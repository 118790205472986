const variantBase = {
  p: "4px 8px",
  borderRadius: "1.5rem"
};

const variantStatus = {
  p: "4px 8px",
  color: "neutral.99",
  bg: "green.40",
  fontSize: "11px",
  borderRadius: "1rem",
  lineHeight: "1rem",
  fontWeight: 600
};

const variantPremiumGradient = {
  borderRadius: "24px",
  background: "linear-gradient(275.61deg, #F9913B -0.89%, #FFD788 99.11%)",
  p: "4px 6px"
};

const variantFreeGradient = {
  borderRadius: "24px",
  background: "linear-gradient(275.61deg, #A9A9A9 -0.89%, #CFCFCF 99.11%)",
  p: "4px 6px"
};

const variants = {
  base: variantBase,
  status: variantStatus,
  "premium-gradient": variantPremiumGradient,
  "free-gradient": variantFreeGradient
};

const defaultProps = {
  variant: "base"
};

const badge = {
  variants,
  defaultProps
};

export default badge;
