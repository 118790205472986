import environment from "@/modules/environment";
import { useAuth } from "@/store/auth";

type Tools = {
  premium?: boolean;
};

const { isDev, envMode } = environment;
const isEnabled = isDev || envMode === "test";
const tools: Tools = {};

const create = () => {
  if (!isEnabled) return;
  // @ts-ignore
  window.setPremiumState = (state: "default" | "forceOn" | "forceOff") => {
    if (state === "default") delete tools.premium;
    else if (state === "forceOn") tools.premium = true;
    else if (state === "forceOff") tools.premium = false;
    useAuth.setState({
      // @ts-ignore
      user: {
        ...useAuth.getState().user,
        subscription: {
          ...(useAuth.getState().user?.subscription as Subscription)
        }
      }
    });
  };
};

const get = (getter: keyof Tools) => {
  if (!isEnabled) return;
  return tools[getter];
};

export const DeveloperToggles = {
  create,
  get
};
