import PresenceKeeper from "@/modules/app/Presence";
import { messages } from "@/modules/communication";
import { dataSyncEmitter } from "@/modules/events/emitter";
import { isEmptyArray } from "@/utils";
import LocalEvents from "camera/modules/LocalEvents";

type SendParams = {
  event: CameraEvent;
  updateType: CameraHistoryReplayEventUpdate["updateType"];
};

export default class RpcEventUpdater {
  constructor(private messenger: Messenger) {}
  private peers: { [jid: string]: { jid: string; updateNumber: number } } = {};

  private getAndIncreaseUpdateNumber = (jid: string) => {
    const number = this.peers[jid].updateNumber;
    this.peers[jid].updateNumber++;
    return number;
  };

  private disableForUnavailablePeer = ({ jid, status }: PresenceUpdate) => {
    if (this.peers[jid] && status !== "receiver_connected") {
      this.disableForPeer(jid);
    }
  };

  enableForPeer = (jid: string) => {
    this.peers[jid] = { jid, updateNumber: 0 };
  };
  disableForPeer = (jid: string) => {
    delete this.peers[jid];
  };

  init = () => {
    dataSyncEmitter.on("local-event-create", this.sendCreate);
    dataSyncEmitter.on("local-event-update", this.sendUpdate);
    PresenceKeeper.on("presence-update", this.disableForUnavailablePeer);
  };

  getPayloadForPeer = async (jid: string) => {
    if (!this.peers[jid]) this.enableForPeer(jid);

    const events = await LocalEvents.getAll();
    return {
      events: events.filter((e) => e.type === "MOTION" || e.type === "NOISE"),
      updateNumber: this.peers[jid].updateNumber
    };
  };

  private send = async ({ event, updateType }: SendParams) => {
    const peers = Object.values(this.peers);
    if (isEmptyArray(peers)) return;

    try {
      for (const peer of peers) {
        const message = messages.CameraHistoryReplayEventUpdate.create({
          event,
          updateType,
          updateNumber: this.getAndIncreaseUpdateNumber(peer.jid)
        });
        await this.messenger.sendAsEnvelope({
          payload: { message },
          to: peer.jid
        });
      }
    } catch (err) {
      log.err(err);
    }
  };

  private sendCreate = async (event: CameraEvent) => {
    await this.send({ event, updateType: "CREATE" });
  };
  private sendUpdate = async (event: CameraEvent) => {
    await this.send({ event, updateType: "UPDATE" });
  };
}
