import { cloudCode } from "@/modules/cloud";
import { fetchCurrentUser } from "@/modules/cloud/auth";

export async function createAnonymousAccount(xmppLogin: string) {
  log.auth("Creating anonymous account...");
  const { sessionToken } = await cloudCode.run("createAnonymousAccount", {
    xmppLogin,
  });
  const user = await fetchCurrentUser(sessionToken, { isAnonymous: true });
  return user;
}
