import { menuAnatomy as parts } from "@chakra-ui/anatomy";
import type { SystemStyleObject } from "@chakra-ui/styled-system";
import {
  PartsStyleFunction,
  SystemStyleFunction,
} from "@chakra-ui/theme-tools";
import memoizedGetColor from "@/lib/memoizedGetColor";
import getButtonStateColor from "../utils/getButtonStateColor";
import { ACTIVE_OPACITY, HOVER_OPACITY } from "../utils/constants";

const memoizedGet = memoizedGetColor(getButtonStateColor);

const baseStyleList: SystemStyleObject = {
  bg: "surface2",
  boxShadow: "elevation2",
  color: "inherit",
  minW: "200px",
  py: "8",
  zIndex: 10,
  borderRadius: "1rem",
  overflow: "hidden",
};

const baseStyleItem: SystemStyleFunction = (props) => {
  return {
    p: "12",
    transitionProperty: "background",
    transitionDuration: "0.3s",
    transitionTimingFunction: "ease-in",
    _hover: {
      bg: memoizedGet(props, "on-surface", HOVER_OPACITY, "surface2"),
    },
    _active: {
      bg: memoizedGet(props, "on-surface", ACTIVE_OPACITY, "surface2"),
    },
    _disabled: {
      opacity: 0.4,
      cursor: "not-allowed",
    },
    fontSize: "1rem",
    fontWeight: 400,
    lineHeight: "1.5rem",
    letterSpacing: "0.5px",
    color: "on-surface",
  };
};

const baseStyleGroupTitle: SystemStyleObject = {
  mx: 4,
  my: 2,
  fontWeight: "semibold",
  fontSize: "sm",
};

const baseStyleCommand: SystemStyleObject = {
  opacity: 0.6,
};

const baseStyleDivider: SystemStyleObject = {
  border: 0,
  borderBottom: "1px solid",
  borderColor: "inherit",
  my: "0.5rem",
  opacity: 0.6,
};

const baseStyleButton: SystemStyleObject = {
  transitionProperty: "common",
  transitionDuration: "normal",
  "span:first-of-type": {
    pointerEvents: "auto",
  },
};

const baseStyle: PartsStyleFunction<typeof parts> = (props) => ({
  button: baseStyleButton,
  list: baseStyleList,
  item: baseStyleItem(props),
  groupTitle: baseStyleGroupTitle,
  command: baseStyleCommand,
  divider: baseStyleDivider,
});

const variantStyled = baseStyle;

const variantOptions: SystemStyleFunction = (props) => ({
  ...baseStyle,
  item: { ...baseStyleItem(props), px: "12", py: "8" },
});

const variants = {
  styled: variantStyled,
  options: variantOptions,
};

const defaultProps = {
  variant: "styled",
};

const menu = {
  parts: parts.keys,
  baseStyle,
  variants,
  defaultProps,
};

export default menu;
