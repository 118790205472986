export type PremiumContentEnumKeys = keyof typeof premiumContentEnum;

export const premiumContentEnum = {
  replay: {
    mediaType: "video",
    mediaName: "replay.webm",
    titleKey: "feature.replay.title",
    descriptionKey: "feature.replay.description"
  },
  hd: {
    mediaType: "image",
    mediaName: "video_hd.png",
    titleKey: "feature.hdVideo.title",
    descriptionKey: "feature.hdVideo.description"
  },
  face: {
    mediaType: "video",
    mediaName: "twoWay.webm",
    titleKey: "feature.face.title",
    descriptionKey: "feature.face.description"
  },
  more_cameras: {
    mediaType: "image",
    mediaName: "more_cameras.png",
    titleKey: "feature.moreCamera.title",
    descriptionKey: "feature.moreCamera.description"
  },
  noise: {
    mediaType: "video",
    mediaName: "noise.webm",
    titleKey: "feature.noise.title",
    descriptionKey: "feature.noise.description"
  },
  download: {
    mediaType: "video",
    mediaName: "download.webm",
    titleKey: "feature.download.title",
    descriptionKey: "feature.download.subtitle"
  }
} as const;
