import { cloudCode } from ".";

type updateCameraStationSettingsParams = {
  name?: string;
  color?: CameraColorId;
  settings?: ServerCameraSettings;
  icon?: CameraIconId;
};

export function updateCameraStationSettings({
  name,
  color,
  icon,
  settings,
  xmppLogin,
}: updateCameraStationSettingsParams & {
  xmppLogin: string;
}): Promise<ParseCameraDevice> {
  return cloudCode.run("updateCameraStationSettings", {
    xmppLogin,
    name,
    color,
    icon,
    settings: JSON.stringify(settings || {}),
  });
}
