import protoRoot from "../proto-root";
import createMessage from "./createMessage";

const name = "webRTCCreateAnswer";

function create({ sdp }: { sdp: string }) {
  const WebRTCCreateAnswerMessage = protoRoot.lookupType(
    `comm.WebRTCCreateAnswerMessage`
  );
  const webRTCCreateAnswerMessage = WebRTCCreateAnswerMessage.create({ sdp });

  return createMessage({ [name]: webRTCCreateAnswerMessage });
}

const webRTCCreateAnswer = { create, name };

export default webRTCCreateAnswer;
