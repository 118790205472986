import { Global } from "@emotion/react";

const Fonts = () => (
  <Global
    styles={`
      @font-face {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400-700;
        font-display: swap;
        src: url("fonts/Inter-variable.ttf") format("truetype");
      }
      @font-face {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-display: swap;
        src: url("fonts/Poppins-SemiBold.ttf") format("truetype");
      }
      `}
  />
);

export default Fonts;
