import dayjs from "dayjs";
import { t } from "@/utils";
import { getlanguageCode } from "@/utils";

const commonFormats = {
  style: "unit",
  unitDisplay: "narrow"
} as const;

export default class Time {
  private static secondFormatter: Intl.NumberFormat;
  private static minuteFormatter: Intl.NumberFormat;
  private static hourFormatter: Intl.NumberFormat;
  private static dateFormatter: Intl.DateTimeFormat;
  private static languageCode: string;

  static init() {
    this.languageCode = getlanguageCode();
    this.secondFormatter = new Intl.NumberFormat(this.languageCode, {
      ...commonFormats,
      unit: "second"
    });
    this.minuteFormatter = new Intl.NumberFormat(this.languageCode, {
      ...commonFormats,
      unit: "minute"
    });
    this.hourFormatter = new Intl.NumberFormat(this.languageCode, {
      ...commonFormats,
      unit: "hour"
    });
    this.dateFormatter = new Intl.DateTimeFormat(this.languageCode, {
      weekday: "long",
      month: "short",
      day: "numeric"
    });
  }
  static getRelevantDate(ms: number) {
    const date = dayjs(ms);
    const today = dayjs();
    const yesterday = dayjs().subtract(1, "day");
    if (date.isSame(today, "day")) return t("activityLog.today");
    if (date.isSame(yesterday, "day")) return t("activityLog.yesterday");
    else {
      let formattedDate = this.dateFormatter.format(new Date(ms));

      if (this.languageCode === "cs") formattedDate = formattedDate.replace(/\. /g, ".");
      return formattedDate;
    }
  }
  static formatDate(date: Date | number | string, format: string) {
    return dayjs(date).format(format);
  }
  static fromNow(time: string | number) {
    return dayjs(time).fromNow();
  }
  static formatSeconds(seconds: number) {
    const format = seconds >= 3600 ? "HH:mm:ss" : "mm:ss";
    return dayjs(seconds * 1000).format(format);
  }
  static formatDuration(ms: number) {
    const hours = Math.floor(ms / 1000 / 3600);
    const minutes = Math.floor(((ms / 1000) % 3600) / 60);
    const seconds = Math.floor((ms / 1000) % 60);

    let result = "";

    if (hours > 0) {
      result += `${this.hourFormatter.format(hours)}`;
    }
    if (minutes > 0) {
      result += ` ${this.minuteFormatter.format(minutes)}`;
    }
    if (seconds > 0 && hours < 1) {
      result += ` ${this.secondFormatter.format(seconds)}`;
    }

    return result.trim();
  }
  static getDayWithZeroMs = (date: string | Date) => {
    const time = new Date(date);
    return new Date(time.setHours(0, 0, 0, 0)).getTime();
  };
  static getDayWithMaximumMs = (date: string | Date) => {
    const time = new Date(date);
    return new Date(time.setHours(23, 59, 59, 999)).getTime();
  };
  static isSameDay = (date1: string | Date | number, date2: string | Date | number) => {
    const d1 = dayjs(date1);
    const d2 = dayjs(date2);
    return d1.isSame(d2, "day");
  };
  static timeSince = (since: string | number) => {
    const now = Date.now();
    const past = new Date(since).getTime();
    const diffInSeconds = Math.floor((now - past) / 1000);
    let value;
    let unit;

    if (diffInSeconds < 60) {
      value = diffInSeconds;
      unit = "second";
    } else if (diffInSeconds < 3600) {
      value = Math.floor(diffInSeconds / 60);
      unit = "minute";
    } else if (diffInSeconds < 86400) {
      value = Math.floor(diffInSeconds / 3600);
      unit = "hour";
    } else if (diffInSeconds < 2592000) {
      value = Math.floor(diffInSeconds / 86400);
      unit = "day";
    } else if (diffInSeconds < 31536000) {
      value = Math.floor(diffInSeconds / 2592000);
      unit = "month";
    } else {
      value = Math.floor(diffInSeconds / 31536000);
      unit = "year";
    }

    const formatter = new Intl.NumberFormat(this.languageCode, {
      style: "unit",
      unitDisplay: "short",
      unit
    });

    return formatter.format(value);
  };
}
