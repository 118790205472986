import { nanoid } from "nanoid";
import environment from "@/modules/environment";
import { MediaUploadManager } from "camera/modules/upload/MediaUploadManager";
import { pendingEventsStore } from "camera/store/pendingEvents";
import { messages } from "../deviceStats/messages";
import Xmpp from "../Xmpp";

const INTERVAL = 15 * 60 * 1000;

export default function createStatusMonitor() {
  const startDate = new Date();
  const xmpp = Xmpp.getInstance();

  const getCurrentState = async () => {
    try {
      //@ts-ignore
      const battery = await navigator.getBattery?.();
      const uptime = new Date().getTime() - startDate.getTime();

      const currentState: CurrentStateMessage = {
        presence: xmpp.presence,
        batteryLevel: Math.round((battery?.level || 1) * 100),
        uptime,
        additionalData: JSON.stringify({
          pendingSegments: MediaUploadManager.getUnuploadedSegmentCount(),
          pendingEvents: pendingEventsStore().events.length
        })
      };

      const encoded = messages.CurrentStateMessage.encode(currentState);
      const id = nanoid();
      const iq = $iq({
        type: "set",
        to: environment.xmppDomain,
        id
      }).c("data", { xmlns: "tappytaps:iq:setdevicestats" }, encoded);

      xmpp.sendIq(iq);
    } catch (error) {
      log.err("Failed getting currentState");
      log.err(error);
    }
  };

  getCurrentState();
  setInterval(getCurrentState, INTERVAL);
}
