import { MouseEvent, useEffect } from "react";
import { Flex, Text, Container, Button, ButtonProps } from "@chakra-ui/react";
import SettingsOutlined from "assets/icons/settings_outlined.svg?react";
import ChangeCameraOutlined from "assets/icons/change_camera_outlined.svg?react";
import { t } from "@/utils";
import environment from "@/modules/environment";
import CameraList from "@/modules/app/components/CameraList";
import { dataSyncEmitter } from "@/modules/events/emitter";
import { useCameraSettings } from "@/modules/cameraSettings/CameraSettingsFlow";
import Icon from "@/components/Icon";
import Logo from "@/components/Logo";
import { useCameraMethods } from "camera/modules/station";
import { useCamera } from "camera/store/camera";
import { getIsPaired } from "camera/store/station/selectors";
import { useStation } from "camera/store/station";

const { appMode } = environment;

const NavItem = ({
  children,
  onClick,
  as
}: {
  children: any;
  gap?: string;
  as?: ButtonProps["as"];
  onClick?: Cb<MouseEvent>;
}) => (
  <Button
    as={as}
    variant="unstyled"
    display="flex"
    alignItems="center"
    columnGap="0.5rem"
    px="16"
    py="8"
    onClick={onClick}
  >
    {children}
  </Button>
);

export default function Navbar() {
  const isPaired = useStation(getIsPaired);
  const [openSettings, closeSettings] = useCameraSettings((state) => [state.open, state.close]);
  const changeCamera = useCameraMethods((state) => state.changeCamera);
  const { cameraList, cameraId } = useCamera((state) => ({
    cameraList: state.cameraList,
    cameraId: state.cameraId
  }));

  useEffect(() => {
    const unsub = dataSyncEmitter.on("camera-was-deleted", () => closeSettings());
    return unsub;
  });

  return (
    <Container centerContent>
      <Flex align="center" justify="space-between" py="16px" w="100%">
        <Flex alignItems="center" h="40px">
          <Logo />
          <Text size="md" variant="title" fontFamily="body" color="on-surface-variant" mt="0.125rem">
            {appMode !== "story" && " · " + t(appMode)}
          </Text>
        </Flex>
        <Flex gap="8" align="center">
          <CameraList cameraId={cameraId} cameraList={cameraList} changeCamera={(jid) => changeCamera(jid)}>
            <NavItem as="div">
              <Icon fill="on-surface-variant" icon={ChangeCameraOutlined} />
              <Text>{t("changeCamera")}</Text>
            </NavItem>
          </CameraList>
          {isPaired && (
            <NavItem onClick={openSettings}>
              <Icon fill="on-surface-variant" icon={SettingsOutlined} />
              <Text>{t("settings")}</Text>
            </NavItem>
          )}
        </Flex>
      </Flex>
    </Container>
  );
}
