import opencvUrl from "./opencv.js?url";
import { cameraStore } from "camera/store/camera";

const setState = cameraStore().setOpenCvState;
export function loadOpenCv() {
  if (document.querySelector("#opencv-loader")) {
    return;
  }

  const script = document.createElement("script");
  script.id = "opencv-loader";

  script.onload = () => {
    log.motion("OPENCV loaded successfully");
    setState("ready");
  };
  script.onerror = () => {
    log.motion("OPENCV loading failed");
    setState("error");
  };

  script.src = opencvUrl;
  document.body.appendChild(script);
}
