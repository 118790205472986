import { create } from "zustand";
import { devtools } from "zustand/middleware";

const initialState: AppState = {
  status: "STARTING",
  presence: "unavailable"
};

const useApp = create<AppStore>()(
  devtools((set, get) => ({
    ...initialState,
    setStatus(status) {
      const currentStatus = get().status;
      if (status === "XMPP_DISCONNECTED" && currentStatus === "NO_NETWORK") return;
      log.app("status: " + status);
      set({ status });
    },
    setPresence(presence) {
      log.app("presence: " + presence);
      set({ presence });
    }
  }))
);

export { useApp };
