import EventEmitter from "@/lib/EventEmitter";
import { dataSyncEmitter } from "@/modules/events/emitter";
import { getVideoConstraints } from "@/modules/stream/constraints";
import { useCamera } from "camera/store/camera";
import { useStation } from "camera/store/station";

const emitter = new EventEmitter();
const updateVideoTrackQuality = async (newStream?: MediaStream) => {
  const stream = newStream || useCamera.getState().stream;
  if (!stream) return;
  streamQualityUpdater.isCurrent = false;
  emitter.emit("stream-quality-change-start");

  const videoQuality = useStation.getState().settings.videoQuality;
  const videoConstraints = getVideoConstraints(videoQuality);
  log.info("updating video quality", videoConstraints);

  const track = stream.getVideoTracks()[0];
  try {
    await track.applyConstraints(videoConstraints.video);
    log.info("Done updating video quality");
  } catch (err) {
    log.err("Error applying constraints", err);
  } finally {
    streamQualityUpdater.isCurrent = true;
    emitter.emit("stream-quality-change-end");
  }
};

const startListeningForChanges = () => {
  dataSyncEmitter.on("premium-change", () => updateVideoTrackQuality());
  dataSyncEmitter.on("video-track-update", () => updateVideoTrackQuality());
  useStation.subscribe(
    (state) => state.settings.videoQuality,
    () => updateVideoTrackQuality()
  );
};

const streamQualityUpdater = {
  isCurrent: true,
  startListeningForChanges,
  updateVideoTrackQuality,
  on: emitter.on,
  off: emitter.off
};

export default streamQualityUpdater;
