import { useState } from "react";

export function useControls(isOpen = false) {
  const [_isOpen, setIsOpen] = useState(isOpen);

  return {
    isOpen: _isOpen,
    open: () => setIsOpen(true),
    close: () => setIsOpen(false),
  };
}
