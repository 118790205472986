import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Flex, Heading, Text, Button } from "@chakra-ui/react";
import Icon from "@/components/Icon";
import NavigationBar from "@/components/NavigationBar";
import PlatformIos from "assets/icons/platform_ios.svg?react";
// import PlatformAndroid from "assets/icons/platform_android.svg?react";
import PlatformDesktop from "assets/icons/platform_desktop.svg?react";
import ArrowRight from "assets/icons/arrow_right.svg?react";
import { useButtonStateColors } from "@/theme/utils/getButtonStateColor";

const DeviceSelect = ({ push, state }: FlowProviding<{ previousStepId: string }>) => {
  const { previousStepId } = state;
  const { t } = useTranslation();

  const devicesEnum = useMemo(
    () =>
      [
        {
          stringKey: "howToInstall.device.ios",
          icon: PlatformIos,
          id: "ios",
          iconColor: "on-secondary-container"
        },
        /*  {
          stringKey: "howToInstall.device.android",
          icon: PlatformAndroid,
          id: "android",
          iconColor: "green.60"
        }, */
        {
          stringKey: "howToInstall.device.desktop",
          icon: PlatformDesktop,
          id: "desktop",
          iconColor: "primary"
        }
      ] as const,
    []
  );

  const { active, hover } = useButtonStateColors("on-secondary-container", "secondary-container");

  return (
    <Flex grow={1} direction="column">
      <NavigationBar onBack={() => push(previousStepId, { stepDirection: "previous" })} props={{ my: "1rem" }} />
      <Flex w="100%" direction="column" justify="center" align="center" rowGap="1rem" flexGrow="1" mb="2rem">
        <Heading as="h4" mb="1.25rem">
          {t("pairing.help.deviceSelect.title")}
        </Heading>
        {devicesEnum.map(({ icon, iconColor, id, stringKey }, index) => (
          <Button
            variant="custom"
            display="flex"
            key={id}
            py="1.5rem"
            pl="1rem"
            pr="0.75rem"
            borderRadius="1rem"
            color="on-secondary-container"
            bg="secondary-container"
            _hover={{ bg: hover }}
            _active={{ bg: active }}
            alignItems="center"
            w="100%"
            onClick={() =>
              push("how-to-install", {
                stepDirection: "next",
                stateUpdate: { deviceId: devicesEnum[index].id }
              })
            }
          >
            <Icon icon={icon} fill={iconColor} />
            <Text flexGrow="1" ml="0.62rem" textAlign="start">
              {t(stringKey)}
            </Text>
            <Icon icon={ArrowRight} fill="on-surface-variant" style={{ opacity: "0.38" }} size="24" />
          </Button>
        ))}
      </Flex>
    </Flex>
  );
};

export default DeviceSelect;
