const baseStyle = {
  px: "8px",
  py: "2px",
  bg: "neutral-variant.20",
  color: "neutral.99",
  borderRadius: "4",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "20px",
  letterSpacing: "0.25px",
  maxW: "320px",
  zIndex: "modal"
};

export default {
  baseStyle
};
