import { useCallback, useEffect } from "react";
import Xmpp from "@/modules/Xmpp";
import { useApp } from "@/store/app";
import { commEmitter, dataSyncEmitter } from "@/modules/events/emitter";
import { messages } from "@/modules/communication";
import clearSession from "../app/clearSession";

export function useStationReset({
  deviceSetup,
  networkManager
}: {
  deviceSetup: CameraDeviceSetup;
  networkManager: NetworkManager;
}) {
  const setStatus = useApp((state) => state.setStatus);

  const resetStation = useCallback(async () => {
    setStatus("SIGNING_OUT");
    try {
      await Xmpp.getInstance().disconnect();
      clearSession();

      const xmppCredentials = await deviceSetup.getXmppCredentials();
      await networkManager.start(xmppCredentials);

      setStatus("STARTED");
    } catch (err) {
      log.err("Error while resetting station", err);
      if (isDev) setStatus("FAILURE");
      else restartApp();
    }
  }, [deviceSetup, networkManager, setStatus]);

  const destroyDevice = useCallback(async () => {
    log.app("received unpair message or sessionToken was invalid");
    await resetStation();
  }, [resetStation]);

  useEffect(() => {
    commEmitter.on(messages.DeviceUnpairedMessage.name, destroyDevice);
    dataSyncEmitter.on("invalid-session", destroyDevice);
    return () => {
      commEmitter.off(messages.DeviceUnpairedMessage.name, destroyDevice);
      dataSyncEmitter.off("invalid-session", destroyDevice);
    };
  }, [destroyDevice]);

  return { resetStation };
}
