import { MouseEventHandler } from "react";
import { As, Flex, FlexProps, ThemeTypings, forwardRef, ButtonProps, Tooltip, TooltipProps } from "@chakra-ui/react";
import { useButtonStateColors } from "@/theme/utils/getButtonStateColor";
import { useTooltipManager } from "@/lib/TooltipManager";
import Icon from "./Icon";

type Props = {
  icon: any;
  fill?: ThemeTypings["colors"];
  bg?: ThemeTypings["colors"];
  p?: string;
  onClick?: Cb<MouseEventHandler<HTMLButtonElement>>;
  as?: As;
  props?: FlexProps & ButtonProps;
  iconSize?: string;
  tooltip?: Omit<TooltipProps, "children">;
};

const IconButton = forwardRef<Props, "div">(
  ({ onClick, bg, icon, fill = "neutral.99", as = "button", p = "8", iconSize, tooltip, props }, ref) => {
    const { active, hover } = useButtonStateColors(fill, bg);
    const { addTooltip, removeTooltip, tooltipDelay } = useTooltipManager();

    return (
      <Tooltip
        onOpen={addTooltip}
        {...(tooltip
          ? {
              gutter: 4,
              openDelay: tooltipDelay,
              ...tooltip
            }
          : { isDisabled: true })}
      >
        <Flex
          onMouseLeave={removeTooltip}
          ref={ref}
          //@ts-ignore
          onClick={onClick}
          borderRadius="100%"
          transition="background 0.3s"
          bg={bg}
          as={as}
          p={p}
          _hover={{ bg: hover }}
          _active={{ bg: active }}
          _focusVisible={{
            transition: "box-shadow 0.2s",
            boxShadow: "elevation1",
            outline: "none"
          }}
          {...props}
        >
          <Icon icon={icon} fill={fill} size={iconSize} />
        </Flex>
      </Tooltip>
    );
  }
);

export default IconButton;
