import { create } from "zustand";
import { persist, subscribeWithSelector } from "zustand/middleware";
import environment from "@/modules/environment";
import CrashReporter from "@/modules/app/CrashReporter";

const { system } = environment;
const deviceName = `${system.getBrowserName()} - ${system.getOSName()}`;
const initialState: AuthState = {
  user: null,
  lastSyncDate: new Date(),
  device: {
    xmppCredentials: null,
    name: deviceName,
    deviceName
  }
};

const useAuth = create<AuthStore>()(
  subscribeWithSelector(
    persist(
      (set) => ({
        ...initialState,
        setUser(user, sync = true) {
          log.auth("setting user", user);
          CrashReporter.setUser(user);
          set({ user, ...(sync && { lastSyncDate: new Date() }) });
        },
        updateDevice(update) {
          log.auth("updating device", update);
          set((state) => ({ device: { ...state.device, ...update } }));
        },
        resetState() {
          log.auth("resetting auth store");
          set(initialState);
        }
      }),
      {
        name: environment.appMode + "-auth"
      }
    )
  )
);

export { useAuth };
