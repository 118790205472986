import { create } from "zustand";
import { persist, subscribeWithSelector } from "zustand/middleware";
import { dataSyncEmitter } from "@/modules/events/emitter";
import environment from "@/modules/environment";

type CameraState = {
  stream: MediaStream | null;
  activeFaceJid: null | string;
  cameraList: MediaDeviceInfo[] | null;
  cameraId: string | null;
  microphoneId: string | null;
  loading: boolean;
  error: "no_video_element" | "no_audio" | "no_video" | "permission_denied" | "failure" | null;
  openCvState: "ready" | "loading" | "error";
  lastEvent: CameraEvent | null;
};
export type CameraStore = CameraState & {
  setStream(stream: MediaStream): void;
  updateVideoTrack(track: MediaStreamTrack): void;
  setActiveFaceJid(activeFaceJid: string | null): void;
  setCameraList(cameraList: MediaDeviceInfo[]): void;
  setCameraId(cameraId: string): void;
  setMicrophoneId(microphoneId: string): void;
  setLoading(loading: boolean): void;
  setError(error: CameraState["error"]): void;
  setOpenCvState(state: CameraState["openCvState"]): void;
  setLastEvent(event: CameraEvent | null): void;
  reset(): void;
};

const initialState: CameraState = {
  stream: null,
  lastEvent: null,
  activeFaceJid: null,
  cameraList: null,
  cameraId: null,
  microphoneId: null,
  loading: true,
  error: null,
  openCvState: "loading"
};

const useCamera = create<CameraStore>()(
  persist(
    subscribeWithSelector((set, get) => ({
      ...initialState,
      setStream(stream) {
        set({ stream });
      },
      setMicrophoneId(microphoneId) {
        set({ microphoneId });
      },
      updateVideoTrack(track) {
        const stream = get().stream;
        if (!stream) {
          if (isDev) log.err("Can't update video track, stream is", stream);
          return;
        }
        const oldTrack = stream.getVideoTracks()[0];
        oldTrack.stop();
        oldTrack.enabled = false;
        stream.removeTrack(oldTrack);
        stream.addTrack(track);
        dataSyncEmitter.emit("video-track-update", track);
      },
      setActiveFaceJid(activeFaceJid) {
        if (!activeFaceJid) dataSyncEmitter.emit("stop-face");
        set({ activeFaceJid });
      },
      setCameraList(cameraList) {
        set({ cameraList });
      },
      setCameraId(cameraId) {
        set({ cameraId });
      },
      setLoading(loading) {
        set({ loading });
      },
      setError(error) {
        set({ error });
      },
      setOpenCvState(state) {
        set({ openCvState: state });
      },
      setLastEvent(lastEvent) {
        set({ lastEvent });
      },
      reset() {
        set(initialState);
      }
    })),
    {
      name: environment.appMode + "-last-event",
      partialize: (state) => ({
        lastEvent: state.lastEvent
      })
    }
  )
);

const cameraStore = useCamera.getState;

export { useCamera, cameraStore };
