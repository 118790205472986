import { useEffect, useRef, useState } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Spinner,
  AlertDialogProps,
  Flex,
  ThemeTypings,
  IconProps,
  ModalContentProps
} from "@chakra-ui/react";
import Icon from "./Icon";
import { useButtonStateColors } from "@/theme/utils/getButtonStateColor";
import { dataSyncEmitter } from "@/modules/events/emitter";

type Props = {
  title?: string;
  isOpen: boolean;
  onClose: Cb;
  isProcessing?: boolean;
  body?: any;
  onConfirm?: Cb;
  buttons?: {
    cancel?: { label: string; color?: ThemeTypings["colors"] };
    confirm?: { label: string; color?: ThemeTypings["colors"] };
  };
  props?: Partial<AlertDialogProps>;
  focusedElement?: "cancel" | "accept";
  children?: React.ReactNode;
  icon?: React.FunctionComponent;
  iconProps?: {
    fill?: ThemeTypings["colors"];
    size?: string;
    props?: IconProps;
    customComponent?: JSX.Element;
  };
};

export type { Props as DialogProps };

export default function Dialog({
  isOpen,
  onClose,
  onConfirm,
  title,
  body,
  buttons,
  isProcessing,
  props,
  children,
  icon,
  iconProps,
  focusedElement = "cancel"
}: Props) {
  const [contentStyles, setContentStyles] = useState<ModalContentProps>({ overflow: "auto" });

  const cancelRef = useRef<HTMLButtonElement>(null);
  const acceptRef = useRef<HTMLButtonElement>(null);
  const { active: confirmActive, hover: confirmHover } = useButtonStateColors(
    buttons?.confirm?.color || "primary",
    "surface2"
  );
  const { active: cancelActive, hover: cancelHover } = useButtonStateColors(
    buttons?.cancel?.color || "primary",
    "surface2"
  );

  const leastDestructiveRef = focusedElement === "cancel" ? cancelRef : acceptRef;

  useEffect(() => {
    const unsub = [
      dataSyncEmitter.on("flow-transition-start", () => setContentStyles({ overflow: "hidden" })),
      dataSyncEmitter.on("flow-transition-end", () => setContentStyles({ overflow: "auto" }))
    ];
    return () => unsub.forEach((cb) => cb());
  }, []);

  return (
    <AlertDialog
      size="2xl"
      isOpen={isOpen}
      onClose={onClose}
      leastDestructiveRef={leastDestructiveRef}
      isCentered
      {...props}
    >
      <AlertDialogOverlay>
        <AlertDialogContent {...contentStyles}>
          {isProcessing && <Spinner color="primary" size="xl" my="32" mx="auto" />}
          {children
            ? children
            : !isProcessing && (
                <>
                  {title && (
                    <AlertDialogHeader px="32" pt="32" pb="12">
                      <Flex alignItems="center" columnGap="0.75rem">
                        {iconProps?.customComponent}
                        {icon && (
                          <Icon icon={icon} fill={iconProps?.fill} style={iconProps?.props} size={iconProps?.size} />
                        )}
                        {title}
                      </Flex>
                    </AlertDialogHeader>
                  )}
                  {body && <AlertDialogBody pb="0">{body}</AlertDialogBody>}
                  <AlertDialogFooter>
                    {buttons?.cancel && (
                      <Button
                        variant="text"
                        ref={cancelRef}
                        onClick={onClose}
                        color={buttons.cancel.color}
                        _active={{
                          bg: cancelActive
                        }}
                        _hover={{
                          bg: cancelHover
                        }}
                      >
                        {buttons.cancel.label}
                      </Button>
                    )}
                    {buttons?.confirm && (
                      <Button
                        variant="text"
                        ref={acceptRef}
                        onClick={onConfirm || onClose}
                        color={buttons.confirm.color}
                        _active={{
                          bg: confirmActive
                        }}
                        _hover={{
                          bg: confirmHover
                        }}
                      >
                        {buttons.confirm.label}
                      </Button>
                    )}
                  </AlertDialogFooter>
                </>
              )}
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
