import MotionDetectorState from "../private/MotionDetectorState";
import createMessage from "./createMessage";

const name = "motionDetectorState";

function create({ state }: { state: MotionDetectorState }) {
  return createMessage({
    [name]: MotionDetectorState.create(state),
  });
}

const motionDetectorState = { create, name };

export default motionDetectorState;
