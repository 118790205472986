import protoRoot from "../proto-root";
import PlayableTimeRange from "../private/TimeRange";

const name = "getCameraHistoryStreamPlaylistFileRPCResponse";

function create({
  data,
  startDate,
  duration,
  videoRanges = [],
  result,
}: GetCameraHistoryStreamPlaylistFileResponse) {
  const GetCameraHistoryStreamPlaylistFileRPCResponse = protoRoot.lookupType(
    "comm.GetCameraHistoryStreamPlaylistFileRPCResponse"
  );
  const Result =
    GetCameraHistoryStreamPlaylistFileRPCResponse.lookupEnum("Result");

  const timeRanges = videoRanges.map((range) =>
    PlayableTimeRange.create(range)
  );

  const request = GetCameraHistoryStreamPlaylistFileRPCResponse.create({
    data: data || [],
    startDate: startDate || 0,
    duration: Math.floor(duration || 0),
    videoRanges: timeRanges,
    result: Result.values[result],
  });
  return { payload: request, name };
}

const getCameraHistoryStreamPlaylistFileRPCResponse = { create, name };

export default getCameraHistoryStreamPlaylistFileRPCResponse;
