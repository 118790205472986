import "@/global/init";
import "@/index.css";
import React, { Suspense } from "react";
import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { DeveloperToggles } from "@/utils";
import ColorModeToggle from "@/theme/utils/ColorModeToggle";
import environment from "@/modules/environment";
import CrashReporter from "@/modules/app/CrashReporter";
import ErrorBoundary from "@/components/ErrorBoundary";
import theme from "@/theme";
import App from "./App";
import Fonts from "@/theme/fonts";

CrashReporter.init();
const Parent = environment.strictReact ? React.StrictMode : React.Fragment;
DeveloperToggles.create();

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <Parent>
    <ColorModeScript initialColorMode={theme.config.initialColorMode} />
    <BrowserRouter>
      <ChakraProvider theme={theme}>
        <Fonts />
        <ColorModeToggle>
          <ErrorBoundary>
            <Suspense fallback={null}>
              <App />
            </Suspense>
          </ErrorBoundary>
        </ColorModeToggle>
      </ChakraProvider>
    </BrowserRouter>
  </Parent>
);
