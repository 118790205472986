import environment from "../environment";

const closeRequestName = environment.appMode + "closeTabRequest";
const openRequestName = environment.appMode + "tabOpened";

export default function createInstanceChecker() {
  let receivedCloseRequest = false;
  let canBeStarted = true;

  async function handleCloseRequest() {
    if (receivedCloseRequest) return;
    else receivedCloseRequest = true;
    canBeStarted = false;
  }

  function triggerEvent(event: string) {
    window.localStorage.setItem(event, "true");
    window.localStorage.removeItem(event);
  }

  function notifyOtherExistingInstances() {
    window.addEventListener("storage", async ({ key }) => {
      if (key === openRequestName) triggerEvent(closeRequestName);
      if (key === closeRequestName) handleCloseRequest();
    });

    triggerEvent(openRequestName);
  }

  return {
    notifyOtherExistingInstances,
    getCanBeStarted: () => canBeStarted,
  };
}
