export const SEGMENT_DURATION_REGEX = /#EXTINF:([\d.]+),/g;

export const TRANSCODER_RESET_TIME = 5 * 60000; // 5 minutes
export const SEGMENT_LENGTH = 10000; // 10 seconds
export const APPEND_THRESHOLD_MS = 200;

export const MAXIMUM_GAP_DURATION = 6.997;
export const MINIMUM_GAP_DURATION = 0.02133333;

export const INIT_EXT = ".mp4";
export const SEGMENT_EXT = ".m4s";
export const GAP_PREFIX = "g";
