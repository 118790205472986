import protoRoot from "../proto-root";

const name = "getCameraHistoryStreamPlaylistFileRPCRequest";

function create({
  fileName,
  deltaUpdate,
}: getCameraHistoryStreamPlaylistFileRequest) {
  const GetCameraHistoryStreamPlaylistFileRPCRequest = protoRoot.lookupType(
    "comm.GetCameraHistoryStreamPlaylistFileRPCRequest"
  );

  const request = GetCameraHistoryStreamPlaylistFileRPCRequest.create({
    fileName,
    deltaUpdate,
  });
  return { payload: request, name };
}

const getCameraHistoryStreamPlaylistFileRPCRequest = { create, name };

export default getCameraHistoryStreamPlaylistFileRPCRequest;
