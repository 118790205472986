type Options<Result> = {
  onError?: (err: string) => void;
  onSuccess?: (result: Result) => void;
  onFinished?: () => void;
};

export default async function addGuard<T extends (...args: any) => any, O extends Options<Awaited<ReturnType<T>>>>(
  cb: T,
  options?: O
): Promise<{ result: Awaited<ReturnType<T>> } | { result: false; error: string }> {
  const { onSuccess, onError, onFinished } = options || {};

  try {
    const res = await cb();
    onSuccess?.(res);
    return { result: res };
  } catch (err) {
    onError?.((err as Error)?.message || (err as any) || "UNKNOWN_ERR");
    return { result: false, error: (err as Error)?.message || (err as any) || "UNKNOWN_ERR" };
  } finally {
    onFinished?.();
  }
}
