import { useCallback, useMemo } from "react";
import { Box, Spinner } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { absoluteCenter } from "@/theme/utils/style";
import Dialog from "@/components/Dialog";
import { useControls } from "@/hooks";

export default function useSettingsSaveProgress() {
  const { t } = useTranslation();
  const progressControls = useControls();
  const errorControls = useControls();

  const hideAll = useCallback(() => {
    progressControls.close();
    errorControls.close();
  }, [errorControls, progressControls]);

  const Progress = useCallback(() => {
    if (!progressControls.isOpen) return null;
    return (
      <Box
        pos="absolute"
        left="0"
        right="0"
        top="56"
        bottom="0"
        bg="surface2"
        opacity={0.7}
        zIndex={1}
        borderBottomRadius="1.5rem"
      >
        <Box {...absoluteCenter}>
          <Spinner />
        </Box>
      </Box>
    );
  }, [progressControls.isOpen]);

  const Error = useCallback(() => {
    return (
      <Dialog
        isOpen={errorControls.isOpen}
        onClose={hideAll}
        title={t("somethingWentWrong")}
        body={t("tryAgainLater")}
        buttons={{ cancel: { label: t("buttons.ok") } }}
      />
    );
  }, [errorControls.isOpen, hideAll, t]);

  return useMemo(
    () => ({
      progressControls: {
        hide: progressControls.close,
        show: progressControls.open
      },
      errorControls: {
        show: errorControls.open
      },
      Progress,
      Error
    }),
    [progressControls.close, progressControls.open, errorControls.open, Progress, Error]
  );
}
