import type { SystemStyleObject } from "@chakra-ui/styled-system";
import {
  orient,
  PartsStyleFunction,
  StyleFunctionProps,
  SystemStyleFunction,
} from "@chakra-ui/theme-tools";

function thumbOrientation(props: StyleFunctionProps): SystemStyleObject {
  return orient({
    orientation: props.orientation,
    vertical: {
      left: "50%",
      transform: `translateX(-50%)`,
      _active: {
        transform: `translateX(-50%) scale(1.15)`,
      },
    },
    horizontal: {
      top: "50%",
      transform: `translateY(-50%)`,
      _active: {
        transform: `translateY(-50%) scale(1.15)`,
      },
    },
  });
}

const baseStyleContainer: SystemStyleFunction = (props) => {
  const { orientation } = props;

  return {
    display: "inline-block",
    position: "relative",
    cursor: "pointer",
    _disabled: {
      opacity: 0.6,
      cursor: "default",
      pointerEvents: "none",
    },
    ...orient({
      orientation,
      vertical: { h: "100%" },
      horizontal: { w: "100%" },
    }),
  };
};

const baseStyleTrack: SystemStyleFunction = (props) => {
  return {
    overflow: "hidden",
    borderRadius: "sm",
    bg: "neutral-variant.30",
    ...orient({
      orientation: props.orientation,
      horizontal: { h: "4px" },
      vertical: { w: "4px" },
    }),
    _disabled: {
      bg: "neutral.30",
    },
  };
};

const baseStyleThumb: SystemStyleFunction = (props) => {
  return {
    w: "1rem",
    h: "1rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    outline: 0,
    zIndex: 1,
    borderRadius: "full",
    bg: "neutral.99",
    boxShadow:
      "0px 2px 6px rgba(0, 0, 0, 0.15), 0px 1px 2px rgba(0, 0, 0, 0.30)",
    border: "1px solid",
    borderColor: "transparent",
    transitionProperty: "transform",
    transitionDuration: "normal",
    _focusVisible: { boxShadow: "outline" },
    _disabled: { bg: "neutral.30" },
    ...thumbOrientation(props),
  };
};

const baseStyleFilledTrack: SystemStyleFunction = (props) => {
  return {
    width: "inherit",
    height: "inherit",
    bg: "neutral.99",
  };
};

const baseStyle: PartsStyleFunction = (props) => ({
  container: baseStyleContainer(props),
  track: baseStyleTrack(props),
  thumb: baseStyleThumb(props),
  filledTrack: baseStyleFilledTrack(props),
});

const variantWide: SystemStyleFunction = (props) => {
  return {
    thumb: {
      w: "1rem",
      h: "1rem",
    },
    track: {
      ...orient({
        orientation: props.orientation,
        horizontal: { h: "1rem" },
        vertical: { w: "1rem" },
      }),
    },
  };
};
const variantSlim: SystemStyleFunction = (props) => {
  return {
    thumb: {
      w: "1rem",
      h: "1rem",
    },
    track: {
      ...orient({
        orientation: props.orientation,
        horizontal: { h: "4px" },
        vertical: { w: "4px" },
      }),
    },
  };
};

const variants = {
  wide: variantWide,
  slim: variantSlim,
};

const defaultProps = {
  variant: "slim",
};

const slider = {
  baseStyle,
  variants,
  defaultProps,
};

export default slider;
