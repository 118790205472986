import { DBFactory } from "@/lib/DB";

const DB_NAME = "local-events-database";
const STORE_NAME = "local-events";
export const DATE_INDEX = "end";
const EVENT_INDEX = "uniqueId";

const localEventDbConfig = {
  dbName: DB_NAME,
  version: 2,
  stores: {
    [STORE_NAME]: {
      name: STORE_NAME,
      indexes: [EVENT_INDEX, DATE_INDEX],
      options: { autoIncrement: false, keyPath: EVENT_INDEX }
    }
  }
};

export default class DB extends DBFactory<typeof localEventDbConfig> {
  constructor() {
    super(localEventDbConfig);
  }
}
