import Dialog from "@/components/Dialog";
import Flow from "@/lib/Flow";
import steps from "./steps";
import { Device, useDeleteDeviceFlow } from "./useDeleteDeviceFlow";

export type DeleteFlowStep = FlowProviding & {
  close: Cb;
  device: Device;
};

export default function DeviceDeleteFlow() {
  const { close, isOpen, device } = useDeleteDeviceFlow((state) => ({
    isOpen: state.isOpen,
    close: state.close,
    device: state.device
  }));
  return (
    <Dialog isOpen={isOpen} onClose={close}>
      <Flow initialStep="confirmation" steps={steps} close={close} device={device} />
    </Dialog>
  );
}
