import { savePreviewImage, updateCameraStationPreviewImage } from "camera/modules/cloud/media";

const INTERVAL = isDev ? 120000 : 30000;
const DEBUG_PREVIEW = false;

export default class PreviewUpdater {
  private intervalRef: NodeJS.Timeout | null = null;
  private imageFile: CameraStationPreview["image"];
  private notifyViewers: Cb;

  constructor(notifyViewers: Cb) {
    this.notifyViewers = notifyViewers;
  }

  startTakingVideoPreviews = async () => {
    this.intervalRef = setInterval(this.uploadVideoPreview, INTERVAL);
  };

  stopTakingVideoPreviews = () => {
    if (this.intervalRef) clearInterval(this.intervalRef);
  };

  uploadVideoPreview = async () => {
    try {
      log.mediaPreview("Taking video preview");
      const base64 = this.create();
      await this.upload(base64);
    } catch (e) {
      log.err("Error taking video preview", e);
    }
  };

  getPreviewImagePayload = () => this.imageFile;

  private create = () => {
    const video = document.querySelector<HTMLVideoElement>("#camera-video");
    if (!video) {
      throw Error("No video element found");
    }
    const canvas = window.document.createElement("canvas");
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    const context = canvas.getContext("2d");

    if (!context) {
      throw Error("Error getting canvas context");
    }

    context.drawImage(video, 0, 0);
    const dataUrl = canvas.toDataURL("image/jpeg");

    if (DEBUG_PREVIEW) {
      canvas.style.position = "fixed";
      canvas.style.bottom = "0%";
      canvas.style.left = "50%";
      canvas.style.transform = "translateX(-50%)";
      document.body.appendChild(canvas);
    }

    const base64 = dataUrl.split(",")[1];

    canvas.remove();

    return base64;
  };

  private upload = async (base64: string) => {
    if (!base64) {
      log.warn("No base64 image to upload", base64);
      restartApp();
      return;
    }

    const imageFile = await savePreviewImage({ base64 });
    if (!imageFile) return;

    await updateCameraStationPreviewImage({ imageFile });
    this.imageFile = { fileName: imageFile.name, url: imageFile.url };
    this.notifyViewers();
  };
}
