import protoRoot from "../proto-root";

const name = "reloadCameraHistoryReplayEventsRPCRequest";

function create() {
  const ReloadCameraHistoryReplayEventsRPCRequest = protoRoot.lookupType(
    "comm.ReloadCameraHistoryReplayEventsRPCRequest"
  );

  return { payload: ReloadCameraHistoryReplayEventsRPCRequest.create(), name };
}

const reloadCameraHistoryReplayEventsRPCRequest = {
  create,
  name,
};

export default reloadCameraHistoryReplayEventsRPCRequest;
