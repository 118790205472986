import { useEffect } from "react";
import LogoSmall from "assets/icons/logo_small.svg?react";
import { t } from "@/utils";
import { dataSyncEmitter } from "@/modules/events/emitter";
import { useControls } from "@/hooks";
import Dialog from "@/components/Dialog";
import appUpdater from "./appUpdater";

export default function UpdateNotifier() {
  const dialog = useControls(!appUpdater.getIsCurrent());

  useEffect(() => {
    return dataSyncEmitter.on("new-version-available", dialog.open);
  });

  return (
    <Dialog
      icon={LogoSmall}
      title={t("newVersionAvailable.heading")}
      body={t("newVersionAvailable.text")}
      buttons={{
        cancel: { label: t("remindMeLater") },
        confirm: { label: t("reloadPage") }
      }}
      isOpen={dialog.isOpen}
      onClose={dialog.close}
      onConfirm={restartApp}
      focusedElement="accept"
    />
  );
}
