import {
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  Button,
  Flex,
  Input,
  Heading,
  Spinner
} from "@chakra-ui/react";
import { FormEvent, useCallback, useRef, useState } from "react";
import TrashIcon from "assets/icons/trash.svg?react";
import Icon from "@/components/Icon";
import { useAppContext } from "@/modules/app/AppContextProvider";
import { removeDeviceFromAccount } from "@/modules/cloud/devices";
import { messages } from "@/modules/communication";
import { useAuth } from "@/store/auth";
import Messenger from "@/modules/Messenger";
import { t } from "@/utils";
import { useButtonStateColors } from "@/theme/utils/getButtonStateColor";
import { dataSyncEmitter } from "@/modules/events/emitter";
import { DeleteFlowStep } from "../DeviceDeleteFlow";

export default function Verification({ close, device }: DeleteFlowStep) {
  const { resetStation } = useAppContext();
  const { xmppCredentials } = useAuth((state) => state.device);
  const inputRef = useRef<HTMLInputElement>(null);
  const [canContinue, setCanContinue] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const { active, hover } = useButtonStateColors("error", "surface2");
  const DELETE_STRING = t("buttons.delete");

  const deleteDevice = useCallback(
    async (e: FormEvent) => {
      e.preventDefault();
      const { jid } = device;
      if (!jid) return;
      if (inputRef.current?.value.toLowerCase() !== DELETE_STRING.toLowerCase()) return;
      setIsProcessing(true);

      const isThisDevice = xmppCredentials?.xmppLogin === jid;
      const res = await removeDeviceFromAccount({ xmppLogin: jid });

      if (!res) {
        log.err("Request failed");
        setIsProcessing(false);
        return;
      }

      if (!isThisDevice) {
        const message = messages.DeviceUnpairedMessage.create();
        Messenger.getInstance().sendAsEnvelope({
          to: jid,
          payload: { message }
        });
      }

      dataSyncEmitter.emit("camera-was-deleted");
      if (isThisDevice) await resetStation();
      close();
    },
    [DELETE_STRING, xmppCredentials?.xmppLogin, resetStation, close, device]
  );
  const handleDeleteButtonState = () => {
    if (inputRef.current?.value.toLowerCase() === DELETE_STRING.toLowerCase()) setCanContinue(true);
    else setCanContinue(false);
  };

  return (
    <Flex direction="column" as="form" onSubmit={deleteDevice}>
      {isProcessing ? (
        <Spinner mx="auto" my="32" size="xl" />
      ) : (
        <>
          <AlertDialogHeader pt="32" pb="12" display="flex" alignItems="center" columnGap="0.75rem">
            <Icon icon={TrashIcon} fill="error" />
            <Heading as="h4">{t("deleteCamera.confirmation.title", { 1: DELETE_STRING })}</Heading>
          </AlertDialogHeader>
          <AlertDialogBody py="0">
            {t("deleteCamera.description")}
            <Input autoFocus required mt="1.75rem" ref={inputRef} onChange={handleDeleteButtonState} />
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button variant="text" onClick={close}>
              {t("buttons.keep")}
            </Button>
            <Button
              variant="text"
              type="submit"
              color="error"
              _hover={{ bg: hover }}
              _active={{ bg: active }}
              isDisabled={!canContinue}
            >
              {t("buttons.delete")}
            </Button>
          </AlertDialogFooter>
        </>
      )}
    </Flex>
  );
}
