import * as Sentry from "@sentry/react";
import environment from "@/modules/environment";
import { getLocale } from "@/utils";

const release = `${environment.appName}@${__APP_VERSION__}`;

function getIsDev(functionName: string) {
  if (isDev) {
    log.info(`Sentry: ignoring ${functionName} call in development`);
    return true;
  }
  return false;
}

function init() {
  if (getIsDev("init")) return;
  Sentry.init({
    dsn: environment.sentryDsn,
    environment: environment.envMode,
    debug: isDev,
    release,
    beforeBreadcrumb(breadcrumb, hint) {
      (hint?.event?.breadcrumbs as any[])?.forEach((b, i) => {
        if (b.data?.url?.includes("data:application")) b.data.url = "Removed, included data url";
      });
      return breadcrumb;
    }
  });
  Sentry.setContext("system", {
    browser: environment.system.getBrowserName(),
    os: environment.system.getOSName(),
    mode: environment.appMode,
    locale: getLocale()
  });
}

function sendMessage(message: string) {
  if (getIsDev("sendMessage")) return;
  Sentry.captureMessage(message);
}

function sendException(exception: any, hint?: { key: string; extra: any }) {
  if (getIsDev("sendException")) return;
  Sentry.withScope((scope) => {
    if (hint) scope.setExtra(hint.key, hint.extra);
    Sentry.captureException(exception);
  });
}

function setUser(user: User | null) {
  if (getIsDev("setUser")) return;
  Sentry.setUser(
    user
      ? {
          id: user.objectId,
          email: user.email
        }
      : null
  );
}

const CrashReporter = {
  init,
  sendMessage,
  sendException,
  setUser
};

export default CrashReporter;
