import CameraCommand from "./CameraCommand";
import Empty from "./Empty";
import GetSignedUrl from "./GetSignedUrl";
import Ping from "./Ping";
import StartMonitoring from "./StartMonitoring";
import StartVideoBroadcasting from "./StartVideoBroadcasting";
import StartVideoReceiving from "./StartVideoReceiving";
import StartVideoRecording from "./StartVideoRecording";
import StopMonitoring from "./StopMonitoring";
import StopVideoRecording from "./StopVideoRecording";
import TakeVideoSnapshot from "./TakeVideoSnapshot";
import CameraHistoryReplayEvents from "./CameraHistoryReplayEvents";
import StopCameraHistoryReplayEvents from "./StopCameraHistoryReplayEvents";
import GetCameraHistoryStreamSegmentFile from "./GetCameraHistoryStreamSegmentFile";
import GetCameraHistoryStreamPlaylistFile from "./GetCameraHistoryStreamPlaylistFile";
import SyncMonitoring from "./SyncMonitoring";

export { default as RPCResponse } from "./RPCResponse";

export const RPCResponses = {
  CameraCommand,
  Empty,
  GetSignedUrl,
  Ping,
  StartMonitoring,
  StartVideoBroadcasting,
  StartVideoReceiving,
  StartVideoRecording,
  StopMonitoring,
  StopVideoRecording,
  TakeVideoSnapshot,
  CameraHistoryReplayEvents,
  StopCameraHistoryReplayEvents,
  GetCameraHistoryStreamSegmentFile,
  GetCameraHistoryStreamPlaylistFile,
  SyncMonitoring
};
