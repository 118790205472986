import { chakra, forwardRef } from "@chakra-ui/react";
import StatusBar from "camera/modules/station/components/StatusBar";
import NoPermissons from "./NoPermissons";
import NoDevicesAvailable from "./NoDevicesAvailable";
import LoadingOverlay from "./LoadingOverlay";
import { useAuth } from "@/store/auth";

const EnhancedVideo = chakra("video");

type Props = {
  loading: boolean;
  error: string | null;
};

const Video = forwardRef<Props, "video">(({ loading, error }, ref) => {
  const deviceName = useAuth((state) => state.device.name);

  return (
    <>
      {!loading && <StatusBar />}
      {(loading || error) && (
        <LoadingOverlay deviceName={deviceName}>
          {error === "permission_denied" && <NoPermissons />}
          {error && error !== "permission_denied" && <NoDevicesAvailable />}
        </LoadingOverlay>
      )}
      <EnhancedVideo
        ref={ref}
        id="camera-video"
        playsInline
        muted
        w="100%"
        borderRadius="24px"
        transform="scaleX(-1)"
      />
    </>
  );
});

export default Video;
