import { useAuth } from "@/store/auth";
import { getHasActiveSubscription } from "@/store/auth/selectors";
import { VideoResolutionKeys } from "@/enums/videoQuality";
import { videoConstraints } from "./constraints";

export default function useVideoConstraints(quality?: VideoQuality) {
  const isPremium = useAuth(getHasActiveSubscription);

  const videoConstraintsInfo: {
    quality: NonNullable<typeof quality>;
    resolution: (typeof VideoResolutionKeys)[VideoQuality];
    constraints: { video: MediaTrackConstraints };
  } = {
    quality: isPremium ? quality || "HD" : "LOW",
    resolution: isPremium ? VideoResolutionKeys[quality || "HD"] : VideoResolutionKeys.LOW,
    constraints: isPremium ? videoConstraints.premium[quality || "HD"] : videoConstraints.free.LOW
  };

  return videoConstraintsInfo;
}
