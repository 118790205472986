import { Flex, Spinner, Box } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { t } from "@/utils";
import Logo from "@/components/Logo";
import { useI18nState } from "../i18n/utils";

const MotionBox = motion(Box);

export default function SplashScreen() {
  const stringKey = useI18nState(null, "connecting");
  return (
    <Flex w="100vw" h="100vh" justify="center" align="center">
      <Logo imgProps={{ width: "274px", height: "64px" }} />
      <Flex pos="absolute" bottom="40px" alignItems="center" columnGap="8px">
        <MotionBox initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
          {stringKey && (
            <Flex columnGap="8px">
              <Spinner color="primary" thickness="4px" width="1.5rem" height="1.5rem" bottom="40px" />
              {t(stringKey)}
            </Flex>
          )}
        </MotionBox>
      </Flex>
    </Flex>
  );
}
