import Synchronizer from "../Synchronizer";
import { useApp } from "@/store/app";
import { useAuth } from "@/store/auth";
import { cloudCode } from ".";

export function signOut() {
  const xmppLogin = useAuth.getState().device.xmppCredentials?.xmppLogin;
  return cloudCode.run("signOutWeb", { xmppLogin });
}

export async function registerXmppDevice(deviceName: string) {
  log.info("registering new xmpp device...");
  const response = await cloudCode.run("registerXmppDevice", {
    deviceType: 9,
    deviceName
  });

  const xmppCredentials = {
    xmppLogin: response.username,
    xmppPassword: response.password
  };

  useAuth.getState().updateDevice({ xmppCredentials });
  log.info("device registered ", response, xmppCredentials);
  return xmppCredentials;
}

export async function getUserSubscription(): Promise<Subscription | null> {
  log.warn("getting user subscription...");
  const { subscription: userSubscription } = await cloudCode.run("getCloudAccountInfo");

  if (userSubscription == null) return null;
  delete userSubscription.user;

  if (userSubscription.platform === "web") {
    const paddleSubscription = await cloudCode.run("getCurrentPaddleSubscription");
    if (paddleSubscription) {
      userSubscription.cancelUrl = paddleSubscription.cancelUrl;
      userSubscription.updateUrl = paddleSubscription.updateUrl;
    }
  }

  log.info("userSubscription", userSubscription);
  return userSubscription;
}

export async function fetchCurrentUser(sessionToken?: string, { isAnonymous = false } = {}) {
  const parseUser = (await cloudCode.run(
    "me",
    { _method: "GET", ...(sessionToken && { _SessionToken: sessionToken }) },
    { path: "users" }
  )) as User | null;
  log.info("parseUser", parseUser);

  if (!isAnonymous && parseUser) {
    useAuth.getState().setUser(parseUser);
    parseUser.subscription = await getUserSubscription();
  }
  useAuth.getState().setUser(parseUser);
  return parseUser;
}

export async function fetchCurrentSession() {
  return cloudCode.run("me", { _method: "GET" }, { path: "sessions" });
}

export function getCloudSettings() {
  return cloudCode.run("getCloudSettings");
}

export function generateOtp() {
  return cloudCode.run("generateOtp");
}

// MOVE TO CAMERA DIRECTORY
export async function logInCameraDeviceWithOtp({ authId, otp }: { authId: string; otp: string }) {
  try {
    const xmppLogin = useAuth.getState().device.xmppCredentials?.xmppLogin;
    const { sessionToken } = await cloudCode.run("logInCameraDeviceWithOtp", {
      xmppLogin,
      authId,
      otp
    });
    const user = await fetchCurrentUser(sessionToken);
    useAuth.getState().setUser(user);
    Synchronizer.getInstance().synchronize({ syncAll: true });
  } catch (err) {
    log.err("logInCameraDeviceWithOtp error", err);
    useApp.getState().setStatus("FAILURE");
  }
}
