import { create } from "zustand";
import StationManager from "./StationManager";
import MonitoringManager from "./MonitoringManager";

type Providing = typeof monitoring.providing;

const stationManager = new StationManager();
const monitoring = new MonitoringManager(stationManager);

const useCameraMethods = create<Providing>()(() => monitoring.providing);

export { stationManager, monitoring, useCameraMethods };
