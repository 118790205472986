const baseStyle = {
  field: {
    height: "56px",
    width: "100%",
    minWidth: 0,
    outline: 0,
    position: "relative",
    appearance: "none",
    transitionProperty: "common",
    transitionDuration: "normal"
  }
};

const variantOutline = {
  field: {
    border: "1px solid",
    borderColor: "inherit",
    background: "transparent",
    _focusVisible: {
      zIndex: 1,
      borderColor: "primary"
    },

    borderRadius: "32px",
    padding: "16px 24px",
    fontWeight: 400,
    fontSize: "1.125rem",
    lineHeight: "24px",
    letterSpacing: "0.14px"
  }
};

const variantUnstyled = {
  field: {
    bg: "transparent",
    px: 0,
    height: "auto",
    _disabled: {
      opacity: 0.4,
      cursor: "not-allowed"
    }
  }
};
const variantDate = {
  field: {
    border: "1px solid",
    borderColor: "outline",
    borderRadius: "0.5rem",
    py: "0.375rem",
    pl: "0.75rem",
    pr: "1rem"
  }
};
const defaultProps = {
  variant: "outline"
};
const variants = {
  outline: variantOutline,
  unstyled: variantUnstyled,
  date: variantDate
};
const input = {
  baseStyle,
  variants,
  defaultProps
};

export default input;
