import { v4 as uuid4 } from "uuid";
import { useAuth } from "@/store/auth";
import { useInstallation } from "@/store/installation";
import { createAnonymousAccount } from "../cloud/auth";
import { registerXmppDevice } from "@/modules/cloud/auth";
import { updateInstallation } from "@/modules/app/installation";
import Synchronizer from "@/modules/Synchronizer";
import { useStation } from "camera/store/station";
import ItemSynchronizers from "@/modules/Synchronizer/ItemSynchronizers";
import Xmpp from "@/modules/Xmpp";

const { updateSettings, setColor, setIcon, setObjectId } = useStation.getState();

export default function createDeviceSetup() {
  let isNewDevice = true;
  ItemSynchronizers.deviceUpdater = ({ settings, color, icon, name, objectId }) => {
    if (name) useAuth.getState().updateDevice({ name });
    if (color) setColor(color);
    if (icon) setIcon(icon);
    if (objectId) setObjectId(objectId);
    if (settings) updateSettings(settings);
  };

  const getXmppCredentials = async () => {
    const deviceName = useAuth.getState().device.name;
    let xmppCredentials = useAuth.getState().device.xmppCredentials;
    let user = useAuth.getState().user;

    if (!xmppCredentials || !user) {
      isNewDevice = true;
      const installationId = uuid4();
      useInstallation.getState().setInstallationId(installationId);
      await updateInstallation(installationId);

      xmppCredentials = await registerXmppDevice(deviceName);
      await createAnonymousAccount(xmppCredentials.xmppLogin);
    } else {
      isNewDevice = false;
    }
    return xmppCredentials;
  };

  const syncDevice = async () => {
    if (!isNewDevice) await Synchronizer.getInstance().synchronize({ syncAlways: ["CLOUD_ACCOUNT"] });
    await Xmpp.getInstance().getRoster();
  };

  return {
    getXmppCredentials,
    syncDevice
  };
}
