import PresenceKeeper from "@/modules/app/Presence";
import Messenger from "@/modules/Messenger";
import Peers from "@/modules/Peers";
import { useDevices } from "@/store/devices";
import { getViewerDevices } from "@/store/devices/selectors";
import { Message } from "protobufjs";

export default class Notifier {
  private messenger = Messenger.getInstance();

  private get availablePairedDevices() {
    const devices = getViewerDevices(useDevices.getState());
    const availableDevices = PresenceKeeper.getAvailableJids(devices);
    return availableDevices;
  }

  sendMessageToPairedViewers = (message: Message) => {
    log.notifier(
      "sending message to viewers",
      message,
      this.availablePairedDevices
    );

    this.availablePairedDevices.forEach((jid) => {
      this.messenger.sendAsEnvelope({
        to: jid,
        payload: { message },
      });
    });
  };

  sendMessageToConnectedViewers = (message: Message, jidToIgnore?: string) => {
    const peers = Peers.getAllAsArray();
    log.notifier("sending message to viewers", message, peers);

    peers.forEach((peer) => {
      if (peer.jid === jidToIgnore) return;
      this.messenger.sendAsEnvelope({
        to: peer.jid,
        payload: { message },
      });
    });
  };
}
