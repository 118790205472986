import protoRoot from "../proto-root";

const name = "setLowLightModeRPCRequest";

function create(lowLightMode: LowLightMode) {
  const LowLightMode = protoRoot.lookupEnum("comm.LowLightMode");
  const SetLowLightModeRPCRequest = protoRoot.lookupType(
    "comm.SetLowLightModeRPCRequest"
  );
  const request = SetLowLightModeRPCRequest.create({
    lowLightMode: LowLightMode.values[lowLightMode],
  });

  return { payload: request, name };
}

const setLowLightModeRPCRequest = { create, name };

export default setLowLightModeRPCRequest;
