import { rtcEmitter, dataSyncEmitter } from "../events/emitter";
import { getStreamConstraints } from "../stream/constraints";
import Peers from "../Peers";
import CrashReporter from "../app/CrashReporter";

export default class RtcStream {
  private get stream() {
    return this.getStream() as MediaStream;
  }
  private fps: number;
  private bitrate: number;

  setConstraints = async ({ fps, bitrate }: { fps: number; bitrate: number }) => {
    this.fps = fps;
    this.bitrate = bitrate;
    await this.updateStreamConstraintsForAllPeers();
  };

  constructor(
    private createAndSendOfferTo: (peer: Peer) => Promise<void>,
    private getStream: () => MediaStream | null
  ) {
    dataSyncEmitter.on("video-track-update", this.updateVideoTrackForAllPeers);
    const { fps, bitrate } = getStreamConstraints();
    this.fps = fps;
    this.bitrate = bitrate;
  }

  private updateVideoTrackForAllPeers = async (track: MediaStreamTrack) => {
    const peers = Peers.getAll();
    log.rtc("updating video track for all peers", peers);
    await Promise.all(
      Object.values(peers).map(async (peer) => {
        if (!peer.videoTransceiver) return;
        try {
          await peer.videoTransceiver.sender.replaceTrack(track);
        } catch (err) {
          log.err("failed updating video tracks", err, peer.jid);
        }
      })
    );
  };

  private updateStreamConstraintsForAllPeers = async () => {
    const peers = Peers.getAll();
    log.rtc("updating stream constraints for all peers", peers);
    for (const jid in peers) {
      try {
        const peer = peers[jid];
        if (!peer.videoTransceiver) return;
        const params = peer.videoTransceiver.sender.getParameters();
        if (params) {
          params.encodings[0].maxBitrate = this.bitrate;
          // @ts-ignore
          params.encodings[0].maxFramerate = this.fps;
          await peer.videoTransceiver.sender.setParameters(params);
        }
      } catch (err) {
        log.err("failed updating stream constraints", err, jid);
      }
    }
  };

  private addVideoTransceiverToPeer = (jid: string) => {
    log.rtc("adding video transceiver");
    const peer = Peers.get(jid);
    const videoTrack = this.stream.getVideoTracks()[0];
    peer.videoTransceiver = peer.peerConnection?.addTransceiver(videoTrack, {
      direction: "inactive",
      streams: [this.stream]
    });
    this.updateStreamConstraintsForAllPeers();
  };

  private addAudioTransceiverToPeer = (jid: string) => {
    log.rtc("adding audio transceiver");
    const peer = Peers.get(jid);
    const audioTrack = this.stream.getAudioTracks()[0];
    peer.audioTransceiver = peer.peerConnection?.addTransceiver(audioTrack, {
      direction: "inactive",
      streams: [this.stream]
    });
  };

  startSendingStreamTo = (jid: string, { videoOnly, audioOnly }: { videoOnly?: boolean; audioOnly?: boolean } = {}) => {
    const peer = Peers.get(jid);
    if (peer == null) return;

    return new Promise((resolve, reject) => {
      const start = async () => {
        try {
          if (!peer.audioTransceiver && !videoOnly) this.addAudioTransceiverToPeer(jid);
          if (!peer.videoTransceiver && !audioOnly) this.addVideoTransceiverToPeer(jid);
          if (!videoOnly) peer.audioTransceiver!.direction = "sendonly";
          if (!audioOnly) peer.videoTransceiver!.direction = "sendonly";

          log.rtc("about to start sending stream");
          await this.createAndSendOfferTo(peer);
          resolve(1);
        } catch (err) {
          log.err("'start' at 'startSendingStreamTo' failed, trying to continue anyway...");
          log.err(err);
          CrashReporter.sendException("'startSendingStreamTo' failed", { key: "err", extra: err });
          resolve(1);
        }
      };

      const startWhenReady = async () => {
        if (
          !peer.peerConnection ||
          peer.peerConnection.connectionState === "closed" ||
          peer.peerConnection.connectionState === "failed" ||
          peer.peerConnection.connectionState === "disconnected"
        ) {
          log.err("Connection in bad state:", peer.peerConnection?.connectionState);
          rtcEmitter.off("connection-state-change", startWhenReady);
          log.implementation(
            "listener stays in memory after connection is destroyed (viewer disconnects and connects again)"
          );
          // reject("'startWhenReady' failed");
          return;
        }
        if (peer.peerConnection.connectionState === "connected") {
          rtcEmitter.off("connection-state-change", startWhenReady);
          start();
        }
      };

      log.rtc("peer.peerConnection in 'startSendingStreamTo':", peer.peerConnection);
      if (peer.peerConnection?.connectionState === "connected") {
        start();
      } else {
        log.info("Wont send stream yet, waiting for 'connected' state");
        rtcEmitter.on("connection-state-change", startWhenReady);
      }
    });
  };

  stopSendingStreamTo = async (
    jid: string,
    {
      stopVideoOnly,
      stopAudioOnly
    }: {
      stopVideoOnly?: boolean;
      stopAudioOnly?: boolean;
    } = {}
  ) => {
    const peer = Peers.get(jid);
    if (!peer) return;
    let shouldSendOffer = false;
    try {
      if (peer.audioTransceiver && !stopVideoOnly) {
        peer.audioTransceiver.direction = "inactive";
        shouldSendOffer = true;
      }
      if (peer.videoTransceiver && !stopAudioOnly) {
        peer.videoTransceiver.direction = "inactive";
        shouldSendOffer = true;
      }

      if (shouldSendOffer) await this.createAndSendOfferTo(peer);
    } catch (err) {
      log.err("'stopSendingStreamTo' failed");
      log.err(err);
      CrashReporter.sendException("'stopSendingStreamTo' failed", { key: "err", extra: err });
    }
  };

  destroy = () => {
    dataSyncEmitter.off("video-track-update", this.updateVideoTrackForAllPeers);
  };
}
