import { useCallback, useEffect, useRef } from "react";
import { Box, BoxProps, Flex } from "@chakra-ui/react";
import Circle from "@/components/Circle";
import NoiseDetector from "camera/modules/noise/NoiseDetector";

const MAX_VOLUME_VALUE = 1;
const NUMBER_OF_INDICATORS = 7;
const INDICATOR_RANGE = MAX_VOLUME_VALUE / NUMBER_OF_INDICATORS;

export default function NoiseIndicator({ props }: { props?: BoxProps }) {
  const prevNumberOfActiveIndicators = useRef(0);
  const activeIndicators = useRef<{ [key: number]: HTMLDivElement }>({});

  const countActiveIndicators = useCallback((noiseValue: number) => {
    const cappedVolume = Math.min(MAX_VOLUME_VALUE, noiseValue);
    const numberOfActiveIndicators = Math.ceil(cappedVolume / INDICATOR_RANGE);
    if (numberOfActiveIndicators === prevNumberOfActiveIndicators.current)
      return;

    for (let i = 0; i < numberOfActiveIndicators; i++) {
      if (activeIndicators.current[i]) {
        if (i > 2) activeIndicators.current[i].classList.add("error");
        else activeIndicators.current[i].classList.add("primary");
      }
    }

    for (let i = numberOfActiveIndicators; i < NUMBER_OF_INDICATORS; i++) {
      if (activeIndicators.current[i]) {
        activeIndicators.current[i].classList.remove("primary");
        activeIndicators.current[i].classList.remove("error");
      }
    }
    prevNumberOfActiveIndicators.current = numberOfActiveIndicators;
  }, []);

  const onAudioData = useCallback(
    (noiseValue: number) => {
      countActiveIndicators(noiseValue);
    },
    [countActiveIndicators]
  );

  useEffect(() => {
    NoiseDetector.on("raw-data", onAudioData);
    return () => {
      NoiseDetector.off("raw-data", onAudioData);
    };
  }, [onAudioData]);

  const numberOfIndicators = new Array(NUMBER_OF_INDICATORS).fill(1);
  return (
    <Box {...props}>
      <Flex columnGap="0.5rem">
        {numberOfIndicators.map((_, i) => {
          return (
            <Box
              ref={(el) => (activeIndicators.current[i] = el!)}
              key={i}
              sx={{
                "&.primary": {
                  ".indicator": {
                    bg: "primary",
                    opacity: 1,
                  },
                },
                "&.error": {
                  ".indicator": {
                    bg: "error",
                    opacity: 1,
                  },
                },
              }}
            >
              <Circle
                props={{
                  className: "indicator",
                  opacity: 0.2,
                }}
                bg="primary"
                size="1rem"
              />
            </Box>
          );
        })}
      </Flex>
    </Box>
  );
}
