export const spacing = {
  px: "1px",
  2: "0.125rem",
  4: "0.25rem",
  6: "0.375rem",
  8: "0.5rem",
  10: "0.625rem",
  12: "0.75rem",
  14: "0.875rem",
  16: "1rem",
  20: "1.25rem",
  24: "1.5rem",
  28: "1.75rem",
  32: "2rem",
  36: "2.25rem",
  40: "2.5rem",
  48: "3rem",
  56: "3.5rem",
  64: "4rem",
  72: "4.5rem",
  80: "5rem",
  96: "6rem",
  112: "7rem",
  128: "8rem",
  144: "9rem",
  160: "10rem",
  176: "11rem",
  196: "12rem",
  208: "13rem",
}
