export const ReplayDurationKeys = {
  "8h": "8h",
  "4h": "4h",
  "1h": "1h",
  "10m": "10m"
} as const;

export const ReplayDurationInfo = {
  "8h": { value: 8, unit: "hour" },
  "4h": { value: 4, unit: "hour" },
  "1h": { value: 1, unit: "hour" },
  "10m": { value: 10, unit: "minute" }
};

export const ReplayDurationKeysArray = Object.values(ReplayDurationKeys);
