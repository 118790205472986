import protoRoot from "../proto-root";

const name = "startVideoReceivingRPCRequest";

function create() {
  const StartVideoReceivingRPCRequest = protoRoot.lookupType(
    "comm.StartVideoReceivingRPCRequest"
  );

  const payload = {};

  const request = StartVideoReceivingRPCRequest.create(payload);
  return { payload: request, name };
}

const startVideoReceivingRPCRequest = { create, name };

export default startVideoReceivingRPCRequest;
