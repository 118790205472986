import { useEffect, useState } from "react";
import { useControls } from "@/hooks";
import Dialog from "@/components/Dialog";
import { t } from "@/utils";
import ResponseManager from "./ResponseManager";

export default function Responder() {
  const [isProcessing, setIsProcessing] = useState(false);
  const pairingDialog = useControls();
  const errorDialog = useControls();

  const [responseManager] = useState(
    () =>
      new ResponseManager({
        onSuccess: () => {
          pairingDialog.close();
          resetPairing();
        },
        onError: () => {
          pairingDialog.close();
          errorDialog.open();
        },
        onRequest: () => {
          pairingDialog.open();
        }
      })
  );

  useEffect(() => {
    responseManager.init();
    responseManager.startReactingToAppStatus();
    return () => {
      responseManager.destroy();
      responseManager.stopReactingToAppStatus();
    };
  }, [responseManager]);

  function resetPairing() {
    setIsProcessing(false);
    responseManager.reset();
  }

  return (
    <>
      <Dialog
        isOpen={pairingDialog.isOpen}
        onConfirm={() => {
          responseManager.confirmPairing();
          setIsProcessing(true);
        }}
        onClose={() => {
          responseManager.rejectPairing();
          pairingDialog.close();
        }}
        isProcessing={isProcessing}
        props={{
          onCloseComplete: () => setIsProcessing(false),
          closeOnOverlayClick: false
        }}
        buttons={{
          cancel: { label: t("buttons.reject") },
          confirm: { label: t("buttons.pair") }
        }}
        title={t("pairing.responder.confirmationDialog.heading")}
        body={t("pairing.responder.confirmationDialog.text.initial", {
          1: responseManager.request?.deviceName
        })}
        focusedElement="accept"
      />
      <Dialog
        title={t("pairing.failureDialog.heading")}
        body={t("pairing.failureDialog.text")}
        isOpen={errorDialog.isOpen}
        onClose={errorDialog.close}
        props={{ onCloseComplete: resetPairing, blockScrollOnMount: false }}
        buttons={{
          confirm: {
            label: t("buttons.ok")
          }
        }}
      />
    </>
  );
}
