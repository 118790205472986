import { t as i18nT } from "i18next";
import { Trans } from "react-i18next";

function t(key: StringKey): string;
function t(key: StringKey, params: Keyable): JSX.Element;
function t(key: StringKey, params?: Keyable) {
  if (params) return <Trans i18nKey={key as any} components={params} />;
  return i18nT(key);
}
export { t };
