import { createContext, useContext } from "react";
import type { PremiumContentEnumKeys } from "./premiumContentEnum";

export type DisplayBannerForFreeUser = (featureKey: PremiumContentEnumKeys) => {
  elseRun: <T extends (...args: any) => any>(cb: T) => ReturnType<T> | undefined;
};

export const PremiumScreenCtx = createContext({
  displayBannerForFreeUser: (featureKey: PremiumContentEnumKeys) => ({
    elseRun: <T extends (...args: any) => any>(cb: T): ReturnType<T> | undefined => cb()
  })
});

export const usePremiumScreen = () => useContext(PremiumScreenCtx);
