import environment from "@/modules/environment";
import { MAXIMUM_GAP_DURATION, SEGMENT_DURATION_REGEX } from "../const";
import { encoder, getGapFilename, isInitFile } from "../extensions";
import { PlaylistSettings } from "../Settings";
import TimeRanges from "./TimeRanges";

const { publicPath } = environment;

type RecorderPeer = {
  lastSentSegment: HlsMetaItem | null;
  lastSentInit: HlsMetaItem | null;
  lastSentDeltaPlaylist: {
    data: Uint8Array;
    startDate: string;
    duration: number;
    videoRanges: TimeRange[];
  } | null;
  discontinuitySequence: number;
};

export default class Playlist {
  constructor(private dbController: DbController) {}
  private latestSegmentIndex = 0;
  private latestInitIndex = 0;
  private peers: { [peer: string]: RecorderPeer } = {};

  private initGapData!: Uint8Array;
  private segmentGapData!: Uint8Array;

  loadGapFiles = async () => {
    const initResponse = await fetch(publicPath + `/gapFiles/gap.mp4`);
    const initBuffer = await initResponse.arrayBuffer();
    const initData = new Uint8Array(initBuffer);

    const segmentResponse = await fetch(publicPath + `/gapFiles/gap_${MAXIMUM_GAP_DURATION}_0.m4s`);
    const segmentBuffer = await segmentResponse.arrayBuffer();
    const segmentData = new Uint8Array(segmentBuffer);

    this.initGapData = initData;
    this.segmentGapData = segmentData;
  };

  getGapInit = async (filename: string) => {
    const file = await this.dbController.getRead<HlsMetaItem>("hls-meta", "filename")(filename);
    return { ...file, data: this.initGapData };
  };

  getGapSegment = async (filename: string) => {
    const file = await this.dbController.getRead<HlsMetaItem>("hls-meta", "filename")(filename);
    const isEven = MAXIMUM_GAP_DURATION === Number(file.duration!);
    if (isEven) return { ...file, data: this.segmentGapData };

    const gapFilename = getGapFilename(file.duration!);
    const buffer = await (await fetch(publicPath + `/gapFiles/${gapFilename}`)).arrayBuffer();
    return { ...file, data: new Uint8Array(buffer) };
  };

  prepareNextUsableIndexes = async () => {
    const { initIndex, segmentIndex } = await this.dbController.getNextUsableIndexes();
    this.latestInitIndex = initIndex;
    this.latestSegmentIndex = segmentIndex;
    log.recorderPlaylist("Initial init index ", this.latestInitIndex);
    log.recorderPlaylist("Initial segment index ", this.latestSegmentIndex);
  };

  private createDiscontinuity = () => `#EXT-X-DISCONTINUITY\n`;
  private createSegment = (filename: string, duration: string) => `#EXTINF:${duration}\n${filename}`;
  private createInit = (filename: string, programDate: string) =>
    `#EXT-X-PROGRAM-DATE-TIME:${programDate}\n#EXT-X-MAP:URI="${filename}"`;

  private createPayloadForPeer = async (
    jid: string,
    { data, startDate = new Date().toISOString(), duration = 0 }: PlaylistPayload
  ) => {
    const payload = {
      data,
      startDate,
      duration,
      videoRanges: await TimeRanges.getTimeranges()
    };
    this.peers[jid].lastSentDeltaPlaylist = payload;
    return payload;
  };

  private addPeer = (jid: string): RecorderPeer => {
    if (this.peers[jid]) log.warn("Overwriting existing peer", jid);
    const peer = {
      lastSentDeltaPlaylist: null,
      lastSentInit: null,
      lastSentSegment: null,
      discontinuitySequence: 0
    };
    this.peers[jid] = peer;
    return peer;
  };

  generatePlaylist = async (jid: string) => {
    const peer = this.addPeer(jid);
    const playlistBase = `#EXTM3U
#EXT-X-TARGETDURATION:${PlaylistSettings.TARGETDURATION}
#EXT-X-VERSION:10
#EXT-X-SERVER-CONTROL:CAN-SKIP-UNTIL=${PlaylistSettings.CANSKIP}
#EXT-X-DISCONTINUITY-SEQUENCE:0
#EXT-X-MEDIA-SEQUENCE:SEQUENCE_PLACEHOLDER`;

    let oldestSegment: HlsMetaItem | undefined;
    let sequenceNumber: string | undefined;
    let playlist = playlistBase;
    let playlistDurationInSec = 0;
    const files = await this.dbController.readAllFiles<HlsMetaItem>("hls-meta");

    for (let i = 0; i < files.length; i++) {
      let playlistUpdate = "";
      const file = files[i];
      const nextFile = files[i + 1];
      const { discontinuity, duration, filename, createdAt, index } = file;

      if (isInitFile(filename)) {
        playlistUpdate = this.createInit(filename, nextFile?.createdAt || createdAt);
        peer.lastSentInit = file;
      } else {
        peer.lastSentSegment = file;
        if (!oldestSegment) oldestSegment = file;

        if (!sequenceNumber) {
          sequenceNumber = index.toString();
          playlist = playlist.replace("SEQUENCE_PLACEHOLDER", sequenceNumber);
        }
        if (discontinuity) {
          playlistUpdate = this.createDiscontinuity();
        }
        playlistUpdate += this.createSegment(filename, duration!);
        playlistDurationInSec += Number(duration);
      }

      playlist = playlist.concat("\n" + playlistUpdate);
    }

    log.recorderPlaylist("Created initial playlist");
    const encodedPlaylist = encoder.encode(playlist);

    if (oldestSegment) {
      return this.createPayloadForPeer(jid, {
        data: encodedPlaylist,
        startDate: oldestSegment.createdAt,
        duration: playlistDurationInSec * 1000
      });
    }
    return this.createPayloadForPeer(jid, {
      data: PlaylistSettings.initPlaylistFallback
    });
  };

  generateDeltaPlaylist = async (jid: string) => {
    const peer = this.peers[jid];
    if (!peer) {
      log.warn("Peer asked for delta playlist before initial playlist, ignoring", jid);
      return;
    }
    const lastSentDeltaPlaylist = peer.lastSentDeltaPlaylist;
    if (lastSentDeltaPlaylist) {
      log.recorderPlaylist("Reusing old delta update...");
      return lastSentDeltaPlaylist;
    }

    const oldestSegment = await this.dbController.getFirstFoundSegmentFile("next");
    const request = this.dbController.getMetaCursor("readonly", [null, "prev"]);

    const playlistBase = `#EXTM3U
#EXT-X-TARGETDURATION:${PlaylistSettings.TARGETDURATION}
#EXT-X-VERSION:10
#EXT-X-SERVER-CONTROL:CAN-SKIP-UNTIL=${PlaylistSettings.CANSKIP}
#EXT-X-DISCONTINUITY-SEQUENCE:${peer.discontinuitySequence}
#EXT-X-MEDIA-SEQUENCE:${oldestSegment?.index || 0}
#EXT-X-SKIP:SKIPPED-SEGMENTS=SKIPPED_PLACEHOLDER`;

    let playlist = playlistBase;
    let skipNumber: string;
    const files: HlsMetaItem[] = [];

    const stopDate = new Date(peer.lastSentSegment?.createdAt || new Date());
    stopDate.setSeconds(stopDate.getSeconds() - Number(PlaylistSettings.CANSKIP));

    return new Promise<{
      data: Uint8Array;
      duration: number;
      startDate: string;
      videoRanges: TimeRange[];
    }>((resolve) => {
      const finish = () => {
        const hadFiles = files.length !== 0;

        if (hadFiles) {
          if (!isInitFile(files[0])) {
            files.unshift(peer.lastSentInit!);
          }

          files.forEach((file, i) => {
            const { discontinuity, duration, filename, createdAt, index } = file;
            const nextFile = files[i + 1];
            const isInit = isInitFile(filename);

            let playlistUpdate = "";
            if (isInit) {
              peer.lastSentInit = file;
              playlistUpdate = this.createInit(filename, nextFile?.createdAt || createdAt);
            } else {
              skipNumber = (index - (oldestSegment?.index || 0)).toString();
              playlist = playlist.replaceAll("SKIPPED_PLACEHOLDER", skipNumber);
              if (discontinuity) {
                playlistUpdate = this.createDiscontinuity();
              }
              playlistUpdate += this.createSegment(filename, duration!);
              peer.lastSentSegment = file;
            }

            playlist = playlist.concat("\n" + playlistUpdate);
          });
        }
        log.recorderPlaylist("Created DELTA playlist");
        const encodedPlaylist = encoder.encode(playlist);

        if (!hadFiles) {
          resolve(
            this.createPayloadForPeer(jid, {
              data: PlaylistSettings.deltaPlaylistFallback
            })
          );
          return;
        }

        const playlistDuration =
          new Date(peer.lastSentSegment?.createdAt || new Date()).getTime() +
          Number(peer.lastSentSegment?.duration || 0) * 1000 -
          new Date(oldestSegment?.createdAt || new Date()).getTime();

        resolve(
          this.createPayloadForPeer(jid, {
            data: encodedPlaylist,
            startDate: oldestSegment?.createdAt,
            duration: playlistDuration
          })
        );
      };

      request.onsuccess = (e) => {
        const cursor = (e.target as IDBRequest<IDBCursorWithValue>).result;
        if (cursor) {
          const file = cursor.value as HlsMetaItem;
          const fileDate = new Date(file.createdAt);
          fileDate.setMilliseconds(0);

          const isNewSegment = fileDate.getTime() > stopDate.getTime();
          if (!isNewSegment) {
            finish();
            return;
          }
          files.unshift(file);
          cursor.continue();
        } else {
          finish();
        }
      };
    });
  };

  private readFromPlaylist = (regex: RegExp, playlist: string) => {
    let match: null | RegExpExecArray;
    const result: string[] = [];
    if (!playlist) {
      log.err("Trying to get read from playlist which is null");
      return result;
    }
    while ((match = regex.exec(playlist)) !== null) {
      result.push(match[1]);
    }
    return result;
  };

  getDurationsForSegmentsFromPlaylist = (playlist: string) => {
    return this.readFromPlaylist(SEGMENT_DURATION_REGEX, playlist);
  };

  addToNextSegmentIndex = (segmentIndex: number) => {
    this.latestSegmentIndex += segmentIndex;
  };

  addToNextInitIndex = (initIndex: number) => {
    this.latestInitIndex += initIndex;
  };

  private getNextSafeInteger = (integer: number) => {
    if (integer >= Number.MAX_SAFE_INTEGER) {
      log.warn("Integer is equal or bigger then Number.MAX_SAFE_INTEGER");
      return 0;
    }
    return integer;
  };

  getLatestSegmentIndex = () => {
    const nextSafeInteger = this.getNextSafeInteger(this.latestSegmentIndex);
    this.latestSegmentIndex = nextSafeInteger;
    return nextSafeInteger;
  };

  getLatestInitIndex = () => {
    const nextSafeInteger = this.getNextSafeInteger(this.latestInitIndex);
    this.latestInitIndex = nextSafeInteger;
    return nextSafeInteger;
  };

  clearLastSentDeltaPlaylists = () => {
    Object.values(this.peers).forEach((peer) => {
      peer.lastSentDeltaPlaylist = null;
    });
  };

  addToDiscontinuitySequences = (toAdd: number) => {
    Object.values(this.peers).forEach((peer) => {
      peer.discontinuitySequence += toAdd;
    });
  };
}
