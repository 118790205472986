import { create } from "zustand";
import { persist } from "zustand/middleware";
import environment from "@/modules/environment";
import { generatePairingCode } from "camera/modules/cloud/pairing";

const initialState: PairingCodeState = {
  pairingCode: null
};

const usePairingCode = create<PairingCodeStore>()(
  persist(
    (set, get) => ({
      ...initialState,
      async getPairingCode() {
        let pairingCode = get().pairingCode;
        if (!pairingCode || new Date(pairingCode.expirationDate.iso).getTime() < Date.now()) {
          pairingCode = await generatePairingCode();
          log.pairing("generated pairing code", pairingCode);
          set({ pairingCode });
        }
        return pairingCode;
      },
      isValid() {
        const pairingCode = get().pairingCode;
        return Boolean(
          pairingCode && pairingCode.code && new Date(pairingCode.expirationDate.iso).getTime() > Date.now()
        );
      },
      resetState() {
        log.pairing("resetting pairing code store");
        set(initialState);
      }
    }),
    { name: environment.appMode + "-pairingCode" }
  )
);

export { usePairingCode };
