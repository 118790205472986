export default class BatteryUpdater {
  private batteryManager: BatteryManager | null = null;
  private managerPromise: Promise<BatteryManager> | null = null;
  private notifyViewers: Cb;
  private batteryState: BatteryStatus | null = null;
  private emitter: EventEmitter;

  constructor(notifyViewers: Cb, emitter: EventEmitter) {
    this.emitter = emitter;
    this.notifyViewers = notifyViewers;
    this.initializeBatteryManager();
  }

  private initializeBatteryManager = async () => {
    this.managerPromise = new Promise((resolve) => {
      (navigator as any).getBattery?.().then((manager: BatteryManager) => {
        this.batteryManager = manager;
        if (!this.batteryManager) log.battery("batteryManager not supported");
        else log.battery("BatteryManager initialized");
        resolve(this.batteryManager);
      });
    });
  };

  startListeningForChanges = async () => {
    if (this.managerPromise) await this.managerPromise;
    if (!this.batteryManager) return;
    this.batteryManager.addEventListener("chargingchange", this.handleBatteryChange);
    this.batteryManager.addEventListener("levelchange", this.handleBatteryChange);
  };

  stopListeningForChanges = async () => {
    if (this.managerPromise) await this.managerPromise;
    if (!this.batteryManager) return;
    this.batteryManager.removeEventListener("chargingchange", this.handleBatteryChange);
    this.batteryManager.removeEventListener("levelchange", this.handleBatteryChange);
  };

  getBatteryStatePayload = (): {
    state: BatteryState;
    level: number;
  } => {
    if (!this.batteryManager) {
      log.warn("No batteryManager available in 'getBatteryStatePayload'");
      return { state: "UNKNOWN", level: 0 };
    }
    const { charging, level } = this.batteryManager;

    let state: BatteryState = "UNKNOWN";
    if (charging) state = "CHARGING";
    else if (charging === false) state = "DISCHARGING";

    return {
      state,
      level: Math.round(level * 100)
    };
  };

  private handleBatteryChange = async () => {
    if (!this.batteryManager) return;
    try {
      this.batteryState = this.getBatteryStatePayload();
      log.battery("battery state changed", this.batteryState);
      this.emitter.emit("battery-change", this.batteryState);
      this.notifyViewers();
    } catch (err) {
      log.battery("error updating battery status", err);
    }
  };
}
