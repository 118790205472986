import { cloudCode } from ".";

export function getDevices(): Promise<AllDevices> {
  return cloudCode.run("getCameritoDevices");
}

export function removeDeviceFromAccount({ xmppLogin }: { xmppLogin: string }) {
  return cloudCode.run("removeDeviceFromAccount", { xmppLogin });
}
export function setDeviceName({
  xmppLogin,
  deviceName
}: {
  xmppLogin: string;
  deviceName: string;
}): Promise<ParseDevice> {
  return cloudCode.run("setDeviceName", { xmppLogin, deviceName });
}
