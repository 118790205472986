import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { getLocale } from "@/utils";
import Time from "@/lib/Time";
import localizedFormat from "dayjs/plugin/localizedFormat";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import dayjs from "dayjs";
import { dataSyncEmitter } from "@/modules/events/emitter";

const supportedLocales = {
  cs: true,
  en: true,
  de: true,
  es: true,
  //fi: true,
  fr: true,
  //it: true,
  //ja: true,
  nl: true
  //no: true,
  //pl: true,
  //"pt-BR": true,
  //ru: true,
  //sv: true,
  //fil: true,
  //hu: true,
  //ko: true,
  //"ro-RO": true
};
const localsEnum = {
  cs: () => import(`dayjs/locale/cs`),
  en: () => import(`dayjs/locale/en`),
  de: () => import(`dayjs/locale/de`),
  es: () => import(`dayjs/locale/es`),
  fi: () => import(`dayjs/locale/fi`),
  fr: () => import(`dayjs/locale/fr`),
  it: () => import(`dayjs/locale/it`),
  ja: () => import(`dayjs/locale/ja`),
  nl: () => import(`dayjs/locale/nl`),
  /* no: () => import(`dayjs/locale/no`), */
  pl: () => import(`dayjs/locale/pl`),
  ru: () => import(`dayjs/locale/ru`),
  sv: () => import(`dayjs/locale/sv`),
  /* fil: () => import(`dayjs/locale/fil`), */
  hu: () => import(`dayjs/locale/hu`),
  ko: () => import(`dayjs/locale/ko`),
  "ro-RO": () => import(`dayjs/locale/ro`)
};

export { i18n };
export default async function setupI18n() {
  if (i18n.isInitialized) {
    log.err("Calling setup i18n when already initialized");
    return;
  }

  let locale = getLocale();
  if (locale === "nb") locale = "no";
  if (locale === "pt") locale = "pt-BR";
  if (locale === "ro") locale = "ro-RO";
  if (!(locale in supportedLocales)) locale = "en";

  const stringModule = await import(`../../../locales/${locale}/strings.json`);
  const strings = stringModule.default;

  await i18n.use(initReactI18next).init({
    fallbackLng: "en",
    lng: locale,
    ns: ["default"],
    defaultNS: "default",
    resources: { [locale]: { default: strings } },
    interpolation: {
      escapeValue: false
    },
    debug: isDev
  });
  await setupI18nPlugins(locale as keyof typeof localsEnum);
  Time.init();
  dataSyncEmitter.emit("i18n-loaded");
}

export async function setupI18nPlugins(locale: keyof typeof localsEnum) {
  dayjs.extend(localizedFormat);
  dayjs.extend(duration);
  dayjs.extend(relativeTime);
  await localsEnum[locale]();
  dayjs.locale(locale);
}
