import { DarkMode, Flex, FlexProps, Text } from "@chakra-ui/react";
import { useIsConnected } from "@/hooks";
import { t } from "@/utils";
import Icon from "@/components/Icon";
import ErrorIcon from "assets/icons/error.svg?react";

type Props = {
  style?: FlexProps;
};

const StringKeys = {
  NO_NETWORK: "noConnection.network",
  XMPP_DISCONNECTED: "noConnection.server"
} as const;

export default function NoConnection({ style }: Props) {
  const { appStatus, isConnected } = useIsConnected();

  if (isConnected) return null;

  return (
    <DarkMode>
      <Flex align="center" px="12" py="8" columnGap="12" bg="error-container" borderRadius="1rem" {...style}>
        <Icon icon={ErrorIcon} size="20" fill="on-error-container" />
        <Text color="on-error-container"> {t(StringKeys[appStatus])}</Text>
      </Flex>
    </DarkMode>
  );
}
