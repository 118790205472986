type NoiseSensitivityKeysType = {
  [key in MicSensitivity]: MicSensitivity;
};

export const NoiseSens: NoiseSens = {
  VERY_LOW: { id: "VERY_LOW", value: 0 },
  LOW: { id: "LOW", value: 0.2 },
  MEDIUM: { id: "MEDIUM", value: 0.5 },
  HIGH: { id: "HIGH", value: 0.8 },
  VERY_HIGH: { id: "VERY_HIGH", value: 1.0 },
};

export const NoiseStringKeys = {
  VERY_HIGH: "noiseSensitivity.level.veryHigh",
  HIGH: "noiseSensitivity.level.high",
  MEDIUM: "noiseSensitivity.level.medium",
  LOW: "noiseSensitivity.level.low",
  VERY_LOW: "noiseSensitivity.level.veryLow",
} as const;

export const NoiseSenKeys: NoiseSensitivityKeysType = {
  VERY_HIGH: "VERY_HIGH",
  HIGH: "HIGH",
  MEDIUM: "MEDIUM",
  LOW: "LOW",
  VERY_LOW: "VERY_LOW",
} as const;

export const NoiseSensKeysArr = Object.values(NoiseSenKeys);
