import protoRoot from "../proto-root";
import createMessage from "./createMessage";

const name = "stopVideoBroadcastingMessage";

function create() {
  const StopVideoBroadcastingMessage = protoRoot.lookupType(
    `comm.StopVideoBroadcastingMessage`
  );
  const stopVideoBroadcastingMessage = StopVideoBroadcastingMessage.create();

  return createMessage({ [name]: stopVideoBroadcastingMessage });
}

const stopVideoBroadcastingMessage = { create, name };

export default stopVideoBroadcastingMessage;
