const baseStylePopper = { zIndex: 10 };

const baseStyleContent = {
  bg: "surface2",
  pt: "0.5rem",
  px: "1rem",
  pb: "1.5rem",
  borderRadius: "md",
  boxShadow: "elevation2",
  _focusVisible: {
    outline: "none",
  },
};

const baseStyleHeader = {
  py: "0.5rem",
  color: "on-surface",
  textAlign: "center",
  fontWeight: 600,
  fontSize: "18px",
  lineHeight: "24px",
};

const baseStyleBody = {};

const baseStyleFooter = {};

const baseStyleCloseButton = {
  position: "absolute",
  top: 2,
  insetEnd: 3,
  zIndex: 2,
};

const baseStyle = {
  popper: baseStylePopper,
  content: baseStyleContent,
  header: baseStyleHeader,
  body: baseStyleBody,
  footer: baseStyleFooter,
  closeButton: baseStyleCloseButton,
};

export default { baseStyle };
