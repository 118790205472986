export const semanticTokens = {
  colors: {
    primary: {
      _light: "primary.40",
      _dark: "primary.80"
    },
    "on-primary": {
      _light: "white",
      _dark: "primary.20"
    },
    "primary-container": {
      _light: "primary.90",
      _dark: "primary.30"
    },
    "on-primary-container": {
      _light: "primary.10",
      _dark: "primary.90"
    },
    secondary: {
      _light: "secondary.40",
      _dark: "secondary.80"
    },
    "on-secondary": {
      _light: "white",
      _dark: "secondary.20"
    },
    "secondary-container": {
      _light: "secondary.90",
      _dark: "secondary.30"
    },
    "on-secondary-container": {
      _light: "secondary.10",
      _dark: "secondary.90"
    },
    tertiary: {
      _light: "tertiary.40",
      _dark: "tertiary.80"
    },
    "on-tertiary": {
      _light: "white",
      _dark: "tertiary.20"
    },
    "tertiary-container": {
      _light: "tertiary.90",
      _dark: "tertiary.30"
    },
    "on-tertiary-container": {
      _light: "tertiary.10",
      _dark: "tertiary.90"
    },
    error: {
      _light: "error.40",
      _dark: "error.80"
    },
    "on-error": {
      _light: "white",
      _dark: "error.20"
    },
    "error-container": {
      _light: "error.90",
      _dark: "error.30"
    },
    "on-error-container": {
      _light: "error.10",
      _dark: "error.90"
    },
    background: {
      _light: "neutral.99",
      _dark: "neutral.10"
    },
    "on-background": {
      _light: "neutral.10",
      _dark: "neutral.90"
    },
    surface: {
      _light: "neutral.99",
      _dark: "neutral.10"
    },
    "on-surface": {
      _light: "neutral.10",
      _dark: "neutral.90"
    },
    "surface-variant": {
      _light: "neutral-variant.90",
      _dark: "neutral-variant.30"
    },
    "on-surface-variant": {
      _light: "neutral-variant.30",
      _dark: "neutral-variant.80"
    },
    outline: {
      _light: "neutral-variant.50",
      _dark: "neutral-variant.60"
    },
    "outline-variant": {
      _light: "neutral-variant.80",
      _dark: "neutral-variant.30"
    },
    green: {
      _light: "green.40",
      _dark: "green.80"
    },
    "on-green": {
      _light: "white",
      _dark: "green.20"
    },
    "green-container": {
      _light: "green.90",
      _dark: "green.30"
    },
    "on-green-container": {
      _light: "green.10",
      _dark: "green.90"
    },
    purple: {
      _light: "purple.40",
      _dark: "purple.80"
    },
    "on-purple": {
      _light: "white",
      _dark: "purple.20"
    },
    "purple-container": {
      _light: "purple.90",
      _dark: "purple.30"
    },
    "on-purple-container": {
      _light: "purple.10",
      _dark: "purple.90"
    },
    orange: {
      _light: "orange.40",
      _dark: "orange.80"
    },
    "on-orange": {
      _light: "white",
      _dark: "orange.20"
    },
    "orange-container": {
      _light: "orange.90",
      _dark: "orange.30"
    },
    "on-orange-container": {
      _light: "orange.10",
      _dark: "orange.90"
    },
    blue: {
      _light: "blue.40",
      _dark: "blue.80"
    },
    "on-blue": {
      _light: "white",
      _dark: "blue.20"
    },
    pink: {
      _light: "pink.40",
      _dark: "pink.80"
    },
    "on-pink": {
      _light: "white",
      _dark: "pink.20"
    },
    cyan: {
      _light: "cyan.40",
      _dark: "cyan.80"
    },
    "on-cyan": {
      _light: "white",
      _dark: "cyan.20"
    },
    surface1: {
      _light: "#F1F2FC",
      _dark: "#23242A"
    },
    surface2: {
      _light: "#EAEEFB",
      _dark: "#272931"
    },
    surface3: {
      _light: "#E2E9FA",
      _dark: "#2B2E38"
    },
    surface4: {
      _light: "#DFE6F9",
      _dark: "#2D303A"
    },
    surface5: {
      _light: "#DAE4F8",
      _dark: "#30333F"
    }
  },
  shadows: {
    elevation1: { _light: "elevation1-light", _dark: "elevation1-dark" },
    elevation2: { _light: "elevation2-light", _dark: "elevation2-dark" },
    elevation3: { _light: "elevation3-light", _dark: "elevation3-dark" },
    elevation4: { _light: "elevation4-light", _dark: "elevation4-dark" },
    elevation5: { _light: "elevation5-light", _dark: "elevation5-dark" }
  }
};
