import Customize from "@/shared-steps/Customize";
import MotionDetection from "./MotionDetection";
import NoiseDetection from "./NoiseDetection";
import VideoQuality from "./VideoQuality";
import Settings from "./Settings";
import ReplayDuration from "./ReplayDuration";

const steps = [
  { id: "settings", Component: Settings },
  { id: "customize", Component: Customize },
  { id: "noise-detection", Component: NoiseDetection },
  { id: "motion-detection", Component: MotionDetection },
  { id: "video-quality", Component: VideoQuality },
  { id: "replay-duration", Component: ReplayDuration }
] as {
  id: string;
  Component: (props: FlowProviding & Keyable) => JSX.Element;
}[];

export default steps;
