import protoRoot from "../proto-root";

const name = "startVideoRecordingRPCRequest";

function create() {
  const StartVideoRecordingRPCRequest = protoRoot.lookupType(
    "comm.StartVideoRecordingRPCRequest"
  );

  return { payload: StartVideoRecordingRPCRequest.create(), name };
}

const startVideoRecordingRPCRequest = { create, name };

export default startVideoRecordingRPCRequest;
