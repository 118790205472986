import type {
  ThemeConfig,
  ChakraTheme,
  ThemeDirection,
} from "@chakra-ui/react";
import { components } from "./components";
import { foundations } from "./foundations";
import { semanticTokens } from "./semantic-tokens";
import { styles } from "./styles";

const direction: ThemeDirection = "ltr";
const config: ThemeConfig = {
  initialColorMode: "system",
  useSystemColorMode: true,
};

export const theme: ChakraTheme = {
  semanticTokens,
  direction,
  ...foundations,
  components,
  styles,
  config,
};
export default theme;
