import { useAuth } from "@/store/auth";
import { getHasActiveSubscription } from "@/store/auth/selectors";

export const streamConstraints = {
  free: {
    LOW: {
      fps: 15,
      bitrate: 128000
    }
  },
  premium: {
    HD: {
      fps: 30,
      bitrate: 1600000
    },
    HIGH: {
      fps: 20,
      bitrate: 475000
    },
    MEDIUM: {
      fps: 20,
      bitrate: 267000
    },
    LOW: {
      fps: 15,
      bitrate: 128000
    }
  }
};

export const videoConstraints = {
  free: {
    LOW: {
      video: {
        frameRate: { ideal: 15 },
        width: { ideal: 512 },
        height: { ideal: 288 }
      }
    }
  },
  premium: {
    HD: {
      video: {
        frameRate: { ideal: 30 },
        width: { ideal: 1280 },
        height: { ideal: 720 }
      }
    },
    HIGH: {
      video: {
        frameRate: { ideal: 20 },
        width: { ideal: 854 },
        height: { ideal: 480 }
      }
    },
    MEDIUM: {
      video: {
        frameRate: { ideal: 20 },
        width: { ideal: 640 },
        height: { ideal: 360 }
      }
    },
    LOW: {
      video: {
        frameRate: { ideal: 15 },
        width: { ideal: 512 },
        height: { ideal: 288 }
      }
    }
  }
} as const;

export const getVideoConstraints = (quality?: VideoQuality) => {
  const isPremium = getHasActiveSubscription(useAuth.getState());
  if (isPremium) return videoConstraints.premium[quality || "HD"];
  return videoConstraints.free.LOW;
};

export const getStreamConstraints = (quality?: VideoQuality) => {
  const isPremium = getHasActiveSubscription(useAuth.getState());
  if (isPremium) return streamConstraints.premium[quality || "HD"];
  return streamConstraints.free.LOW;
};
