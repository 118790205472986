import protoRoot from "../proto-root";
import BatteryStatus from "../private/BatteryStatus";
import CameraAndFlashlightState from "../private/CameraAndFlashlightState";

const name = "syncMonitoringRPCResponse";

function create({ batteryStatus, cameraState }: SyncMonitoringResponse) {
  const SyncMonitoring = protoRoot.lookupType("comm.SyncMonitoringRPCResponse");
  const request = SyncMonitoring.create({
    batteryStatus: BatteryStatus.create(batteryStatus),
    cameraState: CameraAndFlashlightState.create({ cameraId: cameraState.cameraId || "" })
  });

  return { payload: request, name };
}

const syncMonitoringRPCResponse = { create, name };
export default syncMonitoringRPCResponse;
