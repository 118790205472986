import { nanoid } from "nanoid";
import { create } from "zustand";
import { persist } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import environment from "@/modules/environment";

type AddedCameraEvent = (CameraEvent & { storeId: string }) | (CameraEvent & { storeId?: never });
type PendingEventsState = {
  unfinishedEvents: StoreCameraEvent[];
  events: StoreCameraEvent[];
};

export type PendingEventsStore = PendingEventsState & {
  addEvent: (event: AddedCameraEvent) => void;
  removeEvent: (storeId: string) => PendingEventsState["events"];
  updateUnfinishedEvent: (event: Partial<StoreCameraEvent>) => void;
  getOldestEvent: Cb<undefined, StoreCameraEvent | undefined>;
  moveEventToTheEnd: (storeId: string) => void;
  resetState: Cb;
};

const initialState: PendingEventsState = {
  unfinishedEvents: [],
  events: []
};

const usePendingEvents = create<PendingEventsStore>()(
  persist(
    immer((set, get) => ({
      ...initialState,
      addEvent(cameraEvent) {
        set((state) => {
          if (!cameraEvent.storeId) cameraEvent.storeId = nanoid();

          state.events.push(cameraEvent as StoreCameraEvent);
          if (cameraEvent.intervalEvent) {
            const isNewEvent = !cameraEvent.finished;
            if (isNewEvent) state.unfinishedEvents.push(cameraEvent as StoreCameraEvent);
            else state.unfinishedEvents = state.unfinishedEvents.filter((e) => e.uniqueId !== cameraEvent.uniqueId);
          }
        });
      },
      removeEvent(storeId) {
        const events = get().events.filter((e) => e.storeId !== storeId);
        set({ events });
        return get().events;
      },
      updateUnfinishedEvent(event) {
        const eventIndex = get().unfinishedEvents.findIndex((e) => e.uniqueId === event.uniqueId);
        set((state) => {
          state.unfinishedEvents[eventIndex] = { ...state.unfinishedEvents[eventIndex], ...event };
        });
      },
      getOldestEvent() {
        return get().events[0];
      },
      moveEventToTheEnd(storeId) {
        const events = [...get().events];
        const indexToMove = events.findIndex((e) => e.storeId === storeId);
        const [event] = events.splice(indexToMove, 1);
        events.push(event);
        set({ events });
      },
      resetState() {
        set(initialState);
      }
    })),
    {
      name: environment.appMode + "-events-upload"
    }
  )
);

const pendingEventsStore = usePendingEvents.getState;

export { usePendingEvents, pendingEventsStore };
