import { TinyColor } from "@ctrl/tinycolor";
import { StyleFunctionProps } from "@chakra-ui/theme-tools";
import getColorToken from "./getColorToken";
import getColorCode from "./getColorCode";
import {
  ThemeTypings,
  useColorMode,
  useTheme,
  useToken,
} from "@chakra-ui/react";
import { useMemo } from "react";

export default function transparentize(
  color: ThemeTypings["colors"],
  opacity: number
) {
  return function (props: StyleFunctionProps) {
    let colorName: string;
    const token = getColorToken(props.theme, color);
    if (token) {
      colorName = props.colorMode === "light" ? token._light : token._dark;
    } else {
      colorName = color;
    }
    const raw = getColorCode(props.theme, colorName);
    return new TinyColor(raw).setAlpha(opacity).toRgbString();
  };
}

export function useTransparentize(
  token: ThemeTypings["colors"],
  opacity: number
) {
  const theme = useTheme();
  const { colorMode } = useColorMode();

  if (theme.semanticTokens.colors[token]) {
    token = theme.semanticTokens.colors[token][`_${colorMode}`];
  }

  const [color] = useToken("colors", [token]);

  return useMemo(
    () => new TinyColor(color).setAlpha(opacity).toRgbString(),
    [color, opacity]
  );
}
