import protoRoot from "../proto-root";

const name = "emptyRpcResponse";

function create() {
  const EmptyRPCResponse = protoRoot.lookupType("comm.EmptyRPCResponse");

  const request = EmptyRPCResponse.create();
  return { payload: request, name };
}

const emptyRpcResponse = { create, name };

export default emptyRpcResponse;
