import protoRoot from "../proto-root";
import CameraEvent from "../private/CameraEvent";

const name = "cameraHistoryReplayEventsRPCResponse";

function create({ updateNumber, events }: CameraHistoryReplayEventsResponse) {
  const CameraHistoryReplayEventsRPCResponse = protoRoot.lookupType(
    "comm.CameraHistoryReplayEventsRPCResponse"
  );
  const cameraEvents = events.map((event) => CameraEvent.create(event));

  const request = CameraHistoryReplayEventsRPCResponse.create({
    updateNumber,
    events: cameraEvents,
  });
  return { payload: request, name };
}

const cameraHistoryReplayEventsRPCResponse = { create, name };

export default cameraHistoryReplayEventsRPCResponse;
