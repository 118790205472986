import { ReplayDurationInfo } from "@/enums/replayDuration";
import { getlanguageCode } from "@/utils";

export const getReplayDurationString = (replayDuration: ReplayDuration, unitDisplay: "long" | "short" = "long") => {
  const languageCode = getlanguageCode();

  const formatter = new Intl.NumberFormat(languageCode, {
    style: "unit",
    unitDisplay,
    unit: replayDuration === "10m" ? "minute" : "hour"
  });

  const { value } = ReplayDurationInfo[replayDuration];
  return formatter.format(value);
};
