import Avatar from "./avatar";
import Badge from "./badge";
import Button from "./button";
import Checkbox from "./checkbox";
import CloseButton from "./close-button";
import Container from "./container";
import Divider from "./divider";
import Heading from "./heading";
import Input from "./input";
import Link from "./link";
import List from "./list";
import Menu from "./menu";
import Modal from "./modal";
import NumberInput from "./number-input";
import PinInput from "./pin-input";
import Radio from "./radio";
import Skeleton from "./skeleton";
import Slider from "./slider";
import Spinner from "./spinner";
import Switch from "./switch";
import Tabs from "./tabs";
import Tag from "./tag";
import Tooltip from "./tooltip";
import FormLabel from "./form-label";
import Select from "./select";
import Text from "./text";
import Popover from "./popover";
import Progress from "./progress";

export { default as Avatar } from "./avatar";
export { default as Badge } from "./badge";
export { default as Button } from "./button";
export { default as Checkbox } from "./checkbox";
export { default as CloseButton } from "./close-button";
export { default as Container } from "./container";
export { default as Divider } from "./divider";
export { default as Heading } from "./heading";
export { default as Input } from "./input";
export { default as Link } from "./link";
export { default as List } from "./list";
export { default as Menu } from "./menu";
export { default as Modal } from "./modal";
export { default as NumberInput } from "./number-input";
export { default as PinInput } from "./pin-input";
export { default as Radio } from "./radio";
export { default as Skeleton } from "./skeleton";
export { default as Slider } from "./slider";
export { default as Spinner } from "./spinner";
export { default as Switch } from "./switch";
export { default as Tabs } from "./tabs";
export { default as Tag } from "./tag";
export { default as Tooltip } from "./tooltip";
export { default as FormLabel } from "./form-label";
export { default as Select } from "./select";
export { default as Text } from "./text";
export { default as Popover } from "./popover";
export { default as Progress } from "./progress";

export const components: Record<string, any> = {
  Avatar,
  Badge,
  Button,
  Checkbox,
  CloseButton,
  Container,
  Divider,
  Heading,
  Input,
  Link,
  List,
  Menu,
  Modal,
  NumberInput,
  PinInput,
  Radio,
  Skeleton,
  Slider,
  Spinner,
  Switch,
  Tabs,
  Tag,
  Tooltip,
  FormLabel,
  Select,
  Text,
  Popover,
  Progress
};
