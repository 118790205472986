import protoRoot from "../proto-root";

const name = "stopVideoRecordingRPCResponse";

function create({
  result,
  thumbnailData,
}: {
  result: "SUCCESS" | "ALREADY_STOPPED" | "FAILURE";
  thumbnailData: Uint8Array;
}) {
  const VideoPreview = protoRoot.lookupType("comm.VideoPreview");
  const StopVideoRecording = protoRoot.lookupType(
    "comm.StopVideoRecordingRPCResponse"
  );
  const Result = StopVideoRecording.lookupEnum("Result");

  const response = StopVideoRecording.create({
    result: Result.values[result],
    video: VideoPreview.create({ thumbnailData }),
  });
  return { payload: response, name };
}

const stopVideoRecordingRPCResponse = { create, name };

export default stopVideoRecordingRPCResponse;
