import { cssVar, SystemStyleFunction } from "@chakra-ui/theme-tools";

const $startColor = cssVar("skeleton-start-color");
const $endColor = cssVar("skeleton-end-color");

const baseStyle: SystemStyleFunction = (props) => {
  return {
    [$startColor.variable]: "colors.neutral-variant.80",
    [$endColor.variable]: "colors.neutral-variant.90",
    _dark: {
      [$startColor.variable]: "colors.neutral-variant.40",
      [$endColor.variable]: "colors.neutral-variant.30"
    },
    background: $startColor.reference,
    borderColor: $endColor.reference,
    opacity: 0.7,
    borderRadius: "2px"
  };
};

export default {
  baseStyle
};
