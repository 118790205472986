import transparentize, { useTransparentize } from "./transparentize";
import combine from "./combine";
import { StyleFunctionProps } from "@chakra-ui/theme-tools";
import getColorToken from "./getColorToken";
import getColorCode from "./getColorCode";
import { ThemeTypings, useColorMode, useTheme, getToken } from "@chakra-ui/react";
import { useMemo } from "react";
import { ACTIVE_OPACITY, HOVER_OPACITY } from "./constants";

export default function getButtonStateColor(
  props: StyleFunctionProps,
  contentColorName: ThemeTypings["colors"],
  opacity: number,
  bgColorName?: ThemeTypings["colors"]
) {
  const contentColorCode = transparentize(contentColorName, opacity)(props);
  if (!bgColorName) {
    return contentColorCode;
  }

  let colorName: string;
  const token = getColorToken(props.theme, bgColorName);
  if (token) {
    colorName = props.colorMode === "light" ? token._light : token._dark;
  } else {
    colorName = bgColorName;
  }
  const raw = getColorCode(props.theme, colorName);

  return combine(contentColorCode, raw);
}

function useButtonStateColor(
  contentColorToken: ThemeTypings["colors"],
  opacity: number,
  bgColorToken?: ThemeTypings["colors"]
) {
  const theme = useTheme();
  const { colorMode } = useColorMode();
  const contentColor = useTransparentize(contentColorToken, opacity);

  if (bgColorToken && theme.semanticTokens.colors[bgColorToken]) {
    bgColorToken = theme.semanticTokens.colors[bgColorToken][`_${colorMode}`];
  }

  return useMemo(() => {
    if (!bgColorToken) return contentColor;
    const bgColor = getToken("colors", bgColorToken)(theme);
    return combine(contentColor, bgColor);
  }, [bgColorToken, contentColor, theme]);
}

export function useButtonStateColors(colorToken: ThemeTypings["colors"], backgroundToken?: ThemeTypings["colors"]) {
  const hover = useButtonStateColor(colorToken, HOVER_OPACITY, backgroundToken);
  const active = useButtonStateColor(colorToken, ACTIVE_OPACITY, backgroundToken);

  return { hover, active };
}
