import Peers from "../Peers";
import { messages } from "../communication";
import Messenger from "../Messenger";

export default class RtcCandidates {
  private messenger = Messenger.getInstance();

  sendCandidates = (jid: string) => {
    const peer = Peers.get(jid);
    if (peer == null) return;

    const { toSend } = peer.candidates;
    if (toSend.length === 0) return;
    peer.candidates.toSend = [];
    const message = messages.WebRTCCandidate.create(toSend);
    this.messenger.sendAsEnvelope({
      to: jid,
      payload: { message }
    });
  };

  private applyIceCandidate = async ({ candidate, peer }: { candidate: RTCIceCandidateInit; peer: Peer }) => {
    if (peer == null || peer.peerConnection == null) return;
    const { remoteDescription } = peer.peerConnection;
    if (remoteDescription) {
      try {
        // const iceCandidate = new RTCIceCandidate(candidate);
        log.rtc("About to add ICE candidate", candidate);
        await peer.peerConnection.addIceCandidate(candidate);
      } catch (error) {
        log.err("Add ICE Candidate failed", error, peer.jid);
      }
    } else {
      const alreadyInQueue = peer.candidates.toAdd.find((c) => JSON.stringify(c) === JSON.stringify(candidate));
      if (!alreadyInQueue) {
        log.warn("Missing 'remoteDescription', adding candidate to queue");
        peer.candidates.toAdd.push(candidate);
      }
    }
  };

  addQueuedCandidates = async (peer: Peer) => {
    if (peer == null || peer.peerConnection == null || peer.peerConnection.remoteDescription == null) {
      return;
    }

    const candidatesToAdd = peer.candidates.toAdd;
    peer.candidates.toAdd = [];
    if (candidatesToAdd.length === 0) return;
    log.rtc("about to add queued candidates", candidatesToAdd, peer.jid);

    for (let candidate of candidatesToAdd) {
      await this.applyIceCandidate({ candidate, peer });
    }
  };

  handleWebRTCCandidates = async ({ candidates }: { candidates: WebRTCCandidateData }, from: string) => {
    const peer = Peers.get(from);
    if (peer && peer.peerConnection) {
      candidates.forEach((candidate) => {
        const { sdp, ...rest } = candidate;
        const parsedCandidate = { ...rest, candidate: sdp };
        this.applyIceCandidate({
          candidate: parsedCandidate,
          peer
        });
      });
    }
  };
}
