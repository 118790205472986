import environment from "@/modules/environment";
import {
  GAP_PREFIX,
  INIT_EXT,
  MAXIMUM_GAP_DURATION,
  MINIMUM_GAP_DURATION,
  SEGMENT_EXT,
} from "./const";

const decoder = new TextDecoder();
const encoder = new TextEncoder();

const isInitFile = (file: string | (HlsMetaItem | HlsDataItem)) => {
  const filename = typeof file === "string" ? file : file.filename;
  return filename.endsWith(INIT_EXT);
};
const isSegmentFile = (file: string | (HlsMetaItem | HlsDataItem)) => {
  const filename = typeof file === "string" ? file : file.filename;
  return filename.endsWith(SEGMENT_EXT);
};
const isGapFile = (
  file: string | (HlsMetaItem | HlsDataItem | HlsMetaGapItem)
): file is HlsMetaGapItem => {
  const filename = typeof file === "string" ? file : file.filename;
  return filename.startsWith(GAP_PREFIX);
};

const getGapFilename = (duration: string) => {
  const durationNum = parseFloat(duration);
  const closestDuration =
    Math.round(durationNum / MINIMUM_GAP_DURATION) * MINIMUM_GAP_DURATION;

  if (closestDuration > MAXIMUM_GAP_DURATION) {
    log.warn("GAP OUT OF RANGE");
    return `gap_${MAXIMUM_GAP_DURATION}_0.m4s`;
  }

  const filename = `gap_${closestDuration.toFixed(3)}_0.m4s`;
  return filename;
};

const getSegmentEndTime = (item: HlsMetaItem | HlsDataItem) => {
  const duration = Math.round(parseFloat(item.duration!) * 1000);
  const endTime = new Date(item.createdAt).getTime() + duration;
  return endTime;
};

const strictIsEqual = (compare: any, to: any) => {
  return JSON.stringify(compare) === JSON.stringify(to);
};

const msToFixedSeconds = (ms: number) => {
  const sec = ms / 1000;
  if (sec > 10) return sec;
  return "0" + sec;
};
const mimeType =
  environment.system.getBrowserName() === "Safari"
    ? "video/mp4"
    : "video/webm; codecs=h264";

export {
  decoder,
  encoder,
  isInitFile,
  isSegmentFile,
  isGapFile,
  getGapFilename,
  strictIsEqual,
  msToFixedSeconds,
  getSegmentEndTime,
  mimeType,
};
