import protoRoot from "../proto-root";
import CameraAndFlashlightState from "../private/CameraAndFlashlightState";

const name = "startVideoBroadcastingResponse";

function create({
  result,
  cameraId,
}: {
  result: "SUCCESS" | "NOT_AVAILABLE";
  cameraId: string;
}) {
  const StartVideoBroadcasting = protoRoot.lookupType(
    "comm.StartVideoBroadcastingRPCResponse"
  );
  const Result = StartVideoBroadcasting.lookupEnum("Result");

  const response = StartVideoBroadcasting.create({
    result: Result.values[result],
    cameraAndFlashlightState: CameraAndFlashlightState.create({ cameraId }),
  });
  return { payload: response, name };
}

const startVideoBroadcastingResponse = { create, name };

export default startVideoBroadcastingResponse;
