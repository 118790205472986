import { Heading, Spinner } from "@chakra-ui/react";
import Dialog from "@/components/Dialog";
import { t } from "@/utils";

export default function SigningOut() {
  return (
    <Dialog isOpen onClose={() => {}}>
      <Spinner color="primary" size="xl" my="32" mx="auto" thickness="4px" />
      <Heading as="h5" textAlign="center" mb="1.5rem">
        {t("splashScreen.signingOut")}
      </Heading>
    </Dialog>
  );
}
