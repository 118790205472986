async function trigger(url: string, params?: RequestInit) {
  const response = await fetch(url, params);
  if (!response.ok) {
    const errInfo = await response.json();
    const error = new Error(errInfo?.error || "Couldn't get error message");
    throw error;
  }

  const result = await response.json();
  const finalData = result.result || result;
  return finalData;
}

async function unsafeFetch(url: string, params?: RequestInit) {
  const response = await trigger(url, params);
  return response;
}

async function safeFetch(url: string, params?: RequestInit) {
  try {
    const response = await trigger(url, params);
    return response;
  } catch (err) {
    log.err(err);
    return null;
  }
}

export { unsafeFetch, safeFetch };
