import protoRoot from "../proto-root";

function create(motionDetectorState: MotionDetectorState) {
  const MotionDetectorState = protoRoot.lookupType(`comm.MotionDetectorState`);
  const DetectorStateType = MotionDetectorState.lookupEnum("DetectorStateType");

  return MotionDetectorState.create({
    state: DetectorStateType.values[motionDetectorState],
  });
}

const motionDetectorState = { create };

export default motionDetectorState;
