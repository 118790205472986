import { Button, Flex, Heading, Text } from "@chakra-ui/react";
import { t, openEmailClient } from "@/utils";
import Icon from "@/components/Icon";
import Logo from "@/components/Logo";
import ErrorIcon from "assets/icons/error.svg?react";
import { useIsI18nLoaded } from "../i18n/utils";

export default function ErrorScreen() {
  const hasTranslations = useIsI18nLoaded();
  return (
    <Flex w="100vw" h="100vh" justify="center" align="center" direction="column" textAlign="center">
      <Logo
        imgProps={{
          width: "140px",
          height: "32px",
          pos: "absolute",
          top: "32",
          left: "50%",
          transform: "translateX(-50%)"
        }}
      />
      <Icon icon={ErrorIcon} style={{ mb: "16" }} fill="error" />
      <Heading as="h4" maxWidth="542px" mb="12">
        {hasTranslations ? t("error.oops") : "Oops! Something went wrong..."}
      </Heading>
      <Text maxWidth="542px" mb="32">
        {hasTranslations ? (
          t("error.tryReloading", {
            1: (
              <Button
                variant="unstyled"
                color="primary"
                fontWeight={400}
                _hover={{ bg: "none" }}
                _active={{ bg: "none" }}
                onClick={() => openEmailClient()}
              />
            )
          })
        ) : (
          <>
            Try reloading the page to get things working again. If the problem persist, please{" "}
            <Button
              variant="unstyled"
              color="primary"
              fontWeight={400}
              _hover={{ bg: "none" }}
              _active={{ bg: "none" }}
              onClick={() => openEmailClient()}
            >
              contact
            </Button>{" "}
            our support.
          </>
        )}
      </Text>
      <Button onClick={() => window.location.reload()}>{hasTranslations ? t("reloadPage") : "Reload page"}</Button>
    </Flex>
  );
}
