import { FunctionComponent, MouseEvent } from "react";
import { Button, ButtonProps, Flex, Text } from "@chakra-ui/react";
import { textOverflowDots } from "@/theme/utils/style";
import Icon from "@/components/Icon";
import Arrow from "assets/icons/arrow_right.svg?react";

export default function ListButton({
  onClick,
  icon,
  label,
  additionalText,
  iconFill,
  props,
  isDisabled,
  hideArrow
}: {
  onClick?: Cb<MouseEvent>;
  icon?: FunctionComponent;
  label: string;
  additionalText?: string;
  iconFill?: string;
  props?: ButtonProps;
  hideArrow?: boolean;
  isDisabled?: boolean;
}) {
  return (
    <Button
      variant="unstyled"
      onClick={onClick}
      border="1px solid"
      borderColor="outline"
      py="12"
      pl="12"
      pr="8"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      borderRadius="16px"
      isDisabled={isDisabled}
      color="on-surface"
      bg="surface2"
      {...props}
    >
      <Flex align="center" columnGap="12px" mr="20" overflow="hidden">
        {icon && <Icon icon={icon} fill={iconFill} />}
        <Text variant="title" color="on-surface" {...textOverflowDots}>
          {label}
        </Text>
      </Flex>
      <Flex align="center" overflow="hidden">
        {additionalText && (
          <Text variant="body" mr="4" {...textOverflowDots}>
            {additionalText}
          </Text>
        )}
        {!hideArrow && <Icon style={{ minW: "1rem" }} icon={Arrow} fill="on-surface" />}
      </Flex>
    </Button>
  );
}
