import { useApp } from "@/store/app";
import { useMemo } from "react";

type ReturnType =
  | {
      isConnected: true;
      appStatus: Exclude<AppStatus, "XMPP_DISCONNECTED" | "NO_NETWORK">;
    }
  | {
      isConnected: false;
      appStatus: Extract<AppStatus, "XMPP_DISCONNECTED" | "NO_NETWORK">;
    };

export function useIsConnected(): ReturnType {
  const appStatus = useApp((state) => state.status);
  const isConnected = appStatus !== "NO_NETWORK" && appStatus !== "XMPP_DISCONNECTED";
  return useMemo(() => {
    if (isConnected) return { isConnected, appStatus };
    return { isConnected, appStatus };
  }, [appStatus, isConnected]);
}
