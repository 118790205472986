import HowToInstall from "@/shared-steps/HowToInstall";
import DeviceSelect from "@/shared-steps/DeviceSelect";
import HowToPair from "./HowToPair";

const steps = [
  { id: "how-to-pair", Component: HowToPair },
  { id: "how-to-install", Component: HowToInstall },
  { id: "device-select", Component: DeviceSelect }
] as {
  id: string;
  Component: (props: FlowProviding & Keyable) => JSX.Element;
}[];

export default steps;
