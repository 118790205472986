import { server, protobuff } from "../Synchronizer/synchronizable-items";
import { cloudCode } from ".";

type ServerSyncItems = { [key in keyof typeof server]?: boolean };

export async function getSyncState() {
  const syncState: ServerSyncItems = await cloudCode.run("getSyncState");
  log.synchronizer("syncState", syncState);

  const syncItemKeys = Object.keys(syncState) as (keyof ServerSyncItems)[];
  const syncStateProtoBuffKeys = syncItemKeys.reduce((acc, itemName) => {
    if (!server[itemName]) return acc;

    const protoBuffKey = server[itemName].protoBuffValue;
    acc[protoBuffKey] = syncState[itemName];
    return acc;
  }, {} as SyncItems);

  log.synchronizer("parsed syncState", syncStateProtoBuffKeys);

  return syncStateProtoBuffKeys;
}

export function confirmItemSync(item: CloudAccountSyncItem) {
  log.synchronizer(`confirmItemSync called with itemName ${item}`);
  const { serverValue } = protobuff[item];
  log.synchronizer(`resolved itemName to server value ${serverValue}`);
  return cloudCode.run("confirmItemSync", { item: serverValue });
}
