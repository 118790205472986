import protoRoot from "../proto-root";

const name = "stopVideoBroadcastingRPCRequest";

function create() {
  const StopVideoBroadcastingRPCRequest = protoRoot.lookupType(
    "comm.StopVideoBroadcastingRPCRequest"
  );

  const payload = {};

  const request = StopVideoBroadcastingRPCRequest.create(payload);
  return { payload: request, name };
}

const stopVideoBroadcastingRPCRequest = { create, name };

export default stopVideoBroadcastingRPCRequest;
