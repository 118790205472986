import { useApp } from "@/store/app";
import Xmpp from "../Xmpp";

export default function createNetworkManager() {
  let xmpp: Xmpp | null = null;
  let timeoutId: NodeJS.Timeout | null = null;
  const setStatus = useApp.getState().setStatus;

  const reconnect = async () => {
    log.app("Reconnecting...");
    if (navigator.onLine) {
      log.app("Network available, restarting xmpp");
      try {
        await xmpp?.reconnect();
        setStatus("STARTED");
      } catch (err) {
        log.app("Error while restarting xmpp", err);
        timeoutId = setTimeout(() => reconnect(), 5000);
      }
    } else {
      log.app("Network unavailable, waiting for connection...");
      try {
        await xmpp?.disconnect();
      } catch (err) {
        log.err("Couldn't disconnect from xmpp on reconnect", err);
      }
    }
  };

  const onOnline = async () => {
    log.app("Network available");
    if (timeoutId) clearTimeout(timeoutId);
    try {
      await xmpp?.connect();
      await xmpp?.getRoster();
      xmpp?.pingService.start();
      xmpp?.sendPresence();
    } catch (err) {
      log.app("Network available, but error connecting to xmpp", err);
      reconnect();
      return;
    }
    setStatus("STARTED");
  };

  const onOffline = () => {
    log.app("Network unavailable");
    setStatus("NO_NETWORK");
  };

  const handleStart = async (xmppCredentials: XmppCredentials) => {
    try {
      xmpp = Xmpp.getInstance();
      xmpp.setCredentials(xmppCredentials);
      await xmpp.connect();
      xmpp.pingService.start();
      xmpp.sendPresence("not_chosen_yet");
    } catch (err) {
      log.app("Error while starting app", err);
      setStatus("FAILURE");
      try {
        await xmpp?.disconnect();
      } catch (err) {
        log.app("Couldn't disconnect from xmpp on app failure", err);
        throw Error("NetworkManager 'handleStart' failed");
      }
    }
  };

  const start = async (xmppCredentials: XmppCredentials) => {
    if (navigator.onLine) await handleStart(xmppCredentials);
    else onOffline();
  };

  function startListening() {
    window.addEventListener("online", onOnline);
    window.addEventListener("offline", onOffline);
  }

  function stopListening() {
    window.removeEventListener("online", onOnline);
    window.removeEventListener("offline", onOffline);
  }

  return {
    start,
    reconnect,
    startListening,
    stopListening
  };
}
