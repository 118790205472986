import { useState, useRef, useEffect } from "react";
import { Text, Button, Flex, Heading, Box, Badge } from "@chakra-ui/react";
import { getHasActiveSubscription } from "@/store/auth/selectors";
import { openCameritoWeb, t } from "@/utils";
import AppImage from "@/components/AppImage";
import AppVideo from "@/components/AppVideo";
import { useAuth } from "@/store/auth";
import Modal from "@/components/Modal";
import { premiumContentEnum } from "./premiumContentEnum";
import type { PremiumContentEnumKeys } from "./premiumContentEnum";
import { PremiumScreenCtx } from "./usePremiumScreen";

export default function PremiumScreenProvider({ children }: { children: any }) {
  const [modalVisible, setModalVisible] = useState(false);
  const [featureKey, setFeatureKey] = useState<PremiumContentEnumKeys>("more_cameras");
  const isPremium = useAuth(getHasActiveSubscription);
  const isPremiumRef = useRef(isPremium);

  useEffect(() => {
    isPremiumRef.current = isPremium;
  }, [isPremium]);

  const displayBannerForFreeUser = (featureKey: PremiumContentEnumKeys) => {
    if (!isPremiumRef.current) {
      setFeatureKey(featureKey);
      setModalVisible(true);
    }
    return {
      elseRun: function <T extends (...args: any) => any>(cb: T): ReturnType<T> | undefined {
        if (isPremiumRef.current) return cb();
      }
    };
  };
  const selectedFeature = premiumContentEnum[featureKey];
  const { mediaName, mediaType, descriptionKey, titleKey } = selectedFeature;
  const isVideo = mediaType === "video";

  return (
    <PremiumScreenCtx.Provider value={{ displayBannerForFreeUser }}>
      <Modal
        isOpen={modalVisible}
        onClose={() => setModalVisible(false)}
        closeButton
        tooltip={{ label: t("buttons.close") }}
        modalContentProps={{ minH: 0 }}
        modalBodyProps={{ pt: "2.5rem", pb: "2rem" }}
        disableNavigationEvents
      >
        <Flex justify="center" alignItems="center" flexDir="column" rowGap="2.5rem">
          <Flex columnGap="1.5rem" alignItems="center">
            {isVideo ? (
              <AppVideo
                name={mediaName}
                options={{
                  videoProps: {
                    width: "216px",
                    height: "138px",
                    borderRadius: "1rem",
                    muted: true
                  }
                }}
              />
            ) : (
              <AppImage
                name={mediaName}
                options={{
                  hasDarkMode: false,
                  imgProps: {
                    borderRadius: "1rem",
                    h: "138px",
                    w: "216px"
                  }
                }}
              />
            )}
            <Box w="calc(100% - 240px)">
              <Badge variant="premium-gradient" mb="0.5rem">
                <Text variant="label2" color="orange.40" lineHeight="0.875rem" fontSize="11px">
                  {t("user.premium").toUpperCase()}
                </Text>
              </Badge>
              <Heading as="h4" mb="0.5rem" lineHeight="2rem">
                {t(titleKey)}
              </Heading>
              <Text lineHeight="1.75rem">{t(descriptionKey)}</Text>
            </Box>
          </Flex>
          <Button onClick={() => openCameritoWeb("pricing")}>{t("upgradeToPremium")}</Button>
        </Flex>
      </Modal>
      {children}
    </PremiumScreenCtx.Provider>
  );
}
