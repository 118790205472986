import { v4 as uuid4 } from "uuid";
import { Message } from "protobufjs";
import { isPairedDevice } from "@/utils";
import { RPCRequest, RPCResponses, RPCResponse } from "../communication";
import { rpcEmitter, commEmitter } from "../events/emitter";
import Messenger from "../Messenger";

const CALL_TIMEOUT = 5000;

type PeerRequests = {
  [jid: string]: {
    [uuid: string]: {
      timeoutId: number | NodeJS.Timeout;
      resolve: (data: any) => void;
      reject: (reason?: any) => void;
      rpcRequest: Message<{}>;
    };
  };
};

type MessageWrapper = { uuid: string; [key: string]: any };

export default class RPC {
  private messenger = Messenger.getInstance();
  private requests: PeerRequests = {};

  startListening = () => {
    log.rpc("Initializing RPC");
    commEmitter.on("rpcRequest", this.onRequest);
    commEmitter.on("rpcResponse", this.onResponse);
  };

  destroy = () => {
    log.rpc("Destroying RPC");
    commEmitter.off("rpcRequest", this.onRequest);
    commEmitter.off("rpcResponse", this.onResponse);
    Object.values(this.requests).forEach((request) => {
      Object.values(request).forEach((id) => {
        clearTimeout(id.timeoutId);
      });
    });
    this.requests = {};
  };

  call = async (request: RpcMessage, sendTo: string, options?: { sendBy?: SendBy; timeout?: number }) => {
    const uuid = uuid4();
    const { name, payload } = request;
    const rpcRequest = RPCRequest.create({ [name]: payload, uuid });

    return new Promise((resolve, reject) => {
      this.messenger.sendAsEnvelope({
        payload: { rpcRequest },
        to: sendTo,
        sendBy: options?.sendBy || request.sendBy
      });

      const timeoutId = setTimeout(() => {
        reject(`RPC_TIMEOUT_${name}`);
        delete this.requests[sendTo]?.[uuid];
      }, options?.timeout || CALL_TIMEOUT);

      if (!this.requests[sendTo]) this.requests[sendTo] = {};
      this.requests[sendTo][uuid] = { timeoutId, resolve, reject, rpcRequest };
    });
  };

  private onRequest = ({ uuid, ...requestWrapper }: MessageWrapper, from: string) => {
    if (!isPairedDevice(from)) return;

    const requestName = Object.keys(requestWrapper)[0];

    const sendResponse: RpcSendResponse = (response, sendBy) => {
      if (response == null) {
        response = RPCResponses.Empty.create();
      }
      const { name, payload } = response;
      const rpcResponse = RPCResponse.create({ uuid, [name]: payload });
      this.messenger.sendAsEnvelope({
        payload: { rpcResponse },
        to: from,
        sendBy: sendBy || response.sendBy
      });
    };

    rpcEmitter.emit(requestName, requestWrapper[requestName], from, sendResponse);
  };

  private onResponse = ({ uuid, ...responseWrapper }: MessageWrapper, from: string) => {
    const request = this.requests[from]?.[uuid];
    if (request == null) {
      log.warn("RPC request not found for response", responseWrapper);
      return;
    }
    clearTimeout(request.timeoutId);
    const responseName = Object.keys(responseWrapper)[0];
    request.resolve(responseWrapper[responseName]);
    delete this.requests[from][uuid];
  };
}
