import { Box } from "@chakra-ui/react";
import { motion } from "framer-motion";

export default function AnimatedDot() {
  return (
    <Box
      w="12"
      h="12"
      minW="12"
      bg="green.60"
      borderRadius="100%"
      as={motion.div}
      initial={{ opacity: 0 }}
      animate={{
        opacity: [0, 1, 1, 0, 0],
        transition: {
          duration: 2.6,
          times: [0, 0.12, 0.7, 0.82, 1],
          repeat: Infinity,
        },
      }}
    />
  );
}
