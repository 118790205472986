import Modal from "@/components/Modal";
import Flow from "@/lib/Flow";
import steps from "./steps";

export default function PairingHelpFlow({ isOpen, close }: { isOpen: boolean; close: Cb }) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={close}
      modalBodyProps={{ h: "100%", pt: 8 }}
      modalContentProps={{ height: "612px" }}
    >
      <Flow initialStep="how-to-pair" steps={steps} close={close} />
    </Modal>
  );
}
