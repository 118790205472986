import { numberInputAnatomy as parts } from "@chakra-ui/anatomy";
import type { SystemStyleObject } from "@chakra-ui/styled-system";
import { calc, cssVar, PartsStyleFunction, SystemStyleFunction, mode } from "@chakra-ui/theme-tools";
import Input from "./input";

const { variants, defaultProps } = Input;

const $stepperWidth = cssVar("number-input-stepper-width");

const $inputPadding = cssVar("number-input-input-padding");
const inputPaddingValue = calc($stepperWidth).add("0.5rem").toString();

const baseStyleRoot: SystemStyleObject = {
  [$stepperWidth.variable]: "24px",
  [$inputPadding.variable]: inputPaddingValue
};

const baseStyleField: SystemStyleObject = Input.baseStyle?.field ?? {};

const baseStyleStepperGroup: SystemStyleObject = {
  width: [$stepperWidth.reference]
};

const baseStyleStepper: SystemStyleFunction = (props) => {
  return {
    borderStart: "1px solid",
    borderStartColor: mode("inherit", "whiteAlpha.300")(props),
    color: mode("inherit", "whiteAlpha.800")(props),
    _active: {
      bg: mode("gray.200", "whiteAlpha.300")(props)
    },
    _disabled: {
      opacity: 0.4,
      cursor: "not-allowed"
    }
  };
};

const baseStyle: PartsStyleFunction<typeof parts> = (props) => ({
  root: baseStyleRoot,
  field: baseStyleField,
  stepperGroup: baseStyleStepperGroup,
  stepper: baseStyleStepper(props)
});

export default {
  parts: parts.keys,
  baseStyle,
  variants,
  defaultProps
};
