import { Text, Flex } from "@chakra-ui/react";
import { absoluteCenter } from "@/theme/utils/style";
import Icon from "@/components/Icon";
import { t } from "@/utils";
import CameraIcon from "assets/icons/camera.svg?react";

export default function NoPermissons() {
  return (
    <Flex {...absoluteCenter} justify="center" align="center" flexDir="column" textAlign="center" w="20rem">
      <Icon icon={CameraIcon} fill="primary.80" />
      <Text
        mt="1rem"
        mb="0.5rem"
        color="#FBF8FD"
        fontSize="1.0625rem"
        fontWeight={600}
        lineHeight="1.375rem"
        letterSpacing="-0.0255rem"
      >
        {t("camera.permissions.title")}
      </Text>
      <Text fontSize="0.8125rem" fontWeight={400} lineHeight="1.125rem" letterSpacing="-0.00488rem" color="#FBF8FD">
        {t("camera.permissions.description")}
      </Text>
    </Flex>
  );
}
