import { useState } from "react";
import {
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  Button,
  Flex,
  Heading,
  Spinner
} from "@chakra-ui/react";
import Icon from "@/components/Icon";
import { useButtonStateColors } from "@/theme/utils/getButtonStateColor";
import { t } from "@/utils";
import Messenger from "@/modules/Messenger";
import { messages } from "@/modules/communication";
import { removeDeviceFromAccount } from "@/modules/cloud/devices";
import TrashIcon from "assets/icons/trash.svg?react";
import { DeleteFlowStep } from "../DeviceDeleteFlow";

export default function Confirmation({ close, push, device }: DeleteFlowStep) {
  const [isProcessing, setIsProcessing] = useState(false);

  const next = () => push("verification");
  const { active, hover } = useButtonStateColors("error", "surface2");

  const deleteViewer = async () => {
    const { jid } = device;
    if (!jid) return;
    setIsProcessing(true);
    const res = await removeDeviceFromAccount({ xmppLogin: jid });

    if (!res) {
      log.err("Request failed");
      setIsProcessing(false);
      return;
    }
    const message = messages.DeviceUnpairedMessage.create();
    Messenger.getInstance().sendAsEnvelope({
      to: jid,
      payload: { message }
    });
    close();
  };

  const isViewer = device.role !== "camera";
  return (
    <Flex direction="column">
      {isProcessing ? (
        <Spinner mx="auto" my="32" size="xl" />
      ) : (
        <>
          <AlertDialogHeader pt="32" pb="12" display="flex" alignItems="center" columnGap="0.75rem">
            <Icon icon={TrashIcon} fill="error" />
            <Heading as="h4">
              {t(isViewer ? "viewerDelete.confimation.title" : "deleteCamera.title", {
                1: device.name
              })}
            </Heading>
          </AlertDialogHeader>
          <AlertDialogBody py="0">
            {t(isViewer ? "viewerDelete.confirmation.description" : "deleteCamera.description")}
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button variant="text" onClick={close} mr="0.5rem">
              {t("buttons.keep")}
            </Button>
            <Button
              variant="text"
              onClick={isViewer ? deleteViewer : next}
              color="error"
              _hover={{ bg: hover }}
              _active={{ bg: active }}
            >
              {t("buttons.delete")}
            </Button>
          </AlertDialogFooter>
        </>
      )}
    </Flex>
  );
}
