import protoRoot from "../proto-root";

const name = "pingRequest";

function create() {
  const PingRequest = protoRoot.lookupType("comm.PingRequest");

  const payload = {};

  const request = PingRequest.create(payload);
  return { payload: request, name };
}

const pingRequest = { create, name };

export default pingRequest;
