import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@chakra-ui/react";
import ListField from "@/components/ListField";
import { OptionsMenuProps } from "@/components/Menu";
import NavigationBar from "@/components/NavigationBar";
import { MotionSensKeysArr, MotionStringKeys } from "@/enums/motion";

import ConfigureIcon from "assets/icons/configure.svg?react";

export default function MotionDetection({
  previous,
  cameraSettings,
  updateSettings,
  Progress
}: CSTypes.CameraSettingsStepProps) {
  const { t } = useTranslation();
  const { motionSensitivity, motionDetectionEnabled } = cameraSettings.settings;

  const motionSensitivityItems: OptionsMenuProps["items"] = useMemo(() => {
    return MotionSensKeysArr.map((value) => ({
      item: t(MotionStringKeys[value]),
      value
    }));
  }, [t]);

  return (
    <Box>
      {Progress && <Progress />}
      <NavigationBar title={t("motionDetection.heading")} onBack={previous} props={{ mb: "8" }} />
      <ListField
        iconFill="primary"
        props={{
          mb: "48"
        }}
        label={t("motionDetection.heading")}
        switch={{
          isChecked: motionDetectionEnabled,
          onChange: () => {
            updateSettings({
              settings: {
                motionDetectionEnabled: !motionDetectionEnabled
              }
            });
          }
        }}
      />
      <ListField
        icon={ConfigureIcon}
        disabled={!motionDetectionEnabled}
        iconFill="primary"
        label={t("sensitivity")}
        additionalText={t("motionDetection.description")}
        menuOptions={{
          items: motionSensitivityItems,
          label: t(MotionStringKeys[motionSensitivity]),
          menuOptionGroupProps: {
            value: motionSensitivity,
            onChange: (sens) =>
              updateSettings({
                settings: {
                  motionSensitivity: sens as MotionDetectionSensitivity
                }
              })
          }
        }}
      />
    </Box>
  );
}
