import { ThemeTypings } from "@chakra-ui/react";
import { StyleFunctionProps } from "@chakra-ui/theme-tools";

export default function memoizedGetColor(func: Function) {
  const map = new Map();

  return function (
    ...args: [
      StyleFunctionProps,
      ThemeTypings["colors"],
      number,
      ThemeTypings["colors"]?
    ]
  ) {
    const keys = [args[0].colorMode, ...args.slice(1)];
    const key = JSON.stringify(keys);
    let result = map.get(key);

    if (result) return result;

    result = func(...args);
    map.set(key, result);
    return result;
  };
}
