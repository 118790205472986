import environment from "@/modules/environment";
import openCameritoWeb from "./openCameritoWeb";

const { system, appMode } = environment;
const supportedBrowsers = {
  chrome: ">=76",
  edge: ">=79",
  firefox: ">=80",
  safari: ">=17",
  opera: ">=63"
};

export default function checkBrowserSupport() {
  return new Promise<void>((resolve) => {
    const supported = system.satisfies({ desktop: supportedBrowsers });
    const isFirefox = system.isBrowser("firefox");

    if (supported && !isFirefox) resolve();
    else if (supported && isFirefox && appMode === "viewer") resolve();
    else openCameritoWeb("directions", "_self");
  });
}
