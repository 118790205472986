import { useApp } from "@/store/app";

export const waitForNetwork = () =>
  new Promise((resolve) => {
    const isCurrentlyOffline = useApp.getState().status === "NO_NETWORK";
    if (isCurrentlyOffline) {
      const unsub = useApp.subscribe((state, prevState) => {
        if (prevState.status === "NO_NETWORK" && state.status !== "NO_NETWORK") {
          unsub();
          resolve(1);
        }
      });
    } else resolve(1);
  });
