import createMessage from "./createMessage";
import protoRoot from "../proto-root";

const name = "webRTCCreateOffer";

function create({ sdp }: { sdp: string }) {
  const WebRTCCreateOfferMessage = protoRoot.lookupType(
    `comm.WebRTCCreateOfferMessage`
  );
  const webRTCCreateOfferMessage = WebRTCCreateOfferMessage.create({ sdp });

  return createMessage({
    [name]: webRTCCreateOfferMessage,
  });
}

const webRTCCreateOffer = { create, name };

export default webRTCCreateOffer;
