// import { Routes, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import Camera from "camera/pages/Camera";

export default function AppRoutes() {
  return (
    <AnimatePresence initial={false} mode="wait">
      <Camera />
    </AnimatePresence>
  );
}
// export default function AppRoutes() {
//   const location = useLocation();
//   return (
//     <AnimatePresence initial={false} mode="wait">
//       <Routes key={location.pathname} location={location}>
//         <Route path="/camera" element={<Camera />} />
//       </Routes>
//     </AnimatePresence>
//   );
// }
