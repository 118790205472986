import protoRoot from "../proto-root";

const name = "stopCameraHistoryReplayEventsRPCResponse";

function create() {
  const EmptyRPCResponse = protoRoot.lookupType(
    "comm.StopCameraHistoryReplayEventsRPCResponse"
  );

  const request = EmptyRPCResponse.create();
  return { payload: request, name };
}

const stopCameraHistoryReplayEventsRPCResponse = { create, name };

export default stopCameraHistoryReplayEventsRPCResponse;
