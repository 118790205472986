import { NoiseSens } from "@/enums/noise";
import EventEmitter from "@/lib/EventEmitter";
import { dataSyncEmitter } from "@/modules/events/emitter";
import EventManager from "camera/modules/events/EventManager";
import { cameraStore } from "camera/store/camera";
import { stationStore, useStation } from "camera/store/station";
import { toRelative } from "@/utils";

export default class NoiseDetector {
  private static isEnabled = stationStore().settings.noiseDetectionEnabled;
  private static emitter = new EventEmitter();
  private static ctx: AudioContext;
  private static microphoneNode: MediaStreamAudioSourceNode | null = null;
  private static volumeMeterNode: AudioWorkletNode | null = null;

  private static lastEmit: "rest" | "noise" = "rest";
  private static minimumRestTimeout: NodeJS.Timeout | null = null;

  private static sensitivity = NoiseSens[stationStore().settings.micSensitivity];
  private static correction = stationStore().noiseCorrection;

  private static currentEventId: string | undefined;

  static on = this.emitter.on;
  static off = this.emitter.off;

  private static updateSensitivity = (sensitivity: MicSensitivity) => {
    this.sensitivity = NoiseSens[sensitivity];
  };

  private static udpateCorrection = (correction: number) => {
    this.correction = correction;
  };

  private static updateIsEnabled = async (enabled: boolean) => {
    const prevEnabled = this.isEnabled;
    this.isEnabled = enabled;

    if (!enabled && prevEnabled) {
      this.destroy();
      this.forceRest();
    } else if (enabled && !prevEnabled) {
      await this.init();
      this.connectStream();
    }
  };

  static init = async () => {
    if (!this.isEnabled) return;
    try {
      this.ctx = new AudioContext();
      await this.ctx.audioWorklet.addModule("volume-meter-processor.js");
    } catch (err) {
      log.err("Failed 'init' in NoiseDetector", err);
    }
  };

  static startListeningForSensitivityUpdate = () => {
    useStation.subscribe((store) => store.settings.micSensitivity, this.updateSensitivity);
    useStation.subscribe((store) => store.settings.noiseDetectionEnabled, this.updateIsEnabled);
    useStation.subscribe((store) => store.noiseCorrection, this.udpateCorrection);
    dataSyncEmitter.on("event-maximum-duration-reached", this.forceRestOnEventMaximumDuration);
  };

  static connectStream = () => {
    if (!this.isEnabled) return;
    const stream = cameraStore().stream;
    if (!stream) {
      log.warn("Trying to call 'connectStream' without any stream");
      return;
    }

    if (this.microphoneNode?.mediaStream) {
      log.info("Disconnecting audio nodes, about to connect new stream");
      this.destroyConnectedStream();
    }

    this.microphoneNode = this.ctx.createMediaStreamSource(stream);
    this.volumeMeterNode = new AudioWorkletNode(this.ctx, "volume-meter");
    this.volumeMeterNode.port.onmessage = ({ data }: { data: number }) => {
      const noise = toRelative({
        value: data,
        sensitivity: this.sensitivity.value,
        correction: this.correction
      });

      this.processData(noise);
    };
    this.microphoneNode.connect(this.volumeMeterNode).connect(this.ctx.destination);
    this.ctx.resume();
  };

  private static destroyConnectedStream = () => {
    this.microphoneNode?.disconnect();
    this.volumeMeterNode?.disconnect();
  };

  private static processData = (noiseLevel: number) => {
    try {
      this.emitter.emit("raw-data", noiseLevel);
      if (noiseLevel === 0 && this.lastEmit === "noise" && !this.minimumRestTimeout) {
        this.minimumRestTimeout = setTimeout(() => {
          if (this.minimumRestTimeout) {
            clearTimeout(this.minimumRestTimeout);
            this.minimumRestTimeout = null;
          }

          this.lastEmit = "rest";

          this.emitter.emit("change", false, noiseLevel);
          if (this.currentEventId) EventManager.endEvent(this.currentEventId);
        }, 2000);
      }

      if (noiseLevel > 0 && this.lastEmit === "rest") {
        this.lastEmit = "noise";

        this.emitter.emit("change", true, noiseLevel);
        this.currentEventId = EventManager.startEvent("NOISE");
      }

      if (this.minimumRestTimeout && noiseLevel > 0) {
        clearTimeout(this.minimumRestTimeout);
        this.minimumRestTimeout = null;
      }
    } catch (err) {
      log.err("Failed 'processData'", err);
      this.forceRest();
    }
  };

  private static forceRestOnEventMaximumDuration = (eventId: string) => {
    if (eventId === this.currentEventId) this.forceRest();
  };

  private static forceRest = () => {
    if (this.minimumRestTimeout) {
      clearTimeout(this.minimumRestTimeout);
      this.minimumRestTimeout = null;
    }
    this.lastEmit = "rest";
    this.emitter.emit("change", false, 0);
    this.emitter.emit("raw-data", 0);
    if (this.currentEventId) EventManager.endEvent(this.currentEventId);
  };

  private static destroy = () => {
    this.ctx.close();
    this.destroyConnectedStream();
  };
}
