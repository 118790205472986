import protoRoot from "../proto-root";
import BatteryStatus from "../private/BatteryStatus";
import CameraAndFlashlightState from "../private/CameraAndFlashlightState";
import CameraInfo from "../private/CameraInfo";

const name = "startMonitoringRPCResponse";

function create(payload: StartMonitoringResponse) {
  const StartMonitoringRPCResponse = protoRoot.lookupType(
    "comm.StartMonitoringRPCResponse"
  );

  const batteryStatus = BatteryStatus.create(payload.batteryStatus);
  const cameraState = CameraAndFlashlightState.create({
    cameraId: payload.cameraState.cameraId || "",
  });
  const configuration = {
    availableCameras: payload.configuration.availableCameras.map((camera) =>
      CameraInfo.create(camera)
    ),
  };

  return {
    payload: StartMonitoringRPCResponse.create({
      batteryStatus,
      cameraState,
      configuration,
    }),
    name,
  };
}

const startMonitoringRPCResponse = {
  create,
  name,
};

export default startMonitoringRPCResponse;
