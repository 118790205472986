import {
  Menu as ChakraMenu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemProps,
  MenuProps,
  MenuOptionGroup,
  MenuItemOption,
  MenuItemOptionProps,
  MenuOptionGroupProps,
  Text,
  TextProps,
  Box
} from "@chakra-ui/react";

type Props = {
  children: any;
  offset?: MenuProps["offset"];
  items: {
    props?: MenuItemProps;
    item: any;
  }[];
};

export default function Menu({ children, items, offset }: Props) {
  return (
    <ChakraMenu offset={offset} placement="bottom-end">
      <MenuButton>{children}</MenuButton>
      <MenuList>
        {items.map(({ item, props }, i) => {
          return (
            <MenuItem key={i} {...props}>
              {item}
            </MenuItem>
          );
        })}
      </MenuList>
    </ChakraMenu>
  );
}

type OptionsMenuProps = {
  children: any;
  label: string;
  menuOptionGroupProps?: MenuOptionGroupProps;
  items: {
    props?: MenuItemOptionProps;
    value: string;
    item: any;
  }[];
};
const SelectedIndicator = () => <Box w="0.25rem" h="1.5rem" bg="primary" borderRadius="7px" />;

export function OptionsMenu({ children, items, menuOptionGroupProps }: OptionsMenuProps) {
  return (
    <ChakraMenu placement="bottom-end" variant="options" computePositionOnMount>
      <MenuButton>{children}</MenuButton>
      <MenuList minW="166px">
        <MenuOptionGroup {...menuOptionGroupProps} type="radio">
          {items.map(({ item, props, value }, i) => (
            <MenuItemOption icon={<SelectedIndicator />} key={i} value={value} {...props}>
              <Text variant="title" color="on-surface">
                {item}
              </Text>
            </MenuItemOption>
          ))}
        </MenuOptionGroup>
      </MenuList>
    </ChakraMenu>
  );
}

export const OptionsMenuButton = ({ children, props }: { children: any; props?: TextProps }) => (
  <Text
    variant="body"
    py="2"
    pl="12"
    pr="8"
    border="1px"
    borderColor="outline"
    bg="surface-variant"
    borderRadius="14px"
    display="flex"
    alignItems="center"
    {...props}
  >
    {children}
  </Text>
);

export type { Props as MenuProps, OptionsMenuProps };
