import environment from "@/modules/environment";
import { unsafeFetch } from "@/lib/fetch";
import { useAuth } from "@/store/auth";
import { useInstallation } from "@/store/installation";
import { dataSyncEmitter } from "../events/emitter";

const headers = {
  "Content-Type": "text/plain"
};

async function run(
  func: string,
  body?: object,
  { path = "functions", rethrow = false }: { path?: string; rethrow?: boolean } = {}
) {
  const sessionToken = useAuth.getState().user?.sessionToken;
  const installationId = useInstallation.getState()?.installationId;

  if (!sessionToken) log.warn(`Running cloud code '${func}' without session token`);
  try {
    const response = await unsafeFetch(`${environment.parseUrl}/${path}/${func}`, {
      headers,
      body: JSON.stringify({
        ...(sessionToken && { _SessionToken: sessionToken }),
        _ApplicationId: environment.appName,
        _InstallationId: installationId,
        ...body
      }),
      method: "POST"
    });
    return response;
  } catch (err: any) {
    log.err(`Error running cloud code '${func}': `, err);
    if (err && (err.message === "Invalid session token" || err.message === "cloudCode requires valid session!")) {
      log.auth("Invalid session token or invalid session");
      dataSyncEmitter.emit("invalid-session");
    }
    if (rethrow) throw err;
    return null;
  }
}

const cloudCode = { run };

export { cloudCode };
