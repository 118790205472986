import protoRoot from "../proto-root";

function create({ cameraId }: { cameraId: string }) {
  const CameraAndFlashlightState = protoRoot.lookupType(
    `comm.CameraAndFlashlightState`
  );

  return CameraAndFlashlightState.create({
    flashlightAvailable: false,
    flashlightEnabled: false,
    flashlightAdjustable: false,
    flashlightIntensity: 0,
    cameraId,
  });
}

const cameraAndFlashlightState = { create };

export default cameraAndFlashlightState;
