import { useMemo } from "react";
import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import Menu, { MenuProps } from "@/components/Menu";
import Circle from "@/components/Circle";
import Icon from "@/components/Icon";
import IconButton from "@/components/IconButton";
import { useTransparentize } from "@/theme/utils/transparentize";
import { CameraColors, CameraIcons } from "@/enums/camera";
import MoreIcon from "assets/icons/vertical_dots.svg?react";
import TrashIcon from "assets/icons/trash.svg?react";
import EditIcon from "assets/icons/edit_outlined.svg?react";
import { t } from "@/utils";
import { textOverflowDots } from "@/theme/utils/style";

type DeviceCardProps = {
  name: string;
  deviceName: string;
  icon: CameraIconId;
  color: CameraColorId;
  openDeleteFlow: Cb;
  openCustomize: Cb;
};

export default function DeviceCard({ name, color, deviceName, icon, openDeleteFlow, openCustomize }: DeviceCardProps) {
  const borderColor = useTransparentize("neutral.99", 0.2);

  const items: MenuProps["items"] = useMemo(
    () => [
      {
        item: t("buttons.delete"),
        props: {
          icon: <Icon icon={TrashIcon} fill="on-surface" />,
          onClick: openDeleteFlow
        }
      }
    ],
    [openDeleteFlow]
  );

  return (
    <Box w="100%" borderRadius="1rem" py="1.5rem" px="1rem" border="1px solid" borderColor="outline">
      <Flex alignItems="center">
        <Circle
          bg="surface"
          props={{
            border: "1px solid",
            borderColor: borderColor
          }}
        >
          <Icon icon={CameraIcons[icon].icon} fill={CameraColors[color].value} />
        </Circle>

        <Box flexGrow="1" mx="1rem" overflow="hidden">
          <Heading as="h5" {...textOverflowDots}>
            {name}
          </Heading>
          <Text variant="subheader" color="on-surface-variant" fontSize="16px" lineHeight="24px" {...textOverflowDots}>
            {deviceName}
          </Text>
        </Box>
        <Flex columnGap="1rem">
          <IconButton
            icon={EditIcon}
            iconSize="24px"
            fill="on-surface"
            onClick={openCustomize}
            tooltip={{ label: t("buttons.edit") }}
          />
        </Flex>
      </Flex>
    </Box>
  );
}
