import protobuf from "protobufjs";
import { decodeProtocolBuffer } from "../decode";
import previewStatus from "./PreviewStatus";
import protoRoot from "../proto-root";

function create({ image, status }: CameraStationPreview) {
  const CameraStationPreview = protoRoot.lookupType("comm.CameraStationPreview");

  return CameraStationPreview.create({
    image,
    status: previewStatus.create(status as Required<CameraStatus>)
  });
}

function encode(payload: CameraStationPreview) {
  const cameraStationPreview = create(payload);
  const CameraStationPreview = protoRoot.lookupType("comm.CameraStationPreview");
  const buffer = CameraStationPreview.encode(cameraStationPreview).finish();
  return protobuf.util.base64.encode(buffer, 0, buffer.length);
}

function decode(base64: string): CameraStationPreview {
  const buffer = new Uint8Array(protobuf.util.base64.length(base64));
  protobuf.util.base64.decode(base64, buffer, 0);

  return decodeProtocolBuffer({
    typePath: "comm.CameraStationPreview",
    buffer,
    root: protoRoot
  }) as CameraStationPreview;
}

const cameraStationPreview = { create, encode, decode };

export default cameraStationPreview;
