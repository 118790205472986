import protoRoot from "../proto-root";

const name = "startMonitoringRPCRequest";

function create() {
  const StartMonitoringRPCRequest = protoRoot.lookupType(
    "comm.StartMonitoringRPCRequest"
  );

  return { payload: StartMonitoringRPCRequest.create(), name };
}

const startMonitoringRPCRequest = {
  create,
  name,
};

export default startMonitoringRPCRequest;
