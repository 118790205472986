import environment from "@/modules/environment";
import { useAuth } from "@/store/auth";
import capitalizeFirstLetter from "./capitalizeFirstLetter";

type ClientConfig = {
  subject?: string;
  body?: string;
};

function open(params: ClientConfig) {
  return (window.location.href = `mailto:${environment.mail}?subject=${params.subject}&body=${params.body}`);
}

export function openEmailClient() {
  const { device, user } = useAuth.getState();
  const title = `${capitalizeFirstLetter(
    environment.appName
  )} - Desktop feedback`;
  const debugInfo = `
%0D%0A
%0D%0A
%0D%0A
-----%0D%0A
Account: ${user?.objectId}%0D%0A
JID: ${device.xmppCredentials?.xmppLogin}%0D%0A
Browser: ${environment.system.getBrowserName()}%0D%0A
OS: ${environment.system.getOSName()}%0D%0A
Version: ${__APP_VERSION__}
 `;

  open({
    subject: title,
    body: debugInfo,
  });
}
