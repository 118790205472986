import { Text, Flex, Button } from "@chakra-ui/react";
import { absoluteCenter } from "@/theme/utils/style";
import Icon from "@/components/Icon";
import { openEmailClient, t } from "@/utils";
import CameraIcon from "assets/icons/camera.svg?react";

export default function NoDevicesAvailable() {
  return (
    <Flex {...absoluteCenter} justify="center" align="center" flexDir="column" textAlign="center" w="20rem">
      <Icon icon={CameraIcon} fill="inverse-primary" />
      <Text
        mt="1rem"
        mb="0.5rem"
        pt="2rem"
        color="#FBF8FD"
        fontSize="1.0625rem"
        fontWeight={600}
        lineHeight="1.375rem"
        letterSpacing="-0.0255rem"
      >
        {t("error.missingDevices.title")}
      </Text>
      <Text fontSize="0.8125rem" fontWeight={400} lineHeight="1.125rem" letterSpacing="-0.00488rem" color="#FBF8FD">
        {t("error.missingDevices.subtitle", {
          1: (
            <Button
              variant="unstyled"
              fontSize="0.8125rem"
              color="primary"
              fontWeight={400}
              _hover={{ bg: "none" }}
              _active={{ bg: "none" }}
              onClick={() => openEmailClient()}
            />
          )
        })}
      </Text>
    </Flex>
  );
}
