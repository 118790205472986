import { isNotEmptyObject } from "@/utils";

export const getCameraDevices = (state: DevicesStore) =>
  Object.values(state.devices).filter((device): device is StoreCameraDevice => device.role === "camera");

export const getViewerDevices = (state: DevicesStore) =>
  Object.values(state.devices).filter((device): device is StoreViewerDevice => device.role === "viewer");

export const hasPairedDevices = (state: DevicesStore) => isNotEmptyObject(state.devices);

export const getDeviceByObjectId = (objectId?: string) => (state: DevicesStore) =>
  Object.values(state.devices).find((d) => d.objectId === objectId);

const selectors = {
  getCameraDevices,
  getViewerDevices,
  hasPairedDevices,
  getDeviceByObjectId
};

export default selectors;
