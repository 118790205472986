import protoRoot from "../proto-root";

const name = "stopVideoReceivingRPCRequest";

function create() {
  const StopVideoReceivingRPCRequest = protoRoot.lookupType(
    "comm.StopVideoReceivingRPCRequest"
  );

  const payload = {};

  const request = StopVideoReceivingRPCRequest.create(payload);
  return { payload: request, name };
}

const stopVideoReceivingRPCRequest = { create, name };

export default stopVideoReceivingRPCRequest;
