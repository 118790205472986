import { MotionSens, MotionThresholds } from "@/enums/motion";
import EventEmitter from "@/lib/EventEmitter";
import { dataSyncEmitter } from "@/modules/events/emitter";
import { CameraStore, cameraStore, useCamera } from "camera/store/camera";
import { stationStore, useStation } from "camera/store/station";
import EventManager from "../events/EventManager";
import streamQualityUpdater from "../station/streamQualityUpdater";
import { PROCESS_INTERVAL, ONE_SECOND, SCALE_FACTOR } from "./constants";

const debug = false;

export default class MotionDetector {
  private static emitter = new EventEmitter();
  private static get openCvState() {
    return cameraStore().openCvState;
  }
  private static get isEnabled() {
    return stationStore().settings.motionDetectionEnabled;
  }
  static video: HTMLVideoElement | null = null;

  static state: "idle" | "running" = "idle";
  private static lastEmit: "rest" | "motion" = "rest";
  private static motionFrames = 0;
  private static motionStartDate = 0;
  private static canProcess = true;
  private static sensitivity = stationStore().settings.motionSensitivity;
  private static currentEventId: string | undefined;
  private static clearExistingMats = () => {};
  private static timeoutId: NodeJS.Timeout | null = null;

  private static updateSensitivity = (sensitivity: MotionDetectionSensitivity) => {
    log.motion("sensitivity changed in store:", sensitivity);
    this.sensitivity = sensitivity;
  };

  private static updateIsEnabled = (enabled: boolean) => {
    log.motion("isEnabled changed in store:", enabled);
    if (!enabled) {
      this.stop();
    } else if (this.state === "idle") this.start();
  };

  private static disableForSomeTime = () => {
    log.motion("'disableForSomeTime' fired");
    this.stop();
    setTimeout(() => this.start(), 5000);
  };

  static startListeningForUpdates = () => {
    useStation.subscribe((store) => store.settings.motionSensitivity, this.updateSensitivity);
    useStation.subscribe((store) => store.settings.motionDetectionEnabled, this.updateIsEnabled);
    dataSyncEmitter.on("event-maximum-duration-reached", this.forceRestOnEventMaximumDuration);
    streamQualityUpdater.on("stream-quality-change-start", this.disableForSomeTime);
  };

  static on = this.emitter.on;
  static off = this.emitter.off;

  static start = () => {
    if (this.state === "running") return;
    if (!this.isEnabled) {
      log.motion("Not starting MotionDetector, disabled in 'camera store'");
      return;
    }
    if (this.openCvState === "ready") this.startVideoProcessing();
    if (this.openCvState === "loading") this.waitForReadyState();
  };

  static stop = () => {
    this.forceRest();
    this.stopVideoProcessing();
  };

  private static waitForReadyState = () => {
    log.motion("waiting for OpenCV ready state...");
    const unsubscribe = useCamera.subscribe((store: CameraStore) => {
      if (store.openCvState === "ready") {
        this.startVideoProcessing();
        unsubscribe();
      }
    });
  };

  private static startVideoProcessing = async () => {
    if (!this.video) {
      log.warn("Couln't start motion detector, source video element is:", this.video);
      return;
    }
    this.state = "running";
    log.info("About to start processing video for motion detection");

    const deleteDebugBox = debug ? this.createDebugBox() : null;
    this.canProcess = true;
    const cv = await window.cv;

    const video = this.video as HTMLVideoElement;
    video.height = this.video.offsetHeight;
    video.width = this.video.offsetWidth;

    const cap = new cv.VideoCapture(video);

    const frame = new cv.Mat(video.height, video.width, cv.CV_8UC4);
    const grayFrame = new cv.Mat();
    const refFrame = new cv.Mat();
    const diffFrame = new cv.Mat();
    const threshFrame = new cv.Mat();
    let averageMotion = 0;

    const processVideo = () => {
      try {
        if (!this.canProcess) {
          this.clearExistingMats = () => {
            frame.delete();
            grayFrame.delete();
            refFrame.delete();
            diffFrame.delete();
            threshFrame.delete();
            deleteDebugBox?.();
          };
          this.clearExistingMats();
          return;
        }
        if (!this.isEnabled) return;

        cap.read(frame);
        cv.resize(frame, grayFrame, new cv.Size(0, 0), SCALE_FACTOR, SCALE_FACTOR);
        cv.cvtColor(grayFrame, grayFrame, cv.COLOR_RGBA2GRAY);

        const nonZeroElements = cv.countNonZero(grayFrame);
        const isBrightEnough = this.checkVideoBrightness(grayFrame, nonZeroElements);
        if (!isBrightEnough) {
          this.timeoutId = setTimeout(processVideo, PROCESS_INTERVAL);
          return;
        }

        if (!refFrame.rows || !refFrame.cols) {
          grayFrame.copyTo(refFrame);
        }
        cv.absdiff(grayFrame, refFrame, diffFrame);
        cv.threshold(diffFrame, threshFrame, MotionSens[this.sensitivity].diffThreshold, 255, cv.THRESH_BINARY);

        const newAverageMotion = cv.countNonZero(threshFrame);
        const elements = grayFrame.rows * grayFrame.cols;

        averageMotion = 0.8 * averageMotion + 0.2 * newAverageMotion;
        const averageMotionElements = averageMotion / elements;

        const isAboveThreshold = averageMotionElements > MotionThresholds[this.sensitivity];

        if (isAboveThreshold) {
          this.onMotion();
        } else {
          this.onRest();
        }
        if (debug) cv.imshow("motion-debug-container", threshFrame);

        grayFrame.copyTo(refFrame);
      } catch (err) {
        log.err("Failed 'processVideo'", err);
        this.forceRest();
      }

      this.timeoutId = setTimeout(processVideo, PROCESS_INTERVAL);
    };
    processVideo();
  };

  private static stopVideoProcessing = () => {
    log.info("Stopping video processing for motion");
    if (this.timeoutId) clearTimeout(this.timeoutId);
    this.state = "idle";
    this.canProcess = false;
    this.lastEmit = "rest";
    this.motionFrames = 0;
    this.motionStartDate = 0;
    this.clearExistingMats();
  };

  private static onMotion = () => {
    if (this.motionFrames < 2) {
      this.motionFrames += 1;
      return;
    }
    this.motionStartDate = Date.now();

    if (this.lastEmit === "motion") return;
    this.emitter.emit("change", true);
    this.lastEmit = "motion";
    this.currentEventId = EventManager.startEvent("MOTION");
  };

  private static onRest = () => {
    if (this.motionStartDate + ONE_SECOND > Date.now()) {
      return;
    }
    this.motionFrames = 0;

    if (this.lastEmit === "rest") return;
    this.emitter.emit("change", false);
    this.lastEmit = "rest";
    if (this.currentEventId) EventManager.endEvent(this.currentEventId);
  };

  private static checkVideoBrightness = (grayFrame: any, nonZeroElements: any) => {
    const elements = grayFrame.rows * grayFrame.cols;
    const brightness = Math.round((nonZeroElements / elements) * 100);

    if (brightness <= 10) {
      log.motion("brightness too low");
      return false;
    } else {
      return true;
    }
  };

  private static forceRestOnEventMaximumDuration = (eventId: string) => {
    if (eventId === this.currentEventId) this.forceRest();
  };

  private static forceRest = () => {
    this.motionFrames = 0;
    this.emitter.emit("change", false);
    this.lastEmit = "rest";
    if (this.currentEventId) EventManager.endEvent(this.currentEventId);
  };

  private static createDebugBox = () => {
    const cameraContainer = document.querySelector("#camera-video-container");
    const canvas = document.createElement("canvas");
    canvas.id = "motion-debug-container";
    canvas.style.position = "absolute";
    canvas.style.top = "0px";
    canvas.style.left = "0px";
    canvas.style.width = "100%";
    canvas.style.height = "100%";
    canvas.style.opacity = "0.2";
    cameraContainer?.appendChild(canvas);
    return canvas.remove;
  };
}
