import protoRoot from "../proto-root";
import createMessage from "./createMessage";

const name = "requestPairingResponseMessage";

function create(payload: RequestPairingResponseMessage) {
  const PairingType = protoRoot.lookupEnum("comm.PairingType");
  const RequestPairingResult = protoRoot.lookupEnum(
    "comm.RequestPairingResult"
  );

  const responsePayload = {
    ...payload,
    pairingType: PairingType.values[payload.pairingType],
    result: RequestPairingResult.values[payload.result],
  };

  const RequestPairingResponseMessage = protoRoot.lookupType(
    `comm.RequestPairingResponseMessage`
  );
  const requestPairingResponseMessage =
    RequestPairingResponseMessage.create(responsePayload);

  return createMessage({ [name]: requestPairingResponseMessage });
}

const requestPairingResponseMessage = { create, name };

export default requestPairingResponseMessage;
