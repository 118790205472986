import ChangeCamera from "./ChangeCamera";
import GetSignedUrl from "./GetSignedUrl";
import Ping from "./Ping";
import StartMonitoring from "./StartMonitoring";
import StartVideoReceiving from "./StartVideoReceiving";
import StopVideoReceiving from "./StopVideoReceiving";
import StartVideoBroadcasting from "./StartVideoBroadcasting";
import StartVideoRecording from "./StartVideoRecording";
import StopMonitoring from "./StopMonitoring";
import StopVideoBroadcasting from "./StopVideoBroadcasting";
import StopVideoRecording from "./StopVideoRecording";
import SetLowLightMode from "./SetLowLightMode";
import TakeVideoSnapshot from "./TakeVideoSnapshot";
import ReloadCameraHistoryReplayEvents from "./ReloadCameraHistoryReplayEvents";
import StartCameraHistoryReplayEvents from "./StartCameraHistoryReplayEvents";
import StopCameraHistoryReplayEvents from "./StopCameraHistoryReplayEvents";
import GetCameraHistoryStreamPlaylistFile from "./GetCameraHistoryStreamPlaylistFile";
import GetCameraHistoryStreamSegmentFile from "./GetCameraHistoryStreamSegmentFile";
import SyncMonitoring from "./SyncMonitoring";

export { default as RPCRequest } from "./RPCRequest";

export const RPCRequests = {
  ChangeCamera,
  GetSignedUrl,
  Ping,
  StartMonitoring,
  StartVideoReceiving,
  StopVideoReceiving,
  StartVideoBroadcasting,
  StartVideoRecording,
  StopMonitoring,
  StopVideoBroadcasting,
  StopVideoRecording,
  SetLowLightMode,
  TakeVideoSnapshot,
  ReloadCameraHistoryReplayEvents,
  StartCameraHistoryReplayEvents,
  StopCameraHistoryReplayEvents,
  GetCameraHistoryStreamPlaylistFile,
  GetCameraHistoryStreamSegmentFile,
  SyncMonitoring
};
