import type { SystemStyleObject } from "@chakra-ui/styled-system";

const baseStyle: SystemStyleObject = {
  w: ["container.sm", "container.md", "container.lg"],
  mx: "auto",
};

export default {
  baseStyle,
};
