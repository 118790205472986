interface EmitterEvents {
  [key: string]: Function[];
}

export default class EventEmitter {
  public events: EmitterEvents;
  constructor(events?: EmitterEvents) {
    this.events = events || {};
  }

  public on = <T extends string>(event: T, callback: Function) => {
    log.emitter("Registering event", event);
    this.events[event] = this.events[event] || [];
    this.events[event].push(callback);

    return () => this.off(event, callback);
  };

  public emit = <T extends string>(event: T, ...args: any[]) => {
    if (!this.events[event]) return;
    // if(this.events[event].length > 0) log.emitter("Emitting event", event);
    this.events[event].forEach((callback) => callback(...args));
  };

  public off = <T extends string>(event: T, callback: Function) => {
    if (!this.events[event]) return;
    log.emitter("Unsubscribing from event", event);
    this.events[event] = this.events[event].filter((cb) => cb !== callback);
  };

  public clear = () => {
    this.events = {};
  };
}
