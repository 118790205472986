import { useApp } from "@/store/app";

const ONE_MINUTE = 60 * 1000;
const TIMEOUT = 20 * 1000;

export default class PingService {
  constructor(private getConnection: () => Strophe.Connection) {}
  private connection!: Strophe.Connection;
  private pingInterval: NodeJS.Timeout | null = null;
  private pingHandler: StropheHandler | null = null;
  private isRunning = false;
  private timeoutId: NodeJS.Timeout | null = null;

  start = async () => {
    if (this.isRunning) this.stop();
    this.connection = this.getConnection();

    this.isRunning = true;
    log.xmppPing("Starting ping service...");
    this.pingHandler = this.connection.addHandler(this.onPing, "urn:xmpp:ping", "iq", "get");
    if (isDev) {
      log.info("Not starting ping process in dev mode");
      return;
    }
    this.pingInterval = setInterval(async () => {
      this.ping();
    }, ONE_MINUTE);
  };

  private ping = async () => {
    try {
      const iq = $iq({ type: "get" }).c("ping", { xmlns: "urn:xmpp:ping" });
      this.connection.sendIQ(
        iq,
        (iq) => log.xmppPing("Ping success", iq),
        (iq) => {
          if (!iq) log.xmppPing("Ping timed out");
          else log.xmppPing("Error sending ping", iq);
          useApp.getState().setStatus("XMPP_DISCONNECTED");
        },
        TIMEOUT
      );
    } catch (err) {
      log.xmppPing("Error sending ping", err);
    }
  };

  stop = () => {
    log.xmppPing("Stopping ping service...");
    if (this.timeoutId) clearTimeout(this.timeoutId);
    if (this.connection && this.pingHandler) this.connection.deleteHandler(this.pingHandler);
    if (this.pingInterval) clearInterval(this.pingInterval);
    this.pingInterval = null;
    this.isRunning = false;
  };

  private onPing = (iq: Element) => {
    var from = iq.getAttribute("from");
    var id = iq.getAttribute("id");
    if (!from || !id) {
      log.xmppPing("Received ping without 'from' or 'id' attribute", iq);
      return true;
    }

    const ping = $iq({ type: "result", to: from, id: id });
    this.connection.sendIQ(ping);
    return true;
  };
}
