const variantBase = {
  borderBottom: "1px solid !important",
  borderColor: "outline",
  opacity: 0.16,
};
const defaultProps = {
  variant: "base",
};

const variants = {
  base: variantBase,
};

const divider = {
  variants,
  defaultProps,
};

export default divider;
