import { dataSyncEmitter } from "@/modules/events/emitter";

const ONE_DAY = 24 * 60 * 60 * 1000;
let isCurrent = true;
const checkVersion = async () => {
  try {
    const response = await fetch("app-version.json", { cache: "no-store" });
    const { version } = await response.json();
    if (version > __APP_VERSION__) {
      log.app(`New version available: ${version} > ${__APP_VERSION__}`);
      dataSyncEmitter.emit("new-version-available");
      isCurrent = false;
    }
  } catch (err) {
    log.err((err as Error)?.message || err || "Error trying to fetch current app version");
  }
};

const create = () => {
  checkVersion();
  setInterval(checkVersion, ONE_DAY);
};

const getIsCurrent = () => isCurrent;

const appUpdater = { create, getIsCurrent };
export default appUpdater;
