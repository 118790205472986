import { DeveloperToggles } from "@/utils";

export const getHasActiveSubscription = (state: AuthState) => {
  const devToggle = DeveloperToggles.get("premium");
  if (devToggle !== undefined) {
    log.warn("Developer toggle for premium is active");
    return devToggle;
  }

  const expirationDate = state.user?.subscription?.expirationDate;
  if (expirationDate) {
    const now = new Date();
    if (new Date(expirationDate.iso) > now) return true;
  }
  return false;
};

const selectors = {
  getHasActiveSubscription
};
export default selectors;
