export function isArray(value: any): value is Array<any> {
  return Array.isArray(value);
}

export function isEmptyArray(value: any[]): boolean;
export function isEmptyArray(value: any): value is Array<any>;
export function isEmptyArray(value: any): any {
  return isArray(value) && value.length === 0;
}

export function isObject(value: any): value is Object {
  const type = typeof value;
  return (
    value != null &&
    (type === "object" || type === "function") &&
    !isArray(value)
  );
}
export function isEmptyObject(value: any): value is Object {
  return isObject(value) && Object.keys(value).length === 0;
}
export function isNotEmptyObject(value: any): value is Object {
  return value && !isEmptyObject(value);
}
