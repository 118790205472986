import { t } from "@/utils";
import { Box, Flex, Radio, RadioGroup, Stack } from "@chakra-ui/react";
import NavigationBar from "@/components/NavigationBar";
import { VideoQualityKeysArray, VideoQualityStringKeys, VideoResolutionKeys } from "@/enums/videoQuality";
import { useButtonStateColors } from "@/theme/utils/getButtonStateColor";
import useVideoConstraints from "@/modules/stream/useVideoConstraints";
import { usePremiumScreen } from "@/modules/app/PremiumScreen";

export default function VideoQuality({
  Progress,
  previous,
  updateSettings,
  cameraSettings
}: CSTypes.CameraSettingsStepProps) {
  const { displayBannerForFreeUser } = usePremiumScreen();
  const { quality } = useVideoConstraints(cameraSettings.settings.videoQuality);
  const { active, hover } = useButtonStateColors("on-surface", "surface2");

  const handleChange = (videoQuality: VideoQuality) => {
    displayBannerForFreeUser("hd").elseRun(() => updateSettings({ settings: { videoQuality } }));
  };

  return (
    <Box>
      {Progress && <Progress />}
      <NavigationBar title={t("videoSettings.quality.title")} onBack={previous} props={{ mb: "8" }} />
      <RadioGroup value={quality} onChange={handleChange}>
        <Stack spacing="16">
          {VideoQualityKeysArray.map((videoQuality) => {
            return (
              <Flex
                key={videoQuality}
                border="1px solid"
                borderColor="outline"
                borderRadius="md"
                transition="background-color 0.2s"
                _hover={{ bg: hover }}
                _active={{ bg: active }}
              >
                <Radio p="12" w="100%" h="100%" value={videoQuality}>
                  {t(VideoQualityStringKeys[videoQuality])} ({VideoResolutionKeys[videoQuality]})
                </Radio>
              </Flex>
            );
          })}
        </Stack>
      </RadioGroup>
    </Box>
  );
}
