import { SystemStyleFunction } from "@chakra-ui/react";
import memoizedGetColor from "@/lib/memoizedGetColor";
import getButtonStateColor from "../utils/getButtonStateColor";
import { ACTIVE_OPACITY, HOVER_OPACITY } from "../utils/constants";

const memoizedGet = memoizedGetColor(getButtonStateColor);

const baseStyle = {
  fontSize: ["md", "md", "lg"],
  lineHeight: "4",
  borderRadius: ["24px", "28px", "32px"],
  fontWeight: ["medium", "bold"],
  letterSpacing: ["0.5px", "0.71px", "0.8px"],
  transitionProperty: "common",
  transitionDuration: "normal",
  userSelect: "none",
  _focusVisible: {
    boxShadow: "outline",
  },
  _disabled: {
    opacity: 0.4,
    cursor: "not-allowed",
    boxShadow: "none",
    pointerEvents: "none",
    button: { pointerEvents: "none" },
    span: { pointerEvents: "none" },
  },
  bg: "none",
  color: "inherit",
  display: "inline",
  m: 0,
  p: 0,
};

const variantInteractive: SystemStyleFunction = (props) => ({
  ...baseStyle,
  _hover: {
    cursor: "pointer",
    bg: memoizedGet(props, "on-surface", HOVER_OPACITY),
    _disabled: {
      bg: "initial",
    },
  },
  _active: {
    bg: memoizedGet(props, "on-surface", ACTIVE_OPACITY),
  },
});

const variantStatic = baseStyle;

const variants = {
  interactive: variantInteractive,
  static: variantStatic,
};

const defaultProps = {
  variant: "interactive",
};

const formLabel = {
  baseStyle,
  variants,
  defaultProps,
};

export default formLabel;
