import protoRoot from "../proto-root";
import CameraEvent from "../private/CameraEvent";
import createMessage from "./createMessage";

const name = "cameraHistoryReplayEventUpdate";

function create({
  updateNumber,
  updateType,
  event,
}: CameraHistoryReplayEventUpdate) {
  const CameraHistoryReplayEventUpdate = protoRoot.lookupType(
    "comm.CameraHistoryReplayEventUpdate"
  );
  const UpdateType = CameraHistoryReplayEventUpdate.lookupEnum("UpdateType");

  const cameraHistoryReplayEventUpdate = CameraHistoryReplayEventUpdate.create({
    updateNumber,
    updateType: UpdateType.values[updateType],
    event: CameraEvent.create(event),
  });

  return createMessage({ [name]: cameraHistoryReplayEventUpdate });
}

const cameraHistoryReplayEventUpdate = { create, name };

export default cameraHistoryReplayEventUpdate;
