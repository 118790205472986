import { commEmitter } from "../events/emitter";
import { confirmItemSync, getSyncState } from "../cloud/sync";
import { messages } from "../communication";
import synchronizableItems from "./synchronizable-items";
import ItemSynchronizers from "./ItemSynchronizers";

class Synchronizer {
  private static instance: Synchronizer;
  static getInstance() {
    if (!Synchronizer.instance) {
      Synchronizer.instance = new Synchronizer();
      log.synchronizer("Synchronizer initialized");
    }
    return Synchronizer.instance;
  }
  private syncRequestQueue: SyncItems[] = [];
  private synchronizing = false;
  constructor() {
    commEmitter.on(messages.CloudAccountSyncMessage.name, this.onCloudAccountSync);
  }

  private synchronizeItem = async (item: CloudAccountSyncItem) => {
    try {
      log.synchronizer("Synchronizing item", item);
      switch (item) {
        case "CLOUD_ACCOUNT":
          await ItemSynchronizers.synchronizeCloudAccount();
          break;
        case "CLOUD_SETTINGS":
          await ItemSynchronizers.synchronizeCloudSettings();
          break;
        case "DEVICES":
          await ItemSynchronizers.synchronizeDevices();
          break;
      }
      await confirmItemSync(item);
    } catch (err) {
      log.synchronizer("Error synchronizing item", err);
    }
  };

  synchronize = async ({ items, syncAll, syncAlways }: SynchronizeParams = {}) => {
    let syncItemsObject: SyncItems = items || {};
    if (syncAll) {
      log.synchronizer("syncAll items requested");

      syncItemsObject = Object.values(synchronizableItems).reduce((acc, itemName) => {
        acc[itemName] = false;
        return acc;
      }, {} as SyncItems);
    } else if (items == null) {
      syncItemsObject = await getSyncState();
    }

    if (syncAlways)
      syncAlways.forEach((itemName) => {
        log.synchronizer("Forcing sync of", itemName);
        syncItemsObject[itemName as CloudAccountSyncItem] = false;
      });

    const itemsToSync = Object.keys(syncItemsObject).filter(
      (itemName) => syncItemsObject[itemName as CloudAccountSyncItem] === false
    ) as CloudAccountSyncItem[];

    log.synchronizer("resolved items to sync", itemsToSync);

    if (this.synchronizing) {
      this.syncRequestQueue.push(syncItemsObject);
      return;
    } else this.synchronizing = true;

    await Promise.all(itemsToSync.map(this.synchronizeItem));

    this.synchronizing = false;
    if (this.syncRequestQueue[0]) {
      await this.synchronize({
        items: this.syncRequestQueue.shift() as SyncItems
      });
    }
  };

  private onCloudAccountSync = (message: CloudAccountSyncMessage, from: string) => {
    log.synchronizer("Received cloud account sync message", message, from);
    const { item } = message;
    if (item === "ALL") this.synchronize({ syncAll: true });
    else this.synchronize({ items: { [item]: false } });
  };

  synchronizeCloudAccount = () => this.synchronize({ items: { CLOUD_ACCOUNT: false } });
  synchronizeDevices = () => this.synchronize({ items: { DEVICES: false } });
}

export default Synchronizer;
