// Getter of server values. In case of
// server values changes, we change just the
// server value and platform values stays the same

type TransformerKey = keyof typeof transformers;
type TransformerServerValues = keyof (typeof transformers)[TransformerKey]["serverToPlatform"];
type TransformerPlatformValues = keyof (typeof transformers)[TransformerKey]["platformToServer"];

const serverToPlatformMotionSensitivity = {
  low: "LOW",
  medium: "MEDIUM",
  high: "HIGH"
} as const;

const serverToPlatformMicSensitivity = {
  very_low: "VERY_LOW",
  low: "LOW",
  medium: "MEDIUM",
  high: "HIGH",
  very_high: "VERY_HIGH"
} as const;

const serverToPlatformVideoQuality = {
  "720p": "HD",
  "480p": "HIGH",
  "360p": "MEDIUM",
  "288p": "LOW"
} as const;

const platformToServerMotionSensitivity = {
  LOW: "low",
  MEDIUM: "medium",
  HIGH: "high"
} as const;

const platformToServerMicSensitivity = {
  VERY_LOW: "very_low",
  LOW: "low",
  MEDIUM: "medium",
  HIGH: "high",
  VERY_HIGH: "very_high"
} as const;

const platformToServerVideoQuality = {
  HD: "720p",
  HIGH: "480p",
  MEDIUM: "360p",
  LOW: "288p"
} as const;

const transformers = {
  micSensitivity: {
    serverToPlatform: serverToPlatformMicSensitivity,
    platformToServer: platformToServerMicSensitivity
  },
  motionSensitivity: {
    serverToPlatform: serverToPlatformMotionSensitivity,
    platformToServer: platformToServerMotionSensitivity
  },
  videoQuality: { serverToPlatform: serverToPlatformVideoQuality, platformToServer: platformToServerVideoQuality }
};

export function getPlatformCameraSettings(settings: ServerCameraSettings) {
  const platformSettings: CameraSettings = {};

  Object.entries(settings).forEach(([name, value]) => {
    const property = name as keyof ServerCameraSettings;

    if (property in transformers) {
      const platformValue = transformers[property as TransformerKey].serverToPlatform[value as TransformerServerValues];
      platformSettings[property] = platformValue as any;
    } else platformSettings[property] = value as any;
  });

  return platformSettings;
}

export function platformToServerCameraSettings(settings: Partial<CameraSettings>) {
  const serverSettings: ServerCameraSettings = {};

  Object.entries(settings).forEach(([name, value]) => {
    const property = name as keyof CameraSettings;

    if (property in transformers) {
      const platformValue =
        transformers[property as TransformerKey].platformToServer[value as TransformerPlatformValues];
      serverSettings[property] = platformValue as any;
    } else serverSettings[property] = value as any;
  });

  return serverSettings;
}

export function getKnownCameraControls(cameraControls: CameraControls): CameraControls {
  const primaryControls = cameraControls.primaryControls;
  const secondaryControls = cameraControls.secondaryControls;

  return {
    primaryControls,
    secondaryControls
  };
}
