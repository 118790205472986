import { Box, Heading } from "@chakra-ui/react";

export default function LoadingOverlay({ children, deviceName }: { children: any; deviceName: string }) {
  return (
    <Box width="100%" h="100%" pos="absolute" borderRadius="1.5rem" bg="#000000" zIndex={2}>
      <Heading as="h5" pt="2rem" color="white" textAlign="center">
        {deviceName}
      </Heading>
      {children}
    </Box>
  );
}
