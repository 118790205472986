import Confirmation from "./Confirmation";
import Verification from "./Verification";

const steps = [
  { id: "confirmation", Component: Confirmation },
  { id: "verification", Component: Verification },
] as {
  id: string;
  Component: (props: FlowProviding & Keyable) => JSX.Element;
}[];

export default steps;
