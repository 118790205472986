import EventEmitter from "@/lib/EventEmitter";

const commEmitter = new EventEmitter();
const rtcEmitter = new EventEmitter();
const rpcEmitter = new EventEmitter();
const peerEmitter = new EventEmitter();
const dataSyncEmitter = new EventEmitter();

export { commEmitter, rtcEmitter, rpcEmitter, peerEmitter, dataSyncEmitter };
export default commEmitter;
