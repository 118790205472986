import protoRoot from "../proto-root";

const name = "stopCameraHistoryReplayEventsRPCRequest";

function create() {
  const StopCameraHistoryReplayEventsRPCRequest = protoRoot.lookupType(
    "comm.StopCameraHistoryReplayEventsRPCRequest"
  );

  return { payload: StopCameraHistoryReplayEventsRPCRequest.create(), name };
}

const stopCameraHistoryReplayEventsRPCRequest = {
  create,
  name,
};

export default stopCameraHistoryReplayEventsRPCRequest;
