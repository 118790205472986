import { useEffect, useRef } from "react";
import { Box, Flex, Heading } from "@chakra-ui/react";
import { useAuth } from "@/store/auth";
import Lottie from "@/components/Lottie";
import AnimatedDot from "@/components/AnimatedDot";
import NoConnection from "@/modules/app/components/NoConnection";
import { useIsConnected } from "@/hooks";
import { textOverflowDots } from "@/theme/utils/style";
import { useStation } from "camera/store/station";
import { useCamera } from "camera/store/camera";
import NoiseDetector from "camera/modules/noise/NoiseDetector";
import MotionDetector from "camera/modules/motion/MotionDetector";
import { getIsPaired } from "camera/store/station/selectors";
import motion from "assets/animations/motion.json?url";
import noise from "assets/animations/noise.json?url";

export default function StatusBar() {
  const noiseDisplay = useRef<HTMLDivElement>(null);
  const motionDisplay = useRef<HTMLDivElement>(null);
  const deviceName = useAuth((state) => state.device.name);
  const stream = useCamera((state) => state.stream);
  const { isConnected } = useIsConnected();
  const isPaired = useStation(getIsPaired);

  useEffect(() => {
    if (!isPaired || !stream) return;
    const { style: noiseStyle } = noiseDisplay.current!;
    const { style: motionStyle } = motionDisplay.current!;

    const unsubscribeNoise = NoiseDetector.on("change", (hasNoise: boolean) => {
      if (hasNoise) noiseStyle.visibility = "visible";
      else noiseStyle.visibility = "hidden";
    });

    const unsubscribeMotion = MotionDetector.on("change", (hasMotion: boolean) => {
      if (hasMotion) motionStyle.visibility = "visible";
      else motionStyle.visibility = "hidden";
    });

    return () => {
      unsubscribeNoise();
      unsubscribeMotion();
    };
  }, [isPaired, stream]);

  if (!stream) return null;

  return (
    <Flex
      position="absolute"
      pt="32"
      px="24"
      left="0px"
      right="0px"
      align="center"
      justify={isPaired ? "space-between" : "center"}
      zIndex={1}
      background="linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%)"
      borderTopRadius="24px"
      wrap="wrap"
    >
      {isPaired && (
        <Box ref={motionDisplay} visibility="hidden" w="24">
          <Lottie src={motion} />
        </Box>
      )}
      <Flex align="center" columnGap="12px" color="white" maxW="calc(100% - 76px)">
        {isPaired && isConnected && <AnimatedDot />}
        <Heading as="h5" {...textOverflowDots}>
          {deviceName}
        </Heading>
      </Flex>
      {isPaired && (
        <Box ref={noiseDisplay} visibility="hidden" w="24">
          <Lottie src={noise} />
        </Box>
      )}
      <Flex basis="100%" mt="16" justify="center">
        <NoConnection />
      </Flex>
    </Flex>
  );
}
