import React from "react";
import ErrorScreen from "@/modules/app/scenes/ErrorScreen";
import CrashReporter from "@/modules/app/CrashReporter";

type State = {
  error: Error | null;
  errorInfo: React.ErrorInfo | null;
  errorTrace: string[] | null | undefined;
};
type Props = {
  children: any;
};

const isDev = import.meta.env.DEV;
export default class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = { error: null, errorInfo: null, errorTrace: null };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    const errorTrace = error?.stack?.split("at ");
    errorTrace?.shift();

    CrashReporter.sendException(error, { key: "errorInfo", extra: errorInfo });
    this.setState({ error, errorInfo, errorTrace });
  }

  render() {
    if (this.state.error && !isDev) {
      return <ErrorScreen />;
    }
    if (this.state.error) {
      return (
        <>
          <h2 style={{ marginBottom: "16px", color: "red" }}>
            {this.state.error?.name}
          </h2>
          <h3> {this.state.error?.message}</h3>
          <h4 style={{ marginTop: "32px", marginBottom: "16px" }}>
            Call Stack
          </h4>
          {this.state.errorTrace?.map((trace) => {
            const [component, longPath] = trace.split("(");
            const path = (longPath || trace)
              .replace(`(${window.location.origin}/`, "")
              .replace(`)`, "");
            return (
              <div key={component} style={{ marginBottom: "12px" }}>
                <h5 style={{ color: "burlywood", fontWeight: "bolder" }}>
                  {component}
                </h5>
                <p style={{ marginLeft: "8px" }}>{path}</p>
              </div>
            );
          })}
        </>
      );
    }
    return this.props.children;
  }
}
