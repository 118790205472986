import protoRoot from "../proto-root";

const name = "startCameraHistoryReplayEventsRPCRequest";

function create() {
  const StartCameraHistoryReplayEventsRPCRequest = protoRoot.lookupType(
    "comm.StartCameraHistoryReplayEventsRPCRequest"
  );

  return { payload: StartCameraHistoryReplayEventsRPCRequest.create(), name };
}

const startCameraHistoryReplayEventsRPCRequest = {
  create,
  name,
};

export default startCameraHistoryReplayEventsRPCRequest;
