import { create } from "zustand";
import { immer } from "zustand/middleware/immer";

const getConfigDefault = (key: FilterConfigKey): FilterConfig => ({
  cameras: {},
  types: {},
  date: {
    start: null,
    end: null
  },
  key
});

const initialState: EventFilterState = {
  activeConfigKey: "fromDashboard",
  filterConfigs: {
    fromCamera: getConfigDefault("fromCamera"),
    fromDashboard: getConfigDefault("fromDashboard")
  }
};

const useEventFilters = create<EventFilterStore>()(
  immer((set, get) => ({
    ...initialState,
    setActiveConfig(activeConfigKey) {
      set({ activeConfigKey });
    },

    setCameraFilter(cameras) {
      const activeConfigKey = get().activeConfigKey;
      set(({ filterConfigs }) => {
        filterConfigs[activeConfigKey].cameras = cameras;
      });
    },

    setTypeFilter(types) {
      const activeConfigKey = get().activeConfigKey;
      set(({ filterConfigs }) => {
        filterConfigs[activeConfigKey].types = types;
      });
    },

    setDateFilter(date) {
      const activeConfigKey = get().activeConfigKey;
      set(({ filterConfigs }) => {
        filterConfigs[activeConfigKey].date = date;
      });
    },

    resetConfig(key, preserveCurrentCameraFilter) {
      const currentCameraFilters = get().filterConfigs[key];
      set(({ filterConfigs }) => {
        filterConfigs[key] = preserveCurrentCameraFilter
          ? {
              ...getConfigDefault(key),
              cameras: currentCameraFilters.cameras
            }
          : getConfigDefault(key);
      });
    },

    addCameraFilter(objectId) {
      const activeConfigKey = get().activeConfigKey;
      set(({ filterConfigs }) => {
        filterConfigs[activeConfigKey].cameras[objectId] = objectId;
      });
    },
    removeCameraFilter(objectId: string) {
      log.events("removing camera from filterConfigs objectId:", objectId);
      set(({ filterConfigs }) => {
        delete filterConfigs["fromCamera"].cameras[objectId];
        delete filterConfigs["fromDashboard"].cameras[objectId];
      });
    }
  }))
);

const eventFilterStore = useEventFilters.getState;

export { useEventFilters, eventFilterStore };
