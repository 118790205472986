import protoRoot from "../proto-root";

const name = "changeCameraRpcRequest";

function create({ cameraId }: { cameraId: string }) {
  const ChangeCameraRpcRequest = protoRoot.lookupType(
    "comm.ChangeCameraRpcRequest"
  );

  const request = ChangeCameraRpcRequest.create({ cameraId });

  return { payload: request, name };
}

const changeCameraRpcRequest = { create, name };

export default changeCameraRpcRequest;
