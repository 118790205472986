import protoRoot from "../proto-root";

const name = "getCameraHistoryStreamSegmentFileRPCRequest";

function create({
  fileName,
  segmentType,
}: GetCameraHistoryStreamSegmentFileRequest) {
  const HlsSegmentType = protoRoot.lookupEnum("comm.HlsSegmentType");
  const GetCameraHistoryStreamSegmentFileRPCRequest = protoRoot.lookupType(
    "comm.GetCameraHistoryStreamSegmentFileRPCRequest"
  );

  const request = GetCameraHistoryStreamSegmentFileRPCRequest.create({
    fileName,
    segmentType: HlsSegmentType.values[segmentType],
  });
  return { payload: request, name };
}

const getCameraHistoryStreamSegmentFileRPCRequest = { create, name };

export default getCameraHistoryStreamSegmentFileRPCRequest;
