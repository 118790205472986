import { dataSyncEmitter } from "@/modules/events/emitter";
import { useEffect, useState } from "react";
import { i18n } from "./i18n";

export const useI18nState = <I, T>(initialState: I, stateOnLoad: T) => {
  const [state, setState] = useState<I | T>(i18n.isInitialized ? stateOnLoad : initialState);

  useEffect(() => {
    let unsub: null | Cb = null;
    if (!i18n.isInitialized) {
      unsub = dataSyncEmitter.on("i18n-loaded", () => setState(stateOnLoad));
    }
    return () => {
      unsub?.();
    };
  }, [stateOnLoad]);

  return state;
};

export const useIsI18nLoaded = () => useI18nState(false, true);
