import protoRoot from "../proto-root";

function create({ start, end }: { start: number; end: number }) {
  const TimeRange = protoRoot.lookupType("comm.TimeRange");
  return TimeRange.create({
    start,
    end,
  });
}

const timeRange = { create };

export default timeRange;
