import { BoxProps, chakra } from "@chakra-ui/react";
import { VideoHTMLAttributes } from "react";

const EnhancedVideo = chakra("video");

export default function AppVideo({
  name,
  options,
}: {
  name: string;
  options?: {
    videoProps?: VideoHTMLAttributes<HTMLVideoElement> & BoxProps;
  };
}) {
  const url = new URL(`../../assets/videos/${name}`, import.meta.url).href;
  return <EnhancedVideo src={url} autoPlay loop {...options?.videoProps} />;
}
