import { useMemo } from "react";
import { Box } from "@chakra-ui/react";
import { t } from "@/utils";
import { NoiseSensKeysArr, NoiseStringKeys } from "@/enums/noise";
import { usePremiumScreen } from "@/modules/app/PremiumScreen";
import NavigationBar from "@/components/NavigationBar";
import { OptionsMenuProps } from "@/components/Menu";
import ListField from "@/components/ListField";
import ConfigureIcon from "assets/icons/configure.svg?react";

export default function NoiseDetection({
  previous,
  NoiseIndicator,
  cameraSettings,
  updateSettings,
  Progress
}: CSTypes.CameraSettingsStepProps) {
  const { settings } = cameraSettings;
  const { micSensitivity, noiseDetectionEnabled } = settings;
  const { displayBannerForFreeUser } = usePremiumScreen();

  const noiseSensitivityItems: OptionsMenuProps["items"] = useMemo(() => {
    return NoiseSensKeysArr.map((value) => ({
      item: t(NoiseStringKeys[value]),
      value
    }));
  }, []);

  const handleChange = () => {
    if (noiseDetectionEnabled)
      updateSettings({
        settings: {
          noiseDetectionEnabled: false
        }
      });
    else {
      displayBannerForFreeUser("noise").elseRun(() =>
        updateSettings({
          settings: {
            noiseDetectionEnabled: !noiseDetectionEnabled
          }
        })
      );
    }
  };

  return (
    <Box>
      {Progress && <Progress />}
      <NavigationBar title={t("noise.detection")} onBack={previous} props={{ mb: "8" }} />
      <ListField
        iconFill="primary"
        props={{
          mb: "48"
        }}
        label={t("noise.detection")}
        switch={{
          isChecked: noiseDetectionEnabled,
          onChange: handleChange
        }}
      />
      <ListField
        icon={ConfigureIcon}
        iconFill="primary"
        label={t("sensitivity")}
        additionalText={t("noiseSensitivity.description")}
        disabled={!noiseDetectionEnabled}
        menuOptions={{
          items: noiseSensitivityItems,
          label: t(NoiseStringKeys[micSensitivity]),
          menuOptionGroupProps: {
            value: micSensitivity,
            onChange: (sens) =>
              updateSettings({
                settings: {
                  micSensitivity: sens as MicSensitivity
                }
              })
          }
        }}
      >
        {NoiseIndicator && <NoiseIndicator />}
      </ListField>
    </Box>
  );
}
