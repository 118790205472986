import { ImageProps, useColorMode } from "@chakra-ui/react";
import { Image as ChakraImage } from "@chakra-ui/react";

type Props = {
  name: string;
  options?: {
    path?: "images" | "icons" | "animations";
    hasDarkMode?: boolean;
    imgProps?: ImageProps;
    alt?: string;
  };
};

export default function AppImage({ name, options = {} }: Props) {
  const { colorMode } = useColorMode();
  const { path = "images", hasDarkMode = true, imgProps, alt = name } = options;

  const colorModePrefix = hasDarkMode
    ? colorMode === "dark"
      ? "dark_"
      : "light_"
    : "";

  const src = new URL(
    `../../assets/${path}/${colorModePrefix}${name}`,
    import.meta.url
  ).href;
  return <ChakraImage src={src} alt={alt} {...imgProps} />;
}
