import { chakra, SystemStyleObject, ThemeTypings } from "@chakra-ui/react";
import { FunctionComponent } from "react";

export default function Icon({
  icon,
  fill,
  size,
  style,
}: {
  icon: FunctionComponent;
  fill?: ThemeTypings["colors"];
  size?: string;
  style?: SystemStyleObject;
}) {
  const EnhancedIcon = chakra(icon);

  return (
    <EnhancedIcon
      sx={{
        path: {
          fill
        },
        w: size,
        h: size,
        minW: size,
        minH: size,
        ...style
      }}
    />
  );
}
