import type { Debugger } from "debug";
import createDebugger from "debug";
import environment from "./environment";

const debuggers = [
  "info",
  "warn",
  "err",
  "auth",
  "app",
  "xmpp",
  "protobuf",
  "events",
  "emitter",
  "devices",
  "synchronizer",
  "announcer",
  "messenger",
  "installation",
  "pairing",
  "media",
  "implementation",
  "notifier",
  "battery",
  "rtc",
  "rpc",
  "monitoring",
  "peers",
  "motion",
  "db",
  "recorder",
  "timeline",
  "upload"
] as const;

type Extensions = "media:preview" | "xmpp:*" | "recorder:*";

const extensions = [
  "xmppPing",
  "xmppRoster",
  "mediaPreview",
  "recorderPlaylist",
  "recorderTrancsoder",
  "recorderDbcontroller",
  "recorderTimeranges"
] as const;
type IgnoreList = ((typeof debuggers)[number] | Extensions)[];
const ignore: IgnoreList = [
  // "info",
  // "warn",
  // "err",
  "auth",
  // "app",
  "xmpp",
  "protobuf",
  // "events",
  "emitter",
  "devices",
  // "synchronizer",
  "announcer",
  "messenger",
  "installation",
  "pairing",
  "media",
  // "implementation",
  "notifier",
  "battery",
  // "rtc",
  // "rpc",
  // "monitoring",
  "peers",
  "motion",
  // "db",
  // "recorder",
  // "timeline",
  // "upload",
  // "recorder:*",
  "xmpp:*",
  "media:preview"
];
type DebuggerNames = (typeof debuggers)[number] | (typeof extensions)[number];
type ReducerInitialValue = { [key in DebuggerNames]: Debugger };

function setDebuggers(enabled: boolean, includeAll?: boolean) {
  const filter = includeAll ? () => true : (i: IgnoreList[number]) => !ignore.includes(i);

  window.localStorage.debug = enabled
    ? ([...debuggers, "xmpp:*", "recorder:*", "media:preview"] as const).filter(filter).reduce((acc, name) => {
        acc += `${name},`;
        return acc;
      }, "")
    : "err";
}
setDebuggers(environment.isDev);
// @ts-ignore
window.setDebuggers = (enabled: boolean) => {
  if (enabled === undefined) {
    // eslint-disable-next-line no-console
    console.log("Function requires a parametr of either 'true' of 'false'");
    return;
  }
  setDebuggers(enabled, true);
  window.location.reload();
};

const log = debuggers.reduce((acc, name) => {
  acc[name] = createDebugger(name);
  return acc;
}, {} as ReducerInitialValue);

// eslint-disable-next-line no-restricted-syntax
log.implementation.color = "#F0990A";
log.err.color = "#FF1300";
log.warn.color = "#ECC800";
log.events.color = "#AE61FF";
log.emitter.color = "#214359";
log.pairing.color = "#644FFF";
log.xmpp.color = "#00AB10";
log.app.color = "#23F3E0";
log.messenger.color = "#2CADEC";
log.info.color = "#D6D6D6";
log.media.color = "#EE6082";
log.synchronizer.color = "#64BFEB";
log.notifier.color = "#5CFF5C";
log.battery.color = "#F18A15";
log.rtc.color = "#19E7EA";
log.rpc.color = "#DF8ACC";
log.peers.color = "#9BDF8A";
log.motion.color = "#8C57D3";
log.recorder.color = "#FF7657";
log.db.color = "#5589C0";
log.timeline.color = "#D1007E";
log.upload.color = "#C1F7BA";

log.xmppPing = log.xmpp.extend("ping");
log.xmppPing.color = "#00870D";
log.xmppRoster = log.xmpp.extend("roster");
log.xmppRoster.color = "#00870D";
log.mediaPreview = log.media.extend("preview");
log.mediaPreview.color = "#D35774";

log.recorderPlaylist = log.recorder.extend("playlist");
log.recorderTrancsoder = log.recorder.extend("trancsoder");
log.recorderDbcontroller = log.recorder.extend("dbcontroller");
log.recorderTimeranges = log.recorder.extend("timeranges");

log.app("environment", environment);

// @ts-ignore
window.help = () => {
  // eslint-disable-next-line no-console
  console.log(`
  Available commands:
    setDebuggers(true|false)
    toggleColorMode()
    setPremiumState("default"|"forceOn"|"forceOff")
  `);
};

export default log;
export type Log = typeof log;
