import { CameraStore, useCamera, cameraStore } from "camera/store/camera";
import { rpcEmitter } from "@/modules/events/emitter";
import { RPCRequests, RPCResponses } from "@/modules/communication";
import { isPairedDevice } from "@/utils";
import streamQualityUpdater from "camera/modules/station/streamQualityUpdater";
import { useStation } from "camera/store/station";
import { getIsPaired } from "camera/store/station/selectors";
import Recorder from "./Recorder";
import { isGapFile } from "./extensions";
import EventEmitter from "@/lib/EventEmitter";

export function createRecorder() {
  const recorder = new Recorder();
  let isAlreadyCreating = false;
  let isPaired = getIsPaired(useStation.getState());

  const emitter = new EventEmitter();

  const onCameraStoreUpdate = (store: CameraStore) => {
    if (!isPaired) return;
    if (store.stream && recorder.status !== "recording") init();
  };

  const onStationStoreUpdate = (store: StationStore) => {
    isPaired = getIsPaired(store);
    if (isAlreadyCreating) return;

    if (isPaired && recorder.status === "idle") init();
    else if (!isPaired && recorder.status === "recording") stopAndClear();
  };

  const onFileRequest = async (
    request: GetCameraHistoryStreamSegmentFileRequest,
    from: string,
    sendResponse: RpcSendResponse
  ) => {
    if (!isPairedDevice(from)) return;
    log.recorder("Received file request from", request.fileName, from);

    const { fileName, segmentType } = request;
    const payload: GetCameraHistoryStreamSegmentFileResponse = {
      result: "SUCCESS"
    };
    if (isGapFile(fileName)) {
      const file = await (segmentType === "DATA" ? recorder.playlist.getGapSegment : recorder.playlist.getGapInit)(
        fileName
      );
      if (!file) payload.result = "NOT_AVAILABLE";
      else {
        payload.data = file.data;
        payload.date = new Date(file.createdAt).getTime();
      }
    } else {
      const read = recorder.db.getRead<HlsDataItem>("hls-data", "filename");
      const file = await read(fileName);
      if (!file) payload.result = "NOT_AVAILABLE";
      else {
        if (segmentType === "INIT") payload.imageRotation = "ROTATION_0";
        payload.data = file.data;
        payload.date = new Date(file.createdAt).getTime();
      }
    }

    const response = RPCResponses.GetCameraHistoryStreamSegmentFile.create(payload);
    log.rpc("About to send response", response, fileName);
    sendResponse(response);
  };

  const onPlaylistRequest = async (
    request: getCameraHistoryStreamPlaylistFileRequest,
    from: string,
    sendResponse: RpcSendResponse
  ) => {
    if (!isPairedDevice(from)) return;
    log.recorder("Received playlist request from", from);

    const playlistData = await (request.deltaUpdate
      ? recorder.playlist.generateDeltaPlaylist
      : recorder.playlist.generatePlaylist)(from);
    if (!playlistData) {
      log.err("Wasnt able to create playlist data for peer", from);
      return;
    }
    const { data, duration, startDate, videoRanges } = playlistData;

    const response = RPCResponses.GetCameraHistoryStreamPlaylistFile.create({
      result: "SUCCESS",
      data,
      duration,
      startDate: new Date(startDate).getTime(),
      videoRanges
    });
    log.rpc("About to send response", response);
    sendResponse(response);
  };

  const startListening = () => {
    rpcEmitter.on(RPCRequests.GetCameraHistoryStreamSegmentFile.name, onFileRequest);
    rpcEmitter.on(RPCRequests.GetCameraHistoryStreamPlaylistFile.name, onPlaylistRequest);
  };

  const init = async () => {
    const stream = cameraStore().stream;
    if (!stream || isAlreadyCreating) return;

    isAlreadyCreating = true;
    log.recorder("About to create Recorder");

    await recorder.init(stream);
    log.recorder("Finished initialization");
    await recorder.start();
    log.recorder("Start finished");

    isAlreadyCreating = false;
    emitter.emit("recorder-init");
    streamQualityUpdater.on("stream-quality-change-start", recreate);
  };

  const stopAndClear = async () => {
    try {
      log.recorder("stopAndClear called");
      recorder.stop();
      await recorder.db.clearAll("hls-meta");
      log.recorder("Cleared all meta files");
      await recorder.db.clearAll("hls-data");
      log.recorder("Cleared all data files");
    } catch (err) {
      log.err("'stopAndClear' failed:", err);
    }
  };

  const resume = async () => {
    await recorder.start();
  };

  const recreate = async () => {
    const onStreamUpdate = async () => {
      streamQualityUpdater.off("stream-quality-change-end", onStreamUpdate);
      recorder.createNewMediaRecorder();
      await resume();
      log.recorder("Recorder recreated");
    };

    log.recorder("Recreating recorder...");
    recorder.stop();
    if (streamQualityUpdater.isCurrent) onStreamUpdate();
    else streamQualityUpdater.on("stream-quality-change-end", onStreamUpdate);
  };

  startListening();
  useCamera.subscribe(onCameraStoreUpdate);
  useStation.subscribe(onStationStoreUpdate);
  log.recorder("Creating Recorder");

  return {
    onRecorderInit: (callback: Cb<Recorder>) => {
      return emitter.on("recorder-init", () => callback(recorder!));
    },
    recorder
  };
}
