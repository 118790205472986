import "strophe.js";
import "strophejs-plugin-roster";
import { useApp } from "@/store/app";
import { commEmitter } from "../events/emitter";
import environment from "@/modules/environment";
import { decodeBase64, decodeEnvelope, getEnvelopeContent } from "../communication";
import XmppUtils, { DISCONNECT_TIMEOUT, getChildText, getFullJid } from "./XmppUtils";
import PresenceKeeper from "../app/Presence";
import PingService from "./PingService";
import Roster from "./Roster";
import { isEmptyObject } from "@/utils";

export default class Xmpp extends XmppUtils {
  private static instance: Xmpp;
  static getInstance() {
    if (!Xmpp.instance) {
      Xmpp.instance = new Xmpp();
      log.xmpp("Xmpp instance created");
    }
    return Xmpp.instance;
  }
  private credentials!: XmppCredentials;
  private connection!: Strophe.Connection;
  presence: Presence = "not_chosen_yet";
  payload: Keyable = {};
  pingService = new PingService(() => this.connection);
  private roster = new Roster(() => this.connection);
  private messageHandler: StropheHandler = null;
  private iqHandler: StropheHandler = null;
  private presenceHandler: StropheHandler = null;
  private rosterHandler: StropheHandler = null;
  private get appStatus() {
    return useApp.getState().status;
  }

  setCredentials = (credentials: XmppCredentials) => {
    log.xmpp("Setting xmpp credentials", credentials);
    this.credentials = {
      xmppLogin: getFullJid(credentials.xmppLogin),
      xmppPassword: credentials.xmppPassword
    };
  };

  private onStatus = (status: Strophe.Status) => {
    this.status = status;

    if (status === Strophe.Status.CONNECTING) {
      log.xmpp("Strophe is connecting.");
    } else if (status === Strophe.Status.CONNFAIL) {
      log.xmpp("Strophe failed to connect.");
      if (this.connectResolver) {
        this.connectResolver.reject("Connection resolver failed");
        this.connectResolver = null;
      }
    } else if (status === Strophe.Status.DISCONNECTING) {
      log.xmpp("Strophe is disconnecting.");
    } else if (status === Strophe.Status.DISCONNECTED) {
      log.xmpp("Strophe is disconnected.");
      if (this.disconnectResolver) {
        this.disconnectResolver.resolve(1);
        this.disconnectResolver = null;
      }
      if (
        this.appStatus !== "XMPP_DISCONNECTED" &&
        this.appStatus !== "NO_NETWORK" &&
        this.appStatus !== "SIGNING_OUT"
      ) {
        useApp.getState().setStatus("XMPP_DISCONNECTED");
      }
    } else if (status === Strophe.Status.CONNECTED) {
      this.roster.subscribe(this.credentials.xmppLogin);
      log.xmpp("Strophe is connected.");
      if (this.connectResolver) {
        this.connectResolver.resolve(1);
        this.connectResolver = null;
      }
    }
  };

  private onMessage = (message: Element) => {
    if (this.isSelf(message)) return true;
    if (getChildText(message, "subject") !== "pb") return true;
    log.xmpp("received message", message);
    const base64EncodedEnvelope = getChildText(message, "body");

    const xmppLogin = message.getAttribute("from");
    if (!base64EncodedEnvelope) {
      log.xmpp("No envelope in message", message);
      return true;
    }
    const bufferEnvelope = decodeBase64(base64EncodedEnvelope);
    const decodedEnvelope = decodeEnvelope(bufferEnvelope);
    const { type, payload } = getEnvelopeContent(decodedEnvelope);

    if (message.getAttribute("type") === "error") {
      log.xmpp("Received error message, ignoring", message);
      return true;
    }

    const jid = Strophe.getNodeFromJid(xmppLogin as string);
    commEmitter.emit(type, payload, jid);
    return true;
  };

  private onIq = (iq: Element) => {
    if (this.isSelf(iq)) return true;
    if (iq.querySelector("ping")) return true;
    log.xmpp("received iq", iq);
    return true;
  };

  private onPresence = (presence: Element) => {
    if (this.isSelf(presence)) return true;
    const xmppLogin = presence.getAttribute("from");
    const type = presence.getAttribute("type");
    let status = getChildText(presence, "status") as Presence | undefined;
    const payload = getChildText(presence, "payload");

    if (type === "unavailable" || status == null) {
      status = "unavailable";
    }
    log.xmpp("received presence", presence);
    const presenceUpdate = {
      jid: Strophe.getNodeFromJid(xmppLogin as string),
      status,
      payload: payload ? JSON.parse(payload) : null
    };
    log.xmpp(presenceUpdate);

    PresenceKeeper.update({
      jid: presenceUpdate.jid,
      status: presenceUpdate.status
    });

    if (presenceUpdate.payload && !isEmptyObject(presenceUpdate.payload)) {
      commEmitter.emit("presence-payload", presenceUpdate.payload, presenceUpdate.jid);
    }
    return true;
  };

  connect = () => {
    const promise = this.createConnectPromise();
    this.connection = new Strophe.Connection(environment.xmppServer, { mechanisms: [Strophe.SASLPlain] });
    this.connection.connect(
      this.credentials.xmppLogin,
      this.credentials.xmppPassword,
      this.onStatus,
      undefined,
      undefined,
      undefined,
      undefined,
      DISCONNECT_TIMEOUT
    );

    this.messageHandler = this.connection.addHandler(this.onMessage, "", "message");
    this.iqHandler = this.connection.addHandler(this.onIq, "", "iq");
    this.presenceHandler = this.connection.addHandler(this.onPresence, "", "presence");
    this.rosterHandler = this.connection.addHandler(this.roster.onRosterUpdate, Strophe.NS.ROSTER, "iq", "set");

    return promise;
  };

  sendPresence = (presence = this.presence, payload = this.payload) => {
    if (!this.isReady()) return;
    this.presence = presence;
    this.payload = payload;

    const { setPresence, presence: currentPresence } = useApp.getState();
    if (presence !== currentPresence) setPresence(presence);

    const presenceStanza = $pres()
      .c("status", {}, presence)
      .c("payload", { xmlns: "ttmonitor" }, JSON.stringify(payload))
      .c("priority", {}, "99");

    this.connection.sendPresence(
      presenceStanza,
      (e) => log.xmpp("Presence sent", e),
      (e) => log.xmpp("Error sending presence", e)
    );
  };

  sendEnvelope = (envelope: string, jid: string) => {
    if (!this.isReady()) return;
    const message = $msg({ to: getFullJid(jid), type: "chat" })
      .c("subject", {}, "pb")
      .c("body", {}, envelope);
    log.xmpp("Sending envelope", message);
    this.connection.send(message);
  };

  sendIq = (iq: Strophe.Builder) => {
    if (!this.isReady()) return;
    this.connection.sendIQ(iq);
  };

  disconnect = () => {
    try {
      const promise = this.createDisconnectPromise();
      this.pingService.stop();
      this.roster.unsubscribe(this.credentials.xmppLogin);
      if (this.messageHandler) this.connection.deleteHandler(this.messageHandler);
      if (this.iqHandler) this.connection.deleteHandler(this.iqHandler);
      if (this.presenceHandler) this.connection.deleteHandler(this.presenceHandler);
      if (this.rosterHandler) this.connection.deleteHandler(this.rosterHandler);
      this.connection.disconnect("Disconnected called");

      this.messageHandler = null;
      this.iqHandler = null;
      this.presenceHandler = null;
      this.rosterHandler = null;

      return promise;
    } catch (err) {
      log.xmpp("Error disconnecting from xmpp server", err);
    }
  };

  reconnect = async () => {
    await this.disconnect();
    log.xmpp("Sucessfully disconnected while trying to reconnect");
    await this.connect();
    log.xmpp("Sucessfully connected while trying to reconnect");
    await this.getRoster();
    this.pingService.start();
    this.sendPresence(this.presence, this.payload);
  };

  getRoster = async (): Promise<RosterDevice[]> => {
    return new Promise((resolve, reject) => {
      if (!this.isReady()) {
        reject("'getRoster' failed, connection is not ready");
        return;
      }
      this.roster.getRoster(resolve);
    });
  };

  private isSelf = (stanza: Element): boolean => {
    const from = stanza.getAttribute("from");
    if (!from) return true;
    if (from === this.credentials.xmppLogin) return true;
    return false;
  };
}
