import { useInstallation } from "@/store/installation";
import { getLocale } from "@/utils";
import { cloudCode } from "../cloud";
import { fetchCurrentSession } from "../cloud/auth";
import environment from "../environment";

export async function getInstallationId() {
  let installationId = useInstallation.getState().installationId;

  if (!installationId) {
    log.installation("No installationId found, fetching current session");
    const parseSession = await fetchCurrentSession();
    log.installation("Current session", parseSession);
    installationId = parseSession.installationId;
    if (!installationId) throw Error("No installationId found");
    useInstallation.getState().setInstallationId(installationId);
  }

  return installationId;
}

export async function updateInstallation(newInstallationId?: string) {
  const installationId = newInstallationId || (await getInstallationId());
  const installationData = {
    localeIdentifier: `${getLocale().toUpperCase()}_`,
    deviceType: "web",
    appVersion: __APP_VERSION__,
    systemVersion: navigator.userAgent,
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    appIdentifier: `com.tappytaps.desktop.${environment.appName}`,
    installationId
  };
  log.installation("Updating installation data ", installationData);
  const result = await cloudCode.run("_Installation", installationData, {
    path: "classes"
  });
  log.installation("InstallationData saved: ", result);
}
