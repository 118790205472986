import { defineStyle } from "@chakra-ui/styled-system";
import { SystemStyleFunction } from "@chakra-ui/theme-tools";

const baseStyleLabel = defineStyle({
  lineHeight: "1",
  fontSize: "0.25em",
  fontWeight: "bold",
  color: "white"
});

const baseStyleTrack: SystemStyleFunction = (props) => {
  return {
    bg: "outline-variant",
    h: "8",
    borderRadius: "4px"
  };
};

const baseStyleFilledTrack: SystemStyleFunction = (props) => {
  return {
    bg: "primary",
    transitionProperty: "common",
    transitionDuration: "slow"
  };
};

const baseStyle: SystemStyleFunction = (props) => ({
  label: baseStyleLabel,
  filledTrack: baseStyleFilledTrack(props),
  track: baseStyleTrack(props)
});

export default {
  baseStyle
};
