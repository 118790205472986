import CrashReporter from "@/modules/app/CrashReporter";

type Config = {
  readonly dbName: string;
  readonly version: number;
  readonly stores: {
    readonly [name: string]: {
      readonly name: string;
      readonly options?: Readonly<IDBObjectStoreParameters>;
      readonly indexes: Readonly<string[]>;
    };
  };
};

export class DBFactory<T extends Config> {
  private dbName: string;
  private version: number;
  private stores: Config["stores"];

  constructor({ dbName, version, stores }: Config) {
    this.dbName = dbName;
    this.version = version;
    this.stores = stores;
  }
  private db: IDBDatabase | null = null;

  private log = (...args: any[]) => {
    log.db(`${this.dbName} =>`, ...args);
  };

  init = async () => {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(this.dbName, this.version);
      this.log(`Created request for ${this.dbName}`);
      request.onsuccess = (e) => {
        this.log("onsuccess fired");
        this.db = (e.target as IDBOpenDBRequest).result;
        this.db.onversionchange = (e) => this.log("'onversionchange' fired: ", e);
        this.db.onabort = (e) => this.log("'onabort' fired: ", e);
        this.db.onclose = (e) => this.log("'onclose' fired: ", e);
        this.db.onerror = (e) => this.log("'onerror' fired: ", e);
        this.log("Database opened");
        resolve(this.db);
      };
      request.onerror = (e) => {
        const err = (e.target as IDBOpenDBRequest).error;
        this.log("Access denied, request error", err);
        log.err("Access denied, request error", err);
        reject(`Access denied for ${this.dbName}`);
      };
      request.onblocked = (e) => {
        const err = (e.target as IDBOpenDBRequest).error;
        this.log("Access denied, request was blocked", err);
        log.err("Access denied, request was blocked", err);
        reject(`Access denied for ${this.dbName}`);
      };
      request.onupgradeneeded = (e) => {
        this.log("onupgradeneeded fired");
        const db = (e.target as IDBOpenDBRequest).result;

        Object.values(this.stores).forEach((store) => {
          let objectStore: IDBObjectStore;
          if (!db.objectStoreNames.contains(store.name)) {
            this.log("Creating new objectStore", store.name);
            objectStore = db.createObjectStore(store.name, store.options);
          } else {
            this.log("Found existing objectStore", store.name);
            objectStore = request.transaction!.objectStore(store.name);
          }

          const existingIndexes = Array.from(objectStore.indexNames);

          store.indexes.forEach((index) => {
            if (!existingIndexes.includes(index)) {
              this.log("Creating new index", index);
              objectStore.createIndex(index, index, { unique: false });
            }
          });

          existingIndexes.forEach((index) => {
            if (!store.indexes.includes(index)) {
              this.log("Deleting old index", index);
              objectStore.deleteIndex(index);
            }
          });

          objectStore.transaction.oncomplete = (event) => {
            this.log(`Object store '${store.name}' created`, event);
          };
        });
      };
    });
  };

  createTransaction = (mode: IDBTransactionMode, store: keyof T["stores"]) => {
    if (!this.db) {
      CrashReporter.sendMessage("Transaction couldn't be created, 'this.db' is null");
      if (!isDev) restartApp({ useLimiter: true });
      throw Error("Transaction couldn't be created, 'this.db' is null");
    }
    const transaction = this.db.transaction(store as string, mode).objectStore(store as string);
    return transaction;
  };

  getWrite = <R>(store: keyof T["stores"]) => {
    const objectStore = this.createTransaction("readwrite", store);
    return (item: R, key?: IDBValidKey | undefined) =>
      new Promise((resolve, reject) => {
        const request = objectStore.put(item, key);
        request.onsuccess = (e) => resolve(e);
        request.onerror = (e) => {
          this.log("'write' failed ", e);
          reject(`'write' failed for ${this.dbName}`);
        };
      });
  };

  getDelete = (store: keyof T["stores"], storeIndex: T["stores"][keyof T["stores"]]["indexes"][number]) => {
    const objectStore = this.createTransaction("readwrite", store);
    return (id: string) =>
      new Promise((resolve, reject) => {
        const index = objectStore.index(storeIndex);
        const singleKeyRange = IDBKeyRange.only(id);

        const request = index.openCursor(singleKeyRange);

        request.onsuccess = (event) => {
          const cursor = (event.target as IDBRequest<IDBCursorWithValue>).result;
          if (cursor) {
            const request = cursor.delete();
            request.onsuccess = (e) => resolve(e);
            request.onerror = (e) => {
              this.log("'delete' failed ", e);
              reject(`'delete' failed in cursor for ${this.dbName}`);
            };
          }
        };

        request.onerror = (e) => {
          this.log("'delete' failed ", e);
          reject(`'delete' failed for ${this.dbName}`);
        };
      });
  };

  getRead = <R>(store: keyof T["stores"], storeIndex: T["stores"][keyof T["stores"]]["indexes"][number]) => {
    const indexObjectStore = this.createTransaction("readonly", store);
    const index = indexObjectStore.index(storeIndex);
    return (id: string) =>
      new Promise<R>((resolve, reject) => {
        const request = index.get(id);
        request.onsuccess = (e) => {
          const result = (e.target as IDBRequest<R>).result;
          resolve(result);
        };
        request.onerror = (e) => {
          this.log("'read' failed ", e);
          reject(`'read' failed for ${this.dbName}`);
        };
      });
  };

  readAll = <R>(store: keyof T["stores"]) => {
    return new Promise<R[]>((resolve, reject) => {
      const request = this.createTransaction("readonly", store).getAll();
      request.onsuccess = (e) => {
        resolve((e.target as IDBRequest<R[]>).result);
      };
      request.onerror = (e) => {
        this.log("'readALL' failed", e);
        reject(`'readALL' failed for ${this.dbName}`);
      };
    });
  };

  clearAll = (store: keyof T["stores"]) => {
    const indexObjectStore = this.createTransaction("readwrite", store);
    return new Promise((resolve, reject) => {
      const request = indexObjectStore.clear();
      request.onsuccess = (e) => resolve(e);
      request.onerror = (e) => {
        this.log("'clearAll' failed", e);
        reject(`'clearAll' failed for ${this.dbName}`);
      };
    });
  };
}
