export const PermissionErrorsByBrowser = {
  chrome: "Permission denied",
  edge: "Permission denied",
  firefox: "The request is not allowed by the user agent or the platform in the current context.",
  safari:
    "The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission."
};

export const getHasPermissionError = (error?: string) => {
  if (!error) return false;
  return Object.values(PermissionErrorsByBrowser).reduce((hasError, permissionError) => {
    if (permissionError.includes(error)) hasError = true;
    return hasError;
  }, false);
};
