import { useCallback, useEffect, useMemo, useState } from "react";
import EventEmitter from "./EventEmitter";

export default class TooltipManager {
  private static emitter = new EventEmitter();
  private static timeout: null | NodeJS.Timeout = null;

  static on = this.emitter.on;
  static off = this.emitter.off;

  static add = () => {
    if (this.timeout) {
      clearTimeout(this.timeout!);
      this.timeout = null;
    }
    this.emitter.emit("tooltip-added");
  };

  static remove = () => {
    if (this.timeout) return;
    this.timeout = setTimeout(() => {
      clearTimeout(this.timeout!);
      this.timeout = null;
      this.emitter.emit("tooltip-removed");
    }, 400);
  };
}

export const useTooltipManager = () => {
  const [tooltipDelay, setTooltipDelay] = useState(700);

  const startTooltipDelayReset = useCallback(() => {
    if (tooltipDelay === 0) TooltipManager.remove();
  }, [tooltipDelay]);

  useEffect(() => {
    const cb = [
      TooltipManager.on("tooltip-added", () => setTooltipDelay(0)),
      TooltipManager.on("tooltip-removed", () => setTooltipDelay(700))
    ];
    return () => cb.forEach((c) => c());
  }, []);

  return useMemo(
    () => ({
      addTooltip: TooltipManager.add,
      removeTooltip: startTooltipDelayReset,
      tooltipDelay
    }),
    [startTooltipDelayReset, tooltipDelay]
  );
};
