import protoRoot from "../proto-root";

const name = "takeVideoSnapshotRPCRequest";

function create() {
  const TakeVideoSnapshotRPCRequest = protoRoot.lookupType(
    "comm.TakeVideoSnapshotRPCRequest"
  );

  return { payload: TakeVideoSnapshotRPCRequest.create(), name };
}

const takeVideoSnapshotRPCRequest = { create, name };

export default takeVideoSnapshotRPCRequest;
