import { ThemeTypings } from "@chakra-ui/react";
import { memoizedGet } from "@chakra-ui/utils";

export default function getColorToken(
  theme: object,
  token: ThemeTypings["colors"]
) {
  const hex = memoizedGet(theme, "semanticTokens.colors." + token);
  return hex;
}
