import protoRoot from "../proto-root";

const name = "pingResponse";

function create() {
  const PingResponse = protoRoot.lookupType("comm.PingResponse");

  const response = PingResponse.create();
  return { payload: response, name };
}

const pingResponse = { create, name };

export default pingResponse;
