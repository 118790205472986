import { create } from "zustand";
import { peerEmitter } from "../events/emitter";

type PeerState = {
  peers: Peers;
};
type PeerStore = PeerState & {
  add(peer: Peer): void;
  update(jid: string, update: Partial<Peer>): void;
  remove(jid: string): void;
  reset(): void;
};

const initialState: PeerState = {
  peers: {}
};

const usePeers = create<PeerStore>()((set, get) => ({
  ...initialState,
  add(peer) {
    log.peers("Adding peer to store", peer);
    set((state) => ({
      peers: {
        ...state.peers,
        [peer.jid]: { ...state.peers[peer.jid], ...peer }
      }
    }));
    peerEmitter.emit("peer-added", peer);
  },
  update(jid, update) {
    if (!get().peers[jid]) {
      log.warn("No peer to update, new peer will be created without 'peer-added' emit");
    }
    log.peers("Updating peer in store", update, jid);
    set((state) => ({
      peers: {
        ...state.peers,
        [jid]: { ...state.peers[jid], ...update }
      }
    }));
    peerEmitter.emit("peer-updated", get().peers[jid]);
  },
  remove(jid) {
    log.peers("Removing peer from store", jid);
    set((state) => {
      const { [jid]: _, ...peers } = state.peers;
      return { peers };
    });
    peerEmitter.emit("peer-removed", jid);
  },
  reset() {
    log.peers("Resetting peers store");
    set(initialState);
  }
}));

const Peers = {
  add: usePeers.getState().add,
  update: usePeers.getState().update,
  remove: usePeers.getState().remove,
  reset: usePeers.getState().reset,
  get: (jid: string) => usePeers.getState().peers[jid],
  getAll: () => usePeers.getState().peers,
  getAllAsArray: () => Object.values(usePeers.getState().peers)
};

export default Peers;
