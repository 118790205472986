import createMessage from "./createMessage";
import protoRoot from "../proto-root";

const name = "motionInfoPacket";

function create() {
  const MotionInfoPacket = protoRoot.lookupType(`comm.MotionInfoPacket`);
  const motionInfoPacket = MotionInfoPacket.create({});

  return createMessage({
    [name]: motionInfoPacket,
  });
}

const motionInfoPacket = { create, name };

export default motionInfoPacket;
