import { useEffect, useRef } from "react";
import { Flex, FlexProps, Heading } from "@chakra-ui/react";
import ArrowLeft from "assets/icons/arrow_left.svg?react";
import Close from "assets/icons/close_large.svg?react";
import { t } from "@/utils";
import IconButton from "@/components/IconButton";
import { dataSyncEmitter } from "@/modules/events/emitter";

const TIMEOUT = 400;

const NavigationBar = ({
  title,
  autoFocus = true,
  props,
  children,
  onClose,
  onBack
}: {
  title?: string;
  autoFocus?: boolean;
  props?: FlexProps;
  children?: any;
  onClose?: () => void;
  onBack?: () => void;
}) => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const shouldDisableEscape = useRef(false);

  const getModalEl = () => document.querySelector(".chakra-modal__content") as HTMLDivElement;
  const handleDisableEscape = (shouldDisable: boolean) => (shouldDisableEscape.current = shouldDisable);

  useEffect(() => dataSyncEmitter.on("set-navigation-events", handleDisableEscape));

  useEffect(() => {
    dataSyncEmitter.emit("set-navigation-events-by-navigation-bar", true);
    return () => dataSyncEmitter.emit("set-navigation-events-by-navigation-bar", false);
  }, []);

  useEffect(() => {
    if (autoFocus) {
      timeoutRef.current = setTimeout(() => {
        getModalEl()?.focus();
      }, TIMEOUT);
    }
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
    };
  }, [autoFocus]);

  useEffect(() => {
    const onEsc = () => !shouldDisableEscape.current && (onBack?.() || onClose?.());
    document.addEventListener("modal-esc", onEsc);
    return () => document.removeEventListener("modal-esc", onEsc);
  }, [onBack, onClose]);

  return (
    <Flex w="100%" alignItems="center" pt="8px" pb="16px" pos="relative" ref={containerRef} {...props}>
      {onBack && (
        <IconButton
          onClick={onBack}
          icon={ArrowLeft}
          fill="on-surface"
          props={{ position: "absolute", left: "-12px" }}
          tooltip={{ label: t("back") }}
        />
      )}
      {title && (
        <Heading as="h4" ml={onBack ? "40px" : "0px"}>
          {title}
        </Heading>
      )}

      {onClose && (
        <IconButton
          onClick={onClose}
          icon={Close}
          fill="on-surface"
          props={{ pos: "absolute", right: "-12px" }}
          tooltip={{ label: t("buttons.close") }}
        />
      )}
      {children}
    </Flex>
  );
};

export default NavigationBar;
