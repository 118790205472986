import { FunctionComponent, useId } from "react";
import {
  Box,
  Flex,
  FormControl,
  FormControlProps,
  FormLabel,
  FormLabelProps,
  Switch,
  SwitchProps,
  Text
} from "@chakra-ui/react";
import Arrow from "assets/icons/arrow_down_slim.svg?react";
import Icon from "./Icon";
import { OptionsMenuProps, OptionsMenu, OptionsMenuButton } from "./Menu";

export default function ListField({
  label,
  additionalText,
  icon,
  iconFill,
  props,
  labelProps,
  disabled,
  switch: switchOptions,
  menuOptions,
  children
}: {
  label: string;
  additionalText?: string;
  icon?: FunctionComponent;
  iconFill?: string;
  props?: FormControlProps;
  labelProps?: FormLabelProps;
  disabled?: boolean;
  switch?: SwitchProps;
  menuOptions?: Omit<OptionsMenuProps, "children">;
  children?: any;
}) {
  const id = useId();
  const longestOption = menuOptions?.items.reduce((all, one) => {
    return all.length > one.value.length ? all : one.value;
  }, "");

  return (
    <FormControl isDisabled={disabled} {...props}>
      <FormLabel
        variant={menuOptions ? "static" : "interactive"}
        border="1px solid"
        borderColor="outline"
        py="12"
        pl="12"
        pr="8"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        borderRadius="16px"
        htmlFor={id}
        {...labelProps}
      >
        <Flex align="center" columnGap="12px" mr="20">
          {icon && <Icon icon={icon} fill={iconFill} />}
          <Box>
            <Text variant="title" color="on-surface">
              {label}
            </Text>
            {additionalText && (
              <Text variant="body" maxW="420px" mt="8">
                {additionalText}
              </Text>
            )}
            {children && <Box mt="12">{children}</Box>}
          </Box>
        </Flex>

        <Flex align="center">
          {switchOptions && <Switch id={id} {...switchOptions} />}
          {menuOptions && (
            <Box pos="relative">
              <OptionsMenuButton props={{ visibility: "hidden" }}>
                {longestOption}
                <Icon icon={Arrow} fill="on-surface" />
              </OptionsMenuButton>
              <Box pos="absolute" top="50%" transform="translateY(-50%)" right="0">
                <OptionsMenu {...menuOptions}>
                  <OptionsMenuButton>
                    {menuOptions.label}
                    <Icon icon={Arrow} fill="on-surface" />
                  </OptionsMenuButton>
                </OptionsMenu>
              </Box>
            </Box>
          )}
        </Flex>
      </FormLabel>
    </FormControl>
  );
}
