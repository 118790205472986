import { cloudCode } from "@/modules/cloud";
import { useAuth } from "@/store/auth";

export function confirmDevicePairing({
  pairingRequestId,
  confirmingDeviceJID,
  confirmingPairingType,
}: ConfirmDevicePairingParams) {
  log.pairing("confirming device pairing");
  return cloudCode.run("confirmDevicePairing", {
    pairingRequestId,
    confirmingDeviceJID,
    confirmingPairingType,
  });
}

export function generatePairingCode() {
  log.pairing("generating pairing code");
  const xmppLogin = useAuth.getState().device.xmppCredentials?.xmppLogin;
  const deviceName = useAuth.getState().device.name;
  return cloudCode.run("generatePairingCode", { xmppLogin, deviceName });
}
