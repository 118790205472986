import { create } from "zustand";

export const useCameraSettings = create<Controls>()((set) => ({
  isOpen: false,
  close() {
    set({ isOpen: false });
  },
  open() {
    set({ isOpen: true });
  }
}));
