import { createContext, useContext } from "react";

const AppContext = createContext({ resetStation: async () => {} });

export const useAppContext = () => useContext(AppContext);

export default function AppContextProvider({
  children,
  resetStation
}: {
  children: any;
  resetStation: () => Promise<void>;
}) {
  return <AppContext.Provider value={{ resetStation }}>{children}</AppContext.Provider>;
}
