type Resolver<T> = {
  timeout: NodeJS.Timeout | null;
  resolve: ((value: T) => void) | null;
  reject: ((err: any) => void) | null;
};
const DEFAULT_TIMEOUT = 7000;

export default async function addTimeout<T extends (...args: any) => Promise<any>>(cb: T, ms = DEFAULT_TIMEOUT) {
  const resolver: Resolver<ReturnType<T>> = { timeout: null, resolve: null, reject: null };

  const promise = new Promise<Awaited<ReturnType<T>>>((resolve, reject) => {
    const timeout = setTimeout(() => {
      reject("TIMEOUT");
    }, ms);
    resolver.resolve = resolve;
    resolver.reject = reject;
    resolver.timeout = timeout;
  });

  cb()
    .then((result) => {
      if (resolver.timeout) clearTimeout(resolver.timeout);
      resolver.resolve?.(result);
    })
    .catch((err) => resolver.reject?.(err));

  return promise;
}
