import AppStateMessage from "./AppStateMessage";
import BatteryStatus from "./BatteryStatus";
import CameraAndFlashlightState from "./CameraAndFlashlightState";
import CloudAccountSyncMessage from "./CloudAccountSyncMessage";
import CameraHistoryReplayEventUpdate from "./CameraHistoryReplayEventUpdate";
import DeviceUnpairedMessage from "./DeviceUnpairedMessage";
import MotionDetectorState from "./MotionDetectorState";
import MotionInfoPacket from "./MotionInfoPacket";
import RequestPairingMessage from "./RequestPairingMessage";
import RequestPairingResponseMessage from "./RequestPairingResponseMessage";
import SetVideoFlashlightMessage from "./SetVideoFlashlightMessage";
import StopVideoBroadcasting from "./StopVideoBroadcasting";
import WebRTCCandidate from "./WebRTCCandidate";
import WebRTCCreateOffer from "./WebRTCCreateOffer";
import WebRTCCreateAnswer from "./WebRTCCreateAnswer";

const messages = {
  AppStateMessage,
  BatteryStatus,
  CameraAndFlashlightState,
  CloudAccountSyncMessage,
  CameraHistoryReplayEventUpdate,
  DeviceUnpairedMessage,
  MotionDetectorState,
  MotionInfoPacket,
  RequestPairingMessage,
  RequestPairingResponseMessage,
  SetVideoFlashlightMessage,
  StopVideoBroadcasting,
  WebRTCCandidate,
  WebRTCCreateOffer,
  WebRTCCreateAnswer
};

export { messages };
