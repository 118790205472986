import { getToken } from "@chakra-ui/react";

const baseStyle = ({ theme }: { theme: any }) => ({
  field: {
    py: "2",
    pl: "12",
    pr: "28",
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: 400,
    outline: "solid 2px transparent",
    outlineOffset: "2px",
    position: "relative",
    appearance: "none",
    transitionProperty: "common",
    transitionDuration: "normal",
    bg: "surface-variant",

    borderRadius: "24px",
    border: "1px solid",
    borderColor: "outline",
    option: {
      bg: "surface",
      borderColor: "outline",
    },
    _focus: {
      borderColor: "primary",
      boxShadow: `0 0 0 1px ${getToken("colors", "primary")(theme)}`,
    },
  },
  icon: {
    width: 16,
    height: "100%",
    right: 4,
    color: "currentColor",
    fontSize: "2.5rem",
    position: "absolute",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    pointerEvents: "none",
    top: "50%",
    transform: "translateY(-50%)",
  },
});

export default {
  baseStyle,
};
