import protoRoot from "../proto-root";

function create(payload: {}) {
  const RPCResponse = protoRoot.lookupType("comm.RPCResponse");
  const rpcResponse = RPCResponse.create(payload);
  return rpcResponse;
}
const rpcResponse = { create };

export default rpcResponse;
