import { useCallback, useEffect, useRef, useState } from "react";
import Flow from "@/lib/Flow";
import Modal from "@/components/Modal";
import Announcer from "@/modules/Announcer";
import { updateCameraStationSettings } from "@/modules/cloud/station";
import { platformToServerCameraSettings } from "@/utils";
import useSettingsSaveProgress from "@/hooks/useSettingsSaveProgress";
import { useCameraSettings } from "./useCameraSettings";
import steps from "./steps";

export default function CameraSettingsFlow({ NoiseIndicator, settingsStore }: CSTypes.FlowProps) {
  const shouldUpdateCachedState = useRef(false);
  const [cachedState, setCachedState] = useState(settingsStore.state);
  const { isOpen, close } = useCameraSettings((state) => ({
    isOpen: state.isOpen,
    close: state.close
  }));
  const { progressControls, errorControls, Progress, Error } = useSettingsSaveProgress();

  useEffect(() => {
    if (isOpen && shouldUpdateCachedState.current) {
      shouldUpdateCachedState.current = false;
      setCachedState(settingsStore.state);
    }
    if (!isOpen) shouldUpdateCachedState.current = true;
  }, [isOpen, settingsStore.state]);

  const updateSettings = useCallback(
    async (setting: CSTypes.UpdateParams) => {
      progressControls.show();
      const response = await updateCameraStationSettings({
        xmppLogin: settingsStore.xmppLogin,
        color: setting.color,
        icon: setting.icon,
        name: setting.name,
        ...(setting.settings && {
          settings: platformToServerCameraSettings(setting.settings)
        })
      });
      if (!response) {
        errorControls.show();
        return;
      }
      Announcer.getInstance().announceItemChange("DEVICES");
      settingsStore.update(setting);
      setCachedState({
        ...settingsStore.state,
        ...setting,
        settings: {
          ...settingsStore.state.settings,
          ...setting.settings
        }
      });
      progressControls.hide();
    },
    [progressControls, settingsStore, errorControls]
  );

  return (
    <Modal isOpen={isOpen} onClose={close} modalBodyProps={{ pt: 8 }}>
      <Error />
      <Flow
        Progress={Progress}
        initialStep="settings"
        steps={steps}
        onClose={close}
        NoiseIndicator={NoiseIndicator}
        updateSettings={updateSettings}
        cameraSettings={cachedState}
        xmppLogin={settingsStore.xmppLogin}
        deviceName={settingsStore.deviceName}
      />
    </Modal>
  );
}
