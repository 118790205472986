import { useAuth } from "@/store/auth";
import { useDevices } from "@/store/devices";
import { getKnownCameraControls, getPlatformCameraSettings } from "@/utils";
import { updateInstallation } from "../app/installation";
import PresenceKeeper from "../app/Presence";
import { fetchCurrentUser, getCloudSettings } from "../cloud/auth";
import { getDevices } from "../cloud/devices";

type DeviceUpdaterUpdate = {
  objectId?: string | undefined;
  cameraControls?: CameraControls | undefined;
  settings?: CameraSettings | undefined;
  icon?: CameraIconId | undefined;
  color?: CameraColorId | undefined;
  jid: string;
  role: "camera" | "viewer";
  name?: string;
};

export default class ItemSynchronizers {
  static deviceUpdater: (update: DeviceUpdaterUpdate) => void | undefined;

  static synchronizeCloudAccount = async () => {
    log.synchronizer("Synchronizing cloud account");
    await fetchCurrentUser();
    await updateInstallation();
  };

  static synchronizeCloudSettings = async () => {
    log.synchronizer("Synchronizing cloud settings");
    const settings = await getCloudSettings();
    log.info("received cloud settings", settings);
  };

  static synchronizeDevices = async () => {
    log.synchronizer("Synchronizing devices");
    const xmppLogin = useAuth.getState().device.xmppCredentials?.xmppLogin;
    const pairedDevices = useDevices.getState().devices;

    const allDevices = await getDevices();
    log.synchronizer("Parse devices ", allDevices);

    const oldDevices = Object.values(pairedDevices).filter((device) => {
      return !allDevices.devices.find((d) => d.xmppLogin === device.jid);
    });
    oldDevices.forEach((device) => {
      useDevices.getState().removePairedDevice(device.jid);
    });

    allDevices.devices.forEach((device) => {
      if (device.xmppLogin === xmppLogin) {
        useAuth.getState().updateDevice({
          name: device.deviceName,
          deviceName: device.deviceName
        });
      } else {
        PresenceKeeper.get(device.xmppLogin);
        useDevices.getState().updatePairedDevice({
          jid: device.xmppLogin,
          name: device.deviceName,
          deviceName: device.deviceName
        });
      }
    });

    allDevices.devices.forEach((device) => {
      const camera = allDevices.cameraStations.find((d) => d.xmppLogin === device.xmppLogin);
      const update = {
        jid: device.xmppLogin,
        role: camera ? "camera" : "viewer",
        ...(camera && {
          ...(camera.name && { name: camera.name }),
          objectId: camera.objectId,
          cameraControls: getKnownCameraControls(camera.cameraControls),
          settings: getPlatformCameraSettings(camera.settings),
          icon: camera.icon,
          color: camera.color || "color1"
        })
      } as const;

      if (device.xmppLogin === xmppLogin && this.deviceUpdater) {
        log.synchronizer("Calling 'deviceUpdater'", device.xmppLogin);
        this.deviceUpdater(update);
      }
      if (device.xmppLogin !== xmppLogin) useDevices.getState().updateExistingDevice(update);
    });
  };
}
