import protoRoot from "../proto-root";

const name = "getCameraHistoryStreamSegmentFileRPCResponse";

function create({
  data,
  date,
  result,
  imageRotation,
}: GetCameraHistoryStreamSegmentFileResponse) {
  const GetCameraHistoryStreamSegmentFileRPCResponse = protoRoot.lookupType(
    "comm.GetCameraHistoryStreamSegmentFileRPCResponse"
  );
  const Result =
    GetCameraHistoryStreamSegmentFileRPCResponse.lookupEnum("Result");
  const ImageRotation = protoRoot.lookupEnum("comm.ImageRotation");

  const request = GetCameraHistoryStreamSegmentFileRPCResponse.create({
    data: data || [],
    date: date || 0,
    result: Result.values[result],
    ...(imageRotation && {
      imageRotation: ImageRotation.values[imageRotation],
    }),
  });
  return { payload: request, name };
}

const getCameraHistoryStreamSegmentFileRPCResponse = { create, name };

export default getCameraHistoryStreamSegmentFileRPCResponse;
