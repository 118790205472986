import { useApp } from "@/store/app";
import environment from "../environment";

export type Config = {
  useLimiter?: boolean;
};

const restartLimit = 5;
const reset_timeout = 15 * 60 * 1000;
const key = environment.appMode + "-restart-instance";
let isRestarting = false;
let currentRestartInstance = Number(localStorage.getItem(key) || 0);

const restartApp = ({ useLimiter }: Config = {}) => {
  if (!useLimiter) {
    localStorage.setItem(key, "0");
    window.location.reload();
  } else if (!isRestarting) {
    if (currentRestartInstance >= restartLimit) {
      localStorage.setItem(key, "0");
      useApp.getState().setStatus("FAILURE");
    } else {
      isRestarting = true;
      currentRestartInstance++;
      localStorage.setItem(key, String(currentRestartInstance));
      window.location.reload();
    }
  }
};

setTimeout(() => {
  currentRestartInstance = 0;
  localStorage.setItem(key, "0");
}, reset_timeout);

export default restartApp;
