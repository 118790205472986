import protobuf from "protobufjs";
import protoRoot from "./proto-root";

export function createEnvelopeBuffer(payload: {
  [key: string]: protobuf.Message;
}) {
  const Envelope = protoRoot.lookupType("comm.Envelope");
  log.protobuf("About to create EnvelopeBuffer, payload", payload);

  const err = Envelope.verify(payload);
  if (err) log.warn("Error verifying payload", err, payload);
  const envelope = Envelope.create(payload);
  log.protobuf("Created Envelope", envelope);

  const buffer = Envelope.encode(envelope).finish();
  return buffer;
}

function encodeEnvelopeToBase64(envelopeBuffer: Uint8Array) {
  return protobuf.util.base64.encode(envelopeBuffer, 0, envelopeBuffer.length);
}

export function prepareEnvelope({
  payload,
  sendBy,
}: {
  payload: { [key: string]: protobuf.Message & Keyable };
  sendBy: string;
}) {
  if (payload.rpcRequest && payload.rpcRequest.pingRequest) {
    log.protobuf(`Preparing pingRequest`, payload);
  } else if (payload.rpcResponse && payload.rpcResponse.pingResponse) {
    log.protobuf(`Preparing pingResponse`, payload);
  } else {
    log.protobuf(`Preparing envelope for ${sendBy}, payload:`, payload);
  }

  const envelopeBuffer = createEnvelopeBuffer(payload);

  if (sendBy === "dataChannel") return envelopeBuffer;
  return encodeEnvelopeToBase64(envelopeBuffer);
}
