import { ReplayDurationKeys } from "@/enums/replayDuration";
import { replayDurations } from "@/modules/replay/constraints";
import { useAuth } from "@/store/auth";
import { getHasActiveSubscription } from "@/store/auth/selectors";

export const useReplayDuration = (replayDuration?: ReplayDuration) => {
  const isPremium = useAuth(getHasActiveSubscription);

  const replayDurationInfo = {
    duration: isPremium ? replayDurations.premium[replayDuration || "8h"] : replayDurations.free["10m"],
    key: isPremium ? ReplayDurationKeys[replayDuration || "8h"] : ReplayDurationKeys["10m"]
  };

  return replayDurationInfo;
};
