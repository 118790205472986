/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from "react";

export function useMount(func: Function) {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) return;
    didMount.current = true;
    func();
  }, []);
}

export function useUnmount(func: Function) {
  const allow = useRef(false);

  useEffect(() => {
    if (!allow.current) return;
    return () => {
      func();
    };
  }, []);

  return () => (allow.current = true);
}
