import type { AnimationProps } from "framer-motion";
import { motion } from "framer-motion";

const pageAnimation: AnimationProps["variants"] = {
  initial: { x: "50%", opacity: 0 },
  animate: { x: 0, opacity: 1 },
  exit: { x: "-50%", opacity: 0 }
};

export default function PageAnimation({ children }: { children: any }) {
  return (
    <motion.div
      variants={pageAnimation}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ duration: 0.3 }}
      style={{
        height: "calc(100% - 76px)",
        display: "flex",
        justifyContent: "center"
      }}
    >
      {children}
    </motion.div>
  );
}
