import { Button, Flex, Heading, Text } from "@chakra-ui/react";
import { t, openEmailClient } from "@/utils";
import Icon from "@/components/Icon";
import Logo from "@/components/Logo";
import Warning from "assets/icons/warning.svg?react";

export default function AlreadyRunningScreen() {
  return (
    <Flex w="100vw" h="100vh" justify="center" align="center" direction="column" textAlign="center">
      <Logo
        imgProps={{
          width: "140px",
          height: "32px",
          pos: "absolute",
          top: "32",
          left: "50%",
          transform: "translateX(-50%)"
        }}
      />
      <Icon icon={Warning} style={{ mb: "16" }} fill="orange" />
      <Heading as="h4" maxWidth="542px" mb="12">
        {t("alreadyOpenInAnotherTab.title")}
      </Heading>
      <Text maxWidth="542px">
        {t("alreadyOpenInAnotherTab.subtitle", {
          1: (
            <Button
              variant="unstyled"
              color="primary"
              fontWeight={400}
              _hover={{ bg: "none" }}
              _active={{ bg: "none" }}
              onClick={() => openEmailClient()}
            />
          )
        })}
      </Text>
    </Flex>
  );
}
