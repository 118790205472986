import { t } from "@/utils";
import { Box, Flex, Radio, RadioGroup, Stack } from "@chakra-ui/react";
import NavigationBar from "@/components/NavigationBar";
import { ReplayDurationKeysArray } from "@/enums/replayDuration";
import { useButtonStateColors } from "@/theme/utils/getButtonStateColor";
import { usePremiumScreen } from "@/modules/app/PremiumScreen";
import { useReplayDuration } from "@/hooks/useReplayDuration";
import { getReplayDurationString } from "@/modules/replay/utils";

export default function ReplayDuration({
  Progress,
  previous,
  updateSettings,
  cameraSettings
}: CSTypes.CameraSettingsStepProps) {
  const { displayBannerForFreeUser } = usePremiumScreen();
  const { key: duration } = useReplayDuration(cameraSettings.settings.replayDuration);
  const { active, hover } = useButtonStateColors("on-surface", "surface2");

  const handleChange = (replayDuration: ReplayDuration) => {
    displayBannerForFreeUser("replay").elseRun(() => updateSettings({ settings: { replayDuration } }));
  };

  return (
    <Box>
      {Progress && <Progress />}
      <NavigationBar title={t("replayLength")} onBack={previous} props={{ mb: "8" }} />
      <RadioGroup value={duration} onChange={handleChange}>
        <Stack spacing="16">
          {ReplayDurationKeysArray.map((replayDuration) => {
            return (
              <Flex
                key={replayDuration}
                border="1px solid"
                borderColor="outline"
                borderRadius="md"
                transition="background-color 0.2s"
                _hover={{ bg: hover }}
                _active={{ bg: active }}
              >
                <Radio p="12" w="100%" h="100%" value={replayDuration}>
                  {getReplayDurationString(replayDuration, "long")}
                </Radio>
              </Flex>
            );
          })}
        </Stack>
      </RadioGroup>
    </Box>
  );
}
