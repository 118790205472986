import { useApp } from "@/store/app";
import { useAuth } from "@/store/auth";
import { commEmitter } from "@/modules/events/emitter";
import { messages } from "@/modules/communication";
import Messenger from "@/modules/Messenger";
import Xmpp from "@/modules/Xmpp";
import { cancelDevicePairing } from "@/modules/cloud/pairing";
import { logInCameraDeviceWithOtp } from "@/modules/cloud/auth";
import { confirmDevicePairing } from "camera/modules/cloud/pairing";
import { useStation } from "camera/store/station";

type PairingRequest = RequestPairingMessage & { from: string };

export default class ResponseManager {
  private status: "running" | "idle" = "idle";
  request: PairingRequest | null = null;
  private isResponding = false;
  private onSuccess: Cb;
  private onError: Cb;
  private onRequest: Cb;
  private unsubscribe!: Cb;
  private messenger = Messenger.getInstance();
  private xmpp = Xmpp.getInstance();
  constructor({ onSuccess, onError, onRequest }: { onSuccess: Cb; onError: Cb; onRequest: Cb }) {
    this.onSuccess = onSuccess;
    this.onError = onError;
    this.onRequest = onRequest;
  }

  init = () => {
    log.pairing("starting response manager");
    this.status = "running";
    commEmitter.on(messages.RequestPairingMessage.name, this.onPairingRequest);
  };

  destroy = () => {
    log.pairing("destroying response manager");
    this.status = "idle";
    commEmitter.off(messages.RequestPairingMessage.name, this.onPairingRequest);
    this.reset();
  };

  reset = () => {
    log.pairing("resetting response manager");
    this.request = null;
    this.isResponding = false;
  };

  startReactingToAppStatus = () => {
    this.unsubscribe = useApp.subscribe(this.onAppStateChange);
  };

  stopReactingToAppStatus = () => {
    this.unsubscribe();
  };

  private onAppStateChange = (store: AppStore) => {
    if (store.status === "SIGNING_OUT" && this.status === "running") {
      this.destroy();
    }
    if (store.status !== "SIGNING_OUT" && this.status === "idle") {
      this.init();
    }
  };

  private onPairingRequest = (request: RequestPairingMessage, from: string) => {
    log.pairing("Received pairing request", request, from);
    const presence = useApp.getState().presence;
    if (this.isResponding) {
      this.sendPairingResponse({
        to: from,
        pairingType: request.pairingType,
        requestId: request.requestId,
        result: "PAIRING_IN_PROGRESS"
      });
      return;
    }
    if (presence === "receiver_connected" || presence === "sender_connected") {
      this.sendPairingResponse({
        to: from,
        pairingType: request.pairingType,
        requestId: request.requestId,
        result: "REJECTED"
      });
      return;
    }

    this.request = { ...request, from };
    this.isResponding = true;
    this.onRequest();
  };

  confirmPairing = async () => {
    log.pairing("confirmed pairing");
    if (!this.request) {
      log.err("No request to confirm");
      return;
    }
    const xmppLogin = useAuth.getState().device.xmppCredentials!.xmppLogin;
    try {
      await confirmDevicePairing({
        pairingRequestId: this.request.requestId,
        confirmingDeviceJID: xmppLogin,
        confirmingPairingType: this.request.pairingType
      });

      log.pairing("pairing request", this.request);
      switch (this.request.pairingType) {
        case "OUR_FAMILY":
        case "OUR_FAMILY_CREATE":
        case "INITIAL":
          if (!this.request.otp || !this.request.authId) {
            log.err("No otp or authId in request");
            return;
          }
          log.pairing("logging in with otp");
          await logInCameraDeviceWithOtp({
            authId: this.request.authId,
            otp: this.request.otp
          });
          this.sendPairingResponse({
            result: "ACCEPTED"
          });
          useStation.getState().setRole("camera");
          this.xmpp.sendPresence("sender");
          this.onSuccess();
          return;
        default:
          return;
      }
    } catch (err) {
      log.err(err);
      this.sendPairingResponse({ result: "FAILED" });
      this.reset();
      this.onError();
    }
  };

  rejectPairing = () => {
    log.pairing("Rejecting pairing request");
    cancelDevicePairing(this.request?.requestId || "");
    this.sendPairingResponse({ result: "REJECTED" });
    this.reset();
  };

  sendPairingResponse = (params: SendPairingResponseParams) => {
    log.pairing("sendPairingResponse params ", params);
    const deviceName = useAuth.getState().device.name;
    try {
      const message = messages.RequestPairingResponseMessage.create({
        deviceName,
        result: params.result,
        pairingType: params.pairingType || this.request!.pairingType,
        requestId: params.requestId || this.request!.requestId,
        otp: params.otp,
        authId: params.authId
      });

      log.pairing("Sending pairing response", message);

      log.pairing("sending to ", params.to || this.request!.from);
      this.messenger.sendAsEnvelope({
        to: params.to || this.request!.from,
        payload: { message }
      });
    } catch (err) {
      log.err("Error trying to send pairing response");
      log.err(err);
    }
  };
}
