type MotionSensitivityKeysType = {
  [key in MotionDetectionSensitivity]: MotionDetectionSensitivity;
};

export const MotionSens = {
  HIGH: {
    diffThreshold: 15,
    contourAreaThreshold: 0,
    motionDuration: 1500,
  },
  MEDIUM: {
    diffThreshold: 25,
    contourAreaThreshold: 0.01,
    motionDuration: 1500,
  },
  LOW: {
    diffThreshold: 35,
    contourAreaThreshold: 0.05,
    motionDuration: 1500,
  },
} as const;

export const MotionThresholds = {
  HIGH: 0.005,
  MEDIUM: 0.01,
  LOW: 0.05,
} as const;

export const MotionStringKeys = {
  HIGH: "noiseSensitivity.level.high",
  MEDIUM: "noiseSensitivity.level.medium",
  LOW: "noiseSensitivity.level.low",
} as const;

export const MotionSensKeys: MotionSensitivityKeysType = {
  HIGH: "HIGH",
  MEDIUM: "MEDIUM",
  LOW: "LOW",
} as const;

export const MotionSensKeysArr = Object.values(MotionSensKeys);
