import { useAuth } from "@/store/auth";
import { getHasActiveSubscription } from "@/store/auth/selectors";
import { fetchCurrentUser } from "../cloud/auth";
import { dataSyncEmitter } from "../events/emitter";

const ONE_HOUR_IN_MS = 3600000;

const getPremiumStats = () => {
  const authState = useAuth.getState();
  const expirationDate = authState.user?.subscription?.expirationDate;

  return {
    isActive: getHasActiveSubscription(useAuth.getState()),
    remainingTime: expirationDate ? new Date(expirationDate.iso).getTime() - new Date().getTime() : null
  };
};

const updatePremiumState = async () => {
  const { remainingTime } = getPremiumStats();
  if (remainingTime && remainingTime <= ONE_HOUR_IN_MS * 2) await fetchCurrentUser();
};

export function createPremiumChangeEmitter() {
  let isActive = getPremiumStats().isActive;

  const checkPremiumState = async () => {
    const { isActive: currentHasActivePremium } = getPremiumStats();
    if (currentHasActivePremium !== isActive) {
      dataSyncEmitter.emit("premium-change", currentHasActivePremium);
      isActive = currentHasActivePremium;
      log.app("Premium emitter:", isActive);
    }
  };

  setInterval(() => {
    checkPremiumState();
    updatePremiumState();
  }, ONE_HOUR_IN_MS);

  useAuth.subscribe((store) => store.user?.subscription, checkPremiumState);
}
