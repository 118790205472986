import Peers from "../Peers";
import { commEmitter } from "../events/emitter";
import Xmpp from "../Xmpp";
import {
  decodeEnvelope,
  getEnvelopeContent,
  concatDataParts,
  prepareEnvelope,
  MAX_BYTE_LENGTH,
  createMultiParts,
  createEnvelopeBuffer
} from "../communication";

class Messenger {
  private multiParts: {
    [jid: string]: {
      [id: string]: MultiPart;
    };
  } = {};
  private xmpp = Xmpp.getInstance();

  private static instance: Messenger;
  static getInstance() {
    if (!Messenger.instance) {
      Messenger.instance = new Messenger();
      log.messenger("Messenger initialized");
    }
    return Messenger.instance;
  }

  private processMultiPart = (newMultiPart: MultiPartProps, xmppLogin: string) => {
    log.messenger("Received multiPart", newMultiPart);
    const { uuid, total, part, data } = newMultiPart;
    let multiPart = this.multiParts[xmppLogin]?.[uuid];

    if (multiPart == null) {
      multiPart = {
        total,
        uuid,
        dataParts: []
      };

      (this.multiParts[xmppLogin] ?? (this.multiParts[xmppLogin] = {}))[uuid] = multiPart;
    }

    const expectedPartIndex = multiPart.dataParts.length;
    if (expectedPartIndex !== part) {
      log.err("Deleting multiPart", multiPart);
      delete this.multiParts[xmppLogin];
      return;
    }

    multiPart.dataParts.push(new Uint8Array(data));

    if (part === total - 1) {
      log.messenger("Received last multipart:", multiPart);

      const envelopeBuffer = concatDataParts(multiPart.dataParts);
      const envelope = decodeEnvelope(envelopeBuffer);
      const { type, payload } = getEnvelopeContent(envelope);

      delete this.multiParts[xmppLogin];
      commEmitter.emit(type, payload, xmppLogin);
    }
  };

  private createDataChannelMessageHandler = (peer: Peer) => (e: MessageEvent) => {
    if (peer == null) return;
    const { jid } = peer;
    log.messenger("dataChannel.onmessage fired:", e);

    const envelopeAsArrayBuffer = e.data;
    const envelopeAsBuffer = new Uint8Array(envelopeAsArrayBuffer);
    const envelope = decodeEnvelope(envelopeAsBuffer);

    if (envelope.multipart) {
      this.processMultiPart(envelope.multipart, jid);
      return;
    }

    const { type, payload } = getEnvelopeContent(envelope);
    commEmitter.emit(type, payload, jid);
  };

  addDataChannelMessageHandler = (peer: Peer) => {
    const onMessageHandler = this.createDataChannelMessageHandler(peer);
    if (peer.commDataChannel) peer.commDataChannel.onmessage = onMessageHandler;
  };

  private getDataChannelForPayload(peer: Peer) {
    const { commDataChannel, peerConnection } = peer;
    if (!peerConnection || !commDataChannel) return null;
    const { connectionState } = peerConnection;
    if (connectionState === "connected" && commDataChannel.readyState === "open") {
      return commDataChannel;
    }
    return null;
  }

  sendAsEnvelope({
    to: xmppLogin,
    payload,
    sendBy
  }: {
    to: string;
    payload: { [key: string]: protobuf.Message };
    sendBy?: SendBy;
  }) {
    const peer = Peers.get(xmppLogin);
    let dataChannel: RTCDataChannel | null = null;
    if (!sendBy) {
      dataChannel = peer ? this.getDataChannelForPayload(peer) : null;
      sendBy = dataChannel == null ? "xmpp" : "dataChannel";
    }

    const envelope = prepareEnvelope({ payload, sendBy });

    if (sendBy === "xmpp") {
      log.messenger("Sending envelope through xmpp");
      this.xmpp.sendEnvelope(envelope as string, xmppLogin);
    } else if (sendBy === "dataChannel") {
      const byteLength = (envelope as Uint8Array).byteLength;
      log.messenger("Envelope byte length:", byteLength);

      if (byteLength < MAX_BYTE_LENGTH) {
        log.messenger("Sending over dataChannel");
        try {
          dataChannel?.send(envelope as Uint8Array);
        } catch (err) {
          log.err("Error sending through dataChannel", err);
        }
      } else {
        log.messenger("Sending over dataChannel as MultiParts");
        const multiParts = createMultiParts(envelope as Uint8Array);
        log.messenger("MultiParts created", multiParts);

        for (const multipart of multiParts) {
          try {
            const envelopeBuffer = createEnvelopeBuffer({ multipart });
            dataChannel?.send(envelopeBuffer);
          } catch (err) {
            log.err("Error sending through dataChannel", err);
          }
        }
      }
    }
  }
}

export default Messenger;
