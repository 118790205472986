import protoRoot from "../proto-root";
import createMessage from "./createMessage";

const name = "deviceUnpairedMessage";

function create() {
  const DeviceUnpairedMessage = protoRoot.lookupType(
    `comm.DeviceUnpairedMessage`
  );
  const message = DeviceUnpairedMessage.create();

  return createMessage({ [name]: message });
}

const deviceUnpairedMessage = { create, name };

export default deviceUnpairedMessage;
