import EventEmitter from "@/lib/EventEmitter";
import { getStreamConstraints } from "@/modules/stream/constraints";
import DbController from "./modules/DbController";
import TimeRanges from "./modules/TimeRanges";
import Playlist from "./modules/Playlist";
import Transcoder from "./modules/Transcoder";
import { APPEND_THRESHOLD_MS, SEGMENT_LENGTH } from "./const";
import { getSegmentEndTime, mimeType } from "./extensions";
import DB from "./DB";
import { useStation } from "camera/store/station";
import { getReplayDuration } from "@/modules/replay/constraints";
import { addTimeout } from "@/utils";
import CrashReporter from "@/modules/app/CrashReporter";
import { useApp } from "@/store/app";

const setStatus = useApp.getState().setStatus;

export default class Recorder {
  private emitter = new EventEmitter();
  on = this.emitter.on;
  off = this.emitter.off;

  private mediaRecorder: MediaRecorder | null = null;
  private stream: MediaStream | null = null;

  private timeout: NodeJS.Timeout | null = null;
  private hasCreatedInitFile = false;

  private currentInitId: number | null = null;
  private currentInitFilename: string | null = null;

  private isTranscoding = false;

  private uploadDateRange: { start: number | null; end: number | null } = {
    start: null,
    end: null
  };

  private sourceDate: Date = new Date();
  private trancoderQueue: { data: Blob; trueTime: number }[] = [];
  private videoAppendInMs = 0;
  private trueSegmentEndTime = 0;

  db = new DB();
  private dbController = new DbController(this.db);
  playlist = new Playlist(this.dbController);
  private transcoder = new Transcoder(this.playlist);

  status: "idle" | "recording" = "idle";

  private getStreamConstraints = () => useStation.getState().settings.videoQuality;
  private getReplayDuration = () => useStation.getState().settings.replayDuration;

  createNewMediaRecorder = () => {
    if (!this.stream) {
      log.err("Couldn't create new mediaRecorder, 'this.stream' is 'null' or 'undefined'");
      return;
    }
    this.mediaRecorder?.removeEventListener("dataavailable", this.onDataAvailable);
    const bitrate = getStreamConstraints(this.getStreamConstraints()).bitrate;
    log.recorder("Creating new mediaRecorder with params:", this.stream.getVideoTracks()[0].getConstraints(), bitrate);
    this.mediaRecorder = new MediaRecorder(this.stream, { bitsPerSecond: bitrate, mimeType });
    this.mediaRecorder.addEventListener("dataavailable", this.onDataAvailable);
  };

  init = async (stream: MediaStream) => {
    this.stream = stream;
    try {
      this.mediaRecorder = new MediaRecorder(this.stream, {
        videoBitsPerSecond: getStreamConstraints(this.getStreamConstraints()).bitrate,
        audioBitsPerSecond: 128000,
        mimeType
      });
      this.mediaRecorder.addEventListener("dataavailable", this.onDataAvailable);
      log.recorder("Created mediaRecorder, continuing");
      await addTimeout(this.db.init);
      log.recorder("db.init finished");
      await this.transcoder.init();
      log.recorder("transcoder.init finished");
      await this.dbController.deleteOlderThan(getReplayDuration(this.getReplayDuration()));
      log.recorder("dbController.deleteOlderThan finished");
      await this.playlist.loadGapFiles();
      log.recorder("playlist.loadGapFiles finished");

      TimeRanges.init(this.dbController);
    } catch (err) {
      log.err("Recorder failed to initialize");
      log.err(err);
      if (!isDev) setStatus("FAILURE");
    }
  };

  start = async () => {
    await this.startRecordingSourceVideo();
  };

  stop = () => {
    log.recorder("Stop called");
    if (this.timeout) clearTimeout(this.timeout);
    this.status = "idle";
    this.mediaRecorder?.stop();
  };

  startMarkingDataForUpload = () => {
    if (this.uploadDateRange.start && this.uploadDateRange.end) {
      this.uploadDateRange.end = null;
    } else {
      this.uploadDateRange.start = new Date().getTime() - 2500;
      this.uploadDateRange.end = null;
    }
  };
  stopMarkingDataForUpload = () => (this.uploadDateRange.end = new Date().getTime() + 2500);

  private getShouldBeUploaded = (item: HlsMetaItem | HlsDataItem) => {
    const startTime = new Date(item.createdAt).getTime();
    const endTime = getSegmentEndTime(item);

    const uploadFrom = this.uploadDateRange.start;
    const uploadUntil = this.uploadDateRange.end;

    if (!uploadFrom && !uploadUntil) return false;
    if (uploadFrom && !uploadUntil) return endTime >= uploadFrom;
    if (uploadFrom && uploadUntil) return endTime >= uploadFrom && startTime <= uploadUntil;
    return false;
  };

  private createSourceVideo = () => {
    if (this.status === "idle") {
      log.warn("Recorder is in idle state, but createSourceVideo fired");
      return;
    }
    this.mediaRecorder?.start();

    this.timeout = setTimeout(() => {
      this.trueSegmentEndTime = new Date().getTime();
      this.mediaRecorder?.stop();
      this.createSourceVideo();
    }, SEGMENT_LENGTH);
  };

  private startRecordingSourceVideo = async () => {
    if (this.status === "recording") return;
    log.recorder("Starting creating segments");
    this.hasCreatedInitFile = false;
    this.status = "recording";
    const sourceDate = new Date();
    this.sourceDate = sourceDate;
    await this.dbController.fillSegmentGaps(sourceDate.toISOString());
    await this.playlist.prepareNextUsableIndexes();
    this.createSourceVideo();
  };

  private onDataAvailable = async (event: BlobEvent) => {
    log.recorder("OnDataAvailable fired with data", event.data);
    if (this.status === "idle") {
      log.warn("OnDataAvailable fired in 'idle' status, ignoring");
      return;
    }

    this.dbController.deleteOlderThan(getReplayDuration(this.getReplayDuration()), () =>
      this.playlist.addToDiscontinuitySequences(1)
    );

    const trueSegmentEndTime = this.trueSegmentEndTime;
    if (this.isTranscoding) {
      log.recorder("Transcoding in progress, adding to queue...", this.trancoderQueue.length);
      this.trancoderQueue.push({ data: event.data, trueTime: trueSegmentEndTime });
      return;
    }

    try {
      this.isTranscoding = true;
      await this.transcode(event.data, trueSegmentEndTime);
      if (!this.hasCreatedInitFile && this.status === "recording") this.hasCreatedInitFile = true;

      while (this.trancoderQueue.length > 0) {
        log.recorder("Found items in queue: ", this.trancoderQueue);
        const { data, trueTime } = this.trancoderQueue.shift()!;
        await this.transcode(data, trueTime);
      }
      this.isTranscoding = false;
    } catch (err) {
      log.err(err);
      this.isTranscoding = false;
      if (!isDev) restartApp({ useLimiter: true });
    }
  };

  private transcode = async (blob: Blob, trueSegmentEndTime: number) => {
    const { initData, segmentsData } = await this.transcoder.transcode({
      blob,
      includeInitData: !this.hasCreatedInitFile,
      appendInMs: this.videoAppendInMs || null
    });

    let initFilename: string | undefined;

    if (initData) {
      log.recorder("Got new INIT data");
      const index = this.playlist.getLatestInitIndex();
      initFilename = `i${index}.mp4`;

      this.currentInitFilename = initFilename;
      this.currentInitId = this.sourceDate.getTime();

      const initMetaPayload: HlsMetaItem = {
        filename: initFilename,
        createdAt: this.sourceDate.toISOString(),
        duration: null,
        discontinuity: false,
        index
      };
      const initDataPayload: HlsDataItem = {
        filename: initFilename,
        data: initData,
        createdAt: this.sourceDate.toISOString(),
        shouldUpload: 0,
        uploaded: 0,
        selectForUpload: 0,
        initFilename: null,
        initId: this.currentInitId,
        duration: null,
        discontinuity: false
      };
      await Promise.all([this.db.getWrite("hls-meta")(initMetaPayload), this.db.getWrite("hls-data")(initDataPayload)]);
      this.playlist.addToNextInitIndex(1);
    }

    const segmentStartIndex = this.playlist.getLatestSegmentIndex();

    let i = 0;
    let shouldAnnouceUpload = false;
    for (const segment of segmentsData) {
      const { data, duration } = segment;
      if (!duration || !data) {
        log.err("No duration or data:", duration, data);
        CrashReporter.sendException("No duration or data", { key: "err", extra: { duration, data: data.length } });
        if (!isDev) restartApp();
      }
      if (Number(duration) < 1) log.warn("Segment duration is", duration);
      const index = segmentStartIndex + i;
      const segmentMetaPayload: HlsMetaItem = {
        filename: `s${index}.m4s`,
        duration,
        createdAt: this.sourceDate.toISOString(),
        discontinuity: i === 0,
        index
      };

      const shouldMarkForUpload = this.getShouldBeUploaded(segmentMetaPayload);
      log.recorder(`Should upload ${segmentMetaPayload.filename}:`, shouldMarkForUpload);
      if (shouldMarkForUpload) shouldAnnouceUpload = true;

      const segmentDataPayload: HlsDataItem = {
        filename: `s${index}.m4s`,
        data,
        createdAt: segmentMetaPayload.createdAt,
        shouldUpload: shouldMarkForUpload ? 1 : 0,
        selectForUpload: shouldMarkForUpload ? 1 : 0,
        uploaded: 0,
        initFilename: this.currentInitFilename,
        initId: this.currentInitId,
        duration,
        discontinuity: i === 0
      };

      await Promise.all([
        this.db.getWrite("hls-meta")(segmentMetaPayload),
        this.db.getWrite("hls-data")(segmentDataPayload)
      ]);
      log.recorder("DONE saving segment ", index);

      this.sourceDate.setMilliseconds(this.sourceDate.getMilliseconds() + Number(duration) * 1000);
      i++;
    }
    if (shouldAnnouceUpload) this.emitter.emit("segments-should-be-uploaded");
    if (this.uploadDateRange.start && this.uploadDateRange.end) {
      this.uploadDateRange.start = null;
      this.uploadDateRange.end = null;
    }

    const gapBetweenNowAndSegmentEnd = trueSegmentEndTime - this.sourceDate.getTime();
    if (gapBetweenNowAndSegmentEnd >= APPEND_THRESHOLD_MS) {
      log.recorder(
        "Gap between now and segment end is greater then 200ms, next transcoding will append",
        gapBetweenNowAndSegmentEnd
      );
      this.videoAppendInMs = gapBetweenNowAndSegmentEnd;
    } else this.videoAppendInMs = 0;

    this.playlist.addToNextSegmentIndex(segmentsData.length);
    this.playlist.clearLastSentDeltaPlaylists();
  };
}
