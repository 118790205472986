const SCALED_MAX_VALUE = 32767;
const TO_ZERO_CORRECTION = 45;
const SENSITIVITY_RANGE = 80.0;
const SENSITIVITY_RANGE_SHIFT = 50;
const DBDIV_RECORDING_MAX = 37;
const DECIBEL_CORRECTION = 0;

export function toRelative({
  value,
  sensitivity,
  correction,
}: {
  value: number;
  sensitivity: number;
  correction: number;
}) {
  const scaledValue = value * SCALED_MAX_VALUE;

  const db = Math.max(
    0,
    20.0 * Math.log10(scaledValue) -
      TO_ZERO_CORRECTION +
      DECIBEL_CORRECTION +
      (sensitivity - 0.5) * SENSITIVITY_RANGE +
      correction * SENSITIVITY_RANGE_SHIFT
  );

  return Math.min(db / DBDIV_RECORDING_MAX, 1.0);
}
