import { TinyColor } from "@ctrl/tinycolor";

export default function combine(
  originalColorCode: string,
  addedColorCode: string
) {
  const base = Object.values(new TinyColor(originalColorCode).toRgb());
  const added = Object.values(new TinyColor(addedColorCode).toRgb());

  const mix: number[] = [];

  base[3] = base[3] / 255;
  added[3] = added[3] / 255;

  mix[3] = 1 - (1 - added[3]) * (1 - base[3]); // alpha
  mix[0] = Math.round(
    (added[0] * added[3]) / mix[3] +
      (base[0] * base[3] * (1 - added[3])) / mix[3]
  ); // red
  mix[1] = Math.round(
    (added[1] * added[3]) / mix[3] +
      (base[1] * base[3] * (1 - added[3])) / mix[3]
  ); // green
  mix[2] = Math.round(
    (added[2] * added[3]) / mix[3] +
      (base[2] * base[3] * (1 - added[3])) / mix[3]
  ); // blue

  mix[3] = Math.round(mix[3] * 255 * 10) / 10;

  const combinedColorCode = new TinyColor({
    r: mix[0],
    g: mix[1],
    b: mix[2],
    a: mix[3],
  });

  return combinedColorCode.toRgbString();
}
