import protoRoot from "../proto-root";
import createMessage from "./createMessage";

const name = "webRTCCandidate";

function create(candidates: Peer["candidates"]["toSend"]) {
  const WebRTCCandidateData = protoRoot.lookupType("WebRTCCandidateData");

  const webRTCCandidates = candidates.map((candidate) =>
    WebRTCCandidateData.create(candidate)
  );

  const WebRTCCandidateMessage = protoRoot.lookupType(
    `comm.WebRTCCandidateMessage`
  );

  const webRTCCandidateMessage = WebRTCCandidateMessage.create({
    candidates: webRTCCandidates,
  });

  return createMessage({ [name]: webRTCCandidateMessage });
}

const webRTCCandidate = { create, name };

export default webRTCCandidate;
