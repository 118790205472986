import protoRoot from "../proto-root";

function create(payload: {}) {
  const RPCRequest = protoRoot.lookupType("comm.RPCRequest");
  const rpcRequest = RPCRequest.create(payload);
  return rpcRequest;
}

const rpcRequest = { create };

export default rpcRequest;
