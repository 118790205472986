import protoRoot from "../proto-root";

const name = "startVideoBroadcastingRPCRequest";

function create() {
  const StartVideoBroadcastingRPCRequest = protoRoot.lookupType(
    "comm.StartVideoBroadcastingRPCRequest"
  );

  const payload = {};

  const request = StartVideoBroadcastingRPCRequest.create(payload);
  return { payload: request, name };
}

const startVideoBroadcastingRPCRequest = { create, name };

export default startVideoBroadcastingRPCRequest;
