const synchronizableItem = {
  CLOUD_ACCOUNT: "CLOUD_ACCOUNT",
  CLOUD_SETTINGS: "CLOUD_SETTINGS",
  DEVICES: "DEVICES",
} as const;

const protobuff = {
  ALL: {
    value: "ALL",
    serverValue: "all",
  },
  CLOUD_ACCOUNT: {
    value: "CLOUD_ACCOUNT",
    serverValue: "cloudAccount",
  },
  CLOUD_SETTINGS: {
    value: "CLOUD_SETTINGS",
    serverValue: "cloudSettings",
  },
  DEVICES: {
    value: "DEVICES",
    serverValue: "devices",
  },
} as const;

const server = {
  all: {
    value: "all",
    protoBuffValue: "ALL",
  },
  cloudAccount: {
    vlaue: "cloudAccount",
    protoBuffValue: "CLOUD_ACCOUNT",
  },
  cloudSettings: {
    value: "cloudSettings",
    protoBuffValue: "CLOUD_SETTINGS",
  },
  devices: {
    value: "devices",
    protoBuffValue: "DEVICES",
  },
} as const;

export default synchronizableItem;
export { protobuff, server };
