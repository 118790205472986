export default function createPeerConnection() {
  return new RTCPeerConnection({
    iceServers: [
      { urls: "stun:stun2.l.google.com:19302" },
      {
        urls: "turn:turn.tappytaps.com?transport=tcp",
        username: "dogmonitor",
        credential: "987654",
      },
    ],
  });
}
