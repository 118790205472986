import { cloudCode } from "@/modules/cloud";

export function savePreviewImage({ base64 }: { base64: string }): Promise<{
  name: string;
  url: string;
}> {
  log.mediaPreview("Saving preview image");
  return cloudCode.run(
    "preview.jpeg",
    {
      base64,
      fileData: {
        metadata: {},
        tags: {}
      }
    },
    { path: "files" }
  );
}

export function updateCameraStationPreviewImage({
  imageFile
}: {
  imageFile: {
    name: string;
    url: string;
  };
}) {
  log.mediaPreview("Updating camera station preview image");
  return cloudCode.run("updateCameraStationPreviewImage", {
    imageFile: {
      ...imageFile,
      __type: "File"
    }
  });
}

export function createCameraHistoryEvent({
  cameraEvent
}: {
  cameraEvent: Optional<CameraEvent, "end"> | Optional<StoreCameraEvent, "end" | "storeId">;
}) {
  const { finished, ...event } = cameraEvent;
  if (!finished) delete event.end;
  if ("storeId" in event) delete event.storeId;

  return cloudCode.run("createCameraHistoryEvent", { event });
}
