import protoRoot from "../proto-root";

function create({ state, level }: { state: BatteryState; level: number }) {
  const BatteryStatus = protoRoot.lookupType(`comm.BatteryStatus`);
  const BatteryState = BatteryStatus.lookupEnum("BatteryState");

  return BatteryStatus.create({
    level,
    state: BatteryState.values[state],
  });
}

const batteryStatus = { create };

export default batteryStatus;
