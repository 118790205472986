import protoRoot from "../proto-root";

function create(event: CameraEvent) {
  const CameraEvent = protoRoot.lookupType(`comm.CameraEvent`);
  const EventType = CameraEvent.lookupEnum("CameraEventType");

  return CameraEvent.create({ ...event, type: EventType.values[event.type] });
}

const cameraEvent = { create };

export default cameraEvent;
