import { getSegmentEndTime, isGapFile, isInitFile } from "../extensions";

export default class TimeRanges {
  static dbController: DbController;

  static init = (dbController: DbController) => {
    this.dbController = dbController;
  };

  static getTimeranges = async () => {
    const timeranges: TimeRange[] = [];
    try {
      const files = await this.dbController.readAllFiles<HlsMetaItem>(
        "hls-meta"
      );

      let hasOpenTimerange = false;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const prevFile = files[i - 1];
        const nextFile = files[i + 1];

        if (isInitFile(file)) {
          if (prevFile && hasOpenTimerange) {
            timeranges[timeranges.length - 1].end = new Date(
              getSegmentEndTime(prevFile)
            ).getTime();
            hasOpenTimerange = false;
          }
          if (!isGapFile(file) && nextFile) {
            timeranges.push({
              start: new Date(nextFile.createdAt).getTime(),
              end: 0,
            });
            hasOpenTimerange = true;
          }
        }
      }
      if (timeranges[timeranges.length - 1]) {
        timeranges[timeranges.length - 1].end = new Date(
          getSegmentEndTime(files[files.length - 1])
        ).getTime();
      }
      log.recorderTimeranges("generated timeranges: ", timeranges);
      return timeranges;
    } catch (err) {
      log.err("Failed getting timeranges", err);
      return [];
    }
  };
}
