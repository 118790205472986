import { SystemStyleObject } from "@chakra-ui/react";

const baseStyle: SystemStyleObject = {
  width: "2.5rem",
  height: "3.5rem",
  borderRadius: "0.5rem",
  textAlign: "center",
  bg: "transparent",
  color: "primary",
  fontWeight: 600,
  fontSize: "1.5rem",

  _focus: {
    border: "2px solid",
    borderColor: "primary",
    outline: "none",
  },
};

const variantOutline = {
  border: "1px solid",
  borderColor: "outline",
};

const variants = {
  outline: variantOutline,
};
const pin_input = {
  baseStyle,
  variants,
};
export default pin_input;
