import { encoder } from "./extensions";

export const PlaylistSettings = (function () {
  function getSkip(d: number) {
    return (d * 6).toFixed(1);
  }

  let TARGETDURATION_ = 7;
  let CANSKIP_ = getSkip(TARGETDURATION_);
  let initPlaylistFallback_: Uint8Array;
  let deltaPlaylistFallback_: Uint8Array;

  function generateSettings(targetDuration = 7) {
    TARGETDURATION_ = targetDuration;
    CANSKIP_ = getSkip(TARGETDURATION_);
    const initPlaylistFallbackString = `#EXTM3U
    #EXT-X-TARGETDURATION:${TARGETDURATION_}
    #EXT-X-VERSION:9f
    #EXT-X-SERVER-CONTROL:CAN-SKIP-UNTIL=${CANSKIP_}
    #EXT-X-DISCONTINUITY-SEQUENCE:0
    #EXT-X-MEDIA-SEQUENCE:0`;
    const deltaPlaylistFallbackString = `#EXTM3U
    #EXT-X-TARGETDURATION:${TARGETDURATION_}
    #EXT-X-VERSION:9
    #EXT-X-SERVER-CONTROL:CAN-SKIP-UNTIL=${CANSKIP_}
    #EXT-X-DISCONTINUITY-SEQUENCE:0
    #EXT-X-MEDIA-SEQUENCE:0
    #EXT-X-SKIP:SKIPPED-SEGMENTS=0`;

    initPlaylistFallback_ = encoder.encode(initPlaylistFallbackString);
    deltaPlaylistFallback_ = encoder.encode(deltaPlaylistFallbackString);
  }

  generateSettings();
  return {
    get TARGETDURATION() {
      return TARGETDURATION_;
    },
    get CANSKIP() {
      return CANSKIP_;
    },
    get initPlaylistFallback() {
      return initPlaylistFallback_;
    },
    get deltaPlaylistFallback() {
      return deltaPlaylistFallback_;
    }
  };
})();
