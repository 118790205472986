import protoRoot from "../proto-root";

const name = "getSignedUrlRpcRequest";

function create(fileName: string) {
  const GetSignedUrlRpcRequest = protoRoot.lookupType(
    "comm.GetSignedUrlRpcRequest"
  );

  const request = GetSignedUrlRpcRequest.create({
    fileName,
  });
  return { payload: request, name };
}

const getSignedUrlRpcRequest = { create, name };

export default getSignedUrlRpcRequest;
