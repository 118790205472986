import { useRef, useEffect, useCallback } from "react";
import { chakra } from "@chakra-ui/react";
import Close from "assets/icons/close_large.svg?react";
import { useTransparentize } from "@/theme/utils/transparentize";
import { dataSyncEmitter } from "@/modules/events/emitter";
import { t } from "@/utils";
import IconButton from "@/components/IconButton";
import { useCamera } from "camera/store/camera";
import { useCameraMethods } from "..";

const EnhancedVideo = chakra("video");

export default function RemoteStreamPlayer() {
  const stream = useRef(new MediaStream()).current;
  const tracks = useRef<{ [id: string]: boolean }>({});
  const remoteStreamRef = useRef<HTMLVideoElement>(null);
  const activeFaceJid = useCamera((state) => state.activeFaceJid);
  const stopFace = useCameraMethods((state) => state.stopFace);

  const bg = useTransparentize("neutral-variant.30", 0.7);

  const addRemoteTrack = useCallback(
    (track: MediaStreamTrack) => {
      if (tracks.current[track.id]) return;
      log.monitoring("Adding remote track", track.id, stream.active, track.readyState);
      tracks.current[track.id] = true;
      stream.addTrack(track);
    },
    [stream]
  );

  const removeRemoteTrack = useCallback((track: MediaStreamTrack) => {
    log.monitoring("Deleteing remote track", track.id);
    delete tracks.current[track.id];
  }, []);

  const removeAllTracks = useCallback(() => {
    log.monitoring("Removing all remote tracks");
    stream.getTracks().forEach((track) => stream.removeTrack(track));
  }, [stream]);

  useEffect(() => {
    const unsub = [
      dataSyncEmitter.on("add-remote-track", addRemoteTrack),
      dataSyncEmitter.on("remove-remote-track", removeRemoteTrack),
      dataSyncEmitter.on("stop-face", removeAllTracks)
    ];

    return () => unsub.forEach((cb) => cb());
  }, [addRemoteTrack, removeRemoteTrack, removeAllTracks]);

  useEffect(() => {
    if (!remoteStreamRef.current) return;
    log.info("Preparing for remote stream");
    remoteStreamRef.current.srcObject = stream;
  }, [stream]);

  const hasFaceActive = Boolean(activeFaceJid);
  return (
    <>
      {hasFaceActive && (
        <IconButton
          icon={Close}
          tooltip={{ label: t("buttons.close") }}
          fill="neutral.99"
          bg={bg}
          onClick={stopFace}
          props={{
            pos: "absolute",
            zIndex: 3,
            top: "2rem",
            left: "2rem"
          }}
        />
      )}
      <EnhancedVideo
        ref={remoteStreamRef}
        autoPlay
        pos="absolute"
        bottom="0"
        right="0"
        w={hasFaceActive ? "100%" : "0px"}
        h={hasFaceActive ? "100%" : "0px"}
        zIndex={hasFaceActive ? 2 : -1}
        objectFit="cover"
        borderRadius="16px"
      />
    </>
  );
}
