import { Styles } from "@chakra-ui/theme-tools";

export const styles: Styles = {
  global: {
    body: {
      fontFamily: "body",
      color: "on-surface",
      bg: "surface",
      transitionProperty: "background-color",
      transitionDuration: "normal",
      lineHeight: "base",
    },
    h1: {
      fontWeight: 600,
      fontFamily: "heading",
      fontSize: ["2.125rem", "3rem", "3.75rem"],
      lineHeight: ["40px", "48px", "64px"],
      letterSpacing: ["normal", "-1px", "-1.29px"],
    },
    h2: {
      fontWeight: 600,
      fontFamily: "heading",
      fontSize: ["1.75rem", "2.125rem", "3rem"],
      lineHeight: ["34px", "40px", "56px"],
    },
    h3: {
      fontWeight: 600,
      fontFamily: "heading",
      fontSize: ["1.375rem", "1.5rem", "2.125rem"],
      lineHeight: ["26px", "32px", "40px"],
    },
    h4: {
      fontWeight: 600,
      fontFamily: "heading",
      fontSize: ["1.125rem", "1.25rem", "1.5rem"],
      lineHeight: ["24px", "24px", "32px"],
      letterSpacing: ["normal", "normal", "0.25px"],
    },
    h5: {
      fontWeight: 600,
      fontFamily: "heading",
      fontSize: ["1rem", "1rem", "1.125rem"],
      lineHeight: ["20px", "20px", "24px"],
    },
    h6: {
      fontWeight: 600,
      fontFamily: "heading",
      fontSize: ["0.9rem", "0.9rem", "1rem"],
      lineHeight: ["16px", "16px", "20px"],
    },
    p: {
      fontWeight: 400,
      fontFamily: "body",
      fontSize: ["1rem", "1rem", "1.125rem"],
      lineHeight: ["24px", "24px", "28px"],
      letterSpacing: ["0.5px", "0.5px", "0px"],
      color: "on-surface-variant",
    },
  },
};
