import { create } from "zustand";

export type Device = {
  role: StoreCameraDevice["role"] | StoreViewerDevice["role"];
  jid: string | null;
  name: string | null;
};

export const useDeleteDeviceFlow = create<{
  isOpen: boolean;
  device: Device | null;
  open: ({ device }: { device: Device }) => void;
  close: () => void;
}>()((set) => ({
  isOpen: false,
  device: null,

  close() {
    set({ isOpen: false, device: null });
  },
  open({ device }) {
    set({ isOpen: true, device });
  }
}));
