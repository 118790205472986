import protoRoot from "../proto-root";

const name = "takeVideoSnapshotRPCResponse";

function create({
  result,
  data,
}: {
  result: "SUCCESS" | "FAILURE";
  data: Uint8Array;
}) {
  const TakeVideoSnapshotRPCResponse = protoRoot.lookupType(
    "comm.TakeVideoSnapshotRPCResponse"
  );
  const Result = protoRoot.lookupEnum("Result");

  const response = TakeVideoSnapshotRPCResponse.create({
    result: Result.values[result],
    // snapshot: VideoSnapshotPreview.create({ data })
  });
  return { payload: response, name };
}

const takeVideoSnapshotRPCResponse = { create, name };

export default takeVideoSnapshotRPCResponse;
