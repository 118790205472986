import { DBFactory } from "@/lib/DB";

const DB_NAME = "hls-database";
const META_STORE_NAME = "hls-meta";
const DATA_STORE_NAME = "hls-data";
export const DATE_INDEX = "createdAt";
export const SELECT_FOR_UPLOAD_INDEX = "selectForUpload";
export const FILENAME_INDEX = "filename";

const hlsDbConfig = {
  dbName: DB_NAME,
  version: 1,
  stores: {
    [META_STORE_NAME]: {
      name: META_STORE_NAME,
      indexes: [FILENAME_INDEX, DATE_INDEX],
      options: { autoIncrement: true },
    },
    [DATA_STORE_NAME]: {
      name: DATA_STORE_NAME,
      indexes: [FILENAME_INDEX, DATE_INDEX, SELECT_FOR_UPLOAD_INDEX],
      options: { autoIncrement: true },
    },
  },
} as const;

export default class DB extends DBFactory<typeof hlsDbConfig> {
  constructor() {
    super(hlsDbConfig);
  }
}

export type HlsDb = DB;
export type HlsDbConfig = typeof hlsDbConfig;
