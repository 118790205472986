import protobuf from "protobufjs";
import protoRoot from "../proto-root";

function create(currentState: CurrentStateMessage) {
  const CurrentStateMessage = protoRoot.lookupType("appState.CurrentStateMessage");
  return CurrentStateMessage.create(currentState);
}

function encode(payload: CurrentStateMessage) {
  const currentStateMessage = create(payload);
  const CurrentStateMessage = protoRoot.lookupType("appState.CurrentStateMessage");

  const buffer = CurrentStateMessage.encode(currentStateMessage).finish();
  return protobuf.util.base64.encode(buffer, 0, buffer.length);
}

const currentStateMessage = { create, encode };

export default currentStateMessage;
