const absoluteCenter = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
} as const;

const textOverflowDots = {
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
} as const;

export { absoluteCenter, textOverflowDots };
