import protoRoot from "../proto-root";
import createMessage from "./createMessage";

const name = "requestPairingMessage";

function create(payload: RequestPairingMessage) {
  const PairingType = protoRoot.lookupEnum("comm.PairingType");
  const PairingSource = protoRoot.lookupEnum("comm.PairingSource");

  const requestPayload = {
    ...payload,
    pairingType: PairingType.values[payload.pairingType],
    pairingSource: PairingSource.values[payload.pairingSource],
  };

  const RequestPairingMessage = protoRoot.lookupType(
    `comm.RequestPairingMessage`
  );
  const requestPairingMessage = RequestPairingMessage.create(requestPayload);

  return createMessage({ [name]: requestPairingMessage });
}

const requestPairingMessage = { create, name };

export default requestPairingMessage;
