import root from "./proto-root";
import { decodeProtocolBuffer } from "./decode";

export function decodeEnvelope(buffer: Uint8Array) {
  return decodeProtocolBuffer({ typePath: "comm.Envelope", buffer, root });
}

export function concatDataParts(parts: Uint8Array[]) {
  const totalLength = parts.reduce((acc, val) => acc + val.length, 0);
  const result = new Uint8Array(totalLength);
  let offset = 0;
  for (let part of parts) {
    result.set(part, offset);
    offset += part.length;
  }
  return result;
}

export function getEnvelopeContent(envelope: Keyable) {
  const type = Object.keys(envelope)[0];
  const payload = envelope[type];
  let content: EnvelopeContent;

  switch (type) {
    case "message": {
      const concreteType = Object.keys(payload)[0];
      const concreteMessage = payload[concreteType];
      content = { type: concreteType, payload: concreteMessage };
      break;
    }
    case "rpcResponse":
    case "rpcRequest": {
      content = {
        type,
        payload,
      };
      break;
    }
    default:
      content = {};
      break;
  }

  if (payload.pingRequest || payload.pingResponse) {
    log.protobuf(`Received ${content.type}`, content.payload);
  } else {
    log.protobuf(`Received ${content.type}`, content.payload);
  }
  return content;
}
