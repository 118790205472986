import { tabsAnatomy as parts } from "@chakra-ui/anatomy";
import type { SystemStyleObject } from "@chakra-ui/styled-system";
import {
  PartsStyleFunction,
  PartsStyleInterpolation,
  PartsStyleObject,
  SystemStyleFunction,
} from "@chakra-ui/theme-tools";

const baseStyleRoot: SystemStyleFunction = (props) => {
  const { orientation } = props;
  return {
    display: orientation === "vertical" ? "flex" : "block",
  };
};

const baseStyleTab: SystemStyleFunction = (props) => {
  const { isFitted } = props;

  return {
    flex: isFitted ? 1 : undefined,
    transitionProperty: "common",
    transitionDuration: "normal",
    _focusVisible: {
      zIndex: 1,
      boxShadow: "outline",
    },
    _disabled: {
      cursor: "not-allowed",
      opacity: 0.4,
    },
  };
};

const baseStyleTablist: SystemStyleFunction = (props) => {
  const { align = "start", orientation } = props;

  const alignments: Record<string, string> = {
    end: "flex-end",
    center: "center",
    start: "flex-start",
  };

  return {
    justifyContent: alignments[align],
    flexDirection: orientation === "vertical" ? "column" : "row",
  };
};

const baseStyleTabpanel: SystemStyleObject = {
  p: 4,
};

const baseStyle: PartsStyleFunction<typeof parts> = (props) => ({
  root: baseStyleRoot(props),
  tab: baseStyleTab(props),
  tablist: baseStyleTablist(props),
  tabpanel: baseStyleTabpanel,
});

const sizes: Record<string, PartsStyleObject<typeof parts>> = {
  sm: {
    tab: {
      py: 1,
      px: 4,
      fontSize: "sm",
    },
  },
  md: {
    tab: {
      fontSize: "md",
      py: 2,
      px: 4,
    },
  },
  lg: {
    tab: {
      fontSize: "lg",
      py: 3,
      px: 4,
    },
  },
};

const variantSoftRounded: PartsStyleFunction<typeof parts> = (props) => {
  return {
    tab: {
      borderRadius: "9px",
      px: "12",
      py: "8",
      fontWeight: 500,
      color: "on-secondary-container",
      _selected: {
        bg: "secondary-container",
      },
    },
  };
};

const variants: Record<string, PartsStyleInterpolation<typeof parts>> = {
  "soft-rounded": variantSoftRounded,
};

const defaultProps = {
  size: "md",
  variant: "soft-rounded",
};

export default {
  parts: parts.keys,
  baseStyle,
  sizes,
  variants,
  defaultProps,
};
