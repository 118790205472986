import Bowser from "bowser";

const urlPath = window.location.pathname.split("/")[1] || "webapp";
const [appMode, envMode] = import.meta.env.MODE.split("-");

const environment = {
  appName: import.meta.env.VITE_APP_NAME as "camerito",
  parseUrl: import.meta.env.VITE_PARSE_URL,
  xmppServer: import.meta.env.VITE_XMPP_SERVER,
  xmppDomain: import.meta.env.VITE_XMPP_DOMAIN,
  uploadServerUrl: import.meta.env.VITE_UPLOAD_SERVER_URL,
  mail: "support@camerito.com",
  isDev: import.meta.env.DEV,
  appMode: appMode as "camera" | "viewer" | "story",
  envMode: envMode as "test" | "prod",
  strictReact: true,
  system: Bowser.getParser(navigator.userAgent),
  publicPath: import.meta.env.DEV ? "" : `/${urlPath}/${appMode}`,
  sentryDsn: import.meta.env.VITE_APP_SENTRY_DSN
};

export default environment;
