import { useRef, useState } from "react";
import { Box, Input, Text, Flex, useToken, Button } from "@chakra-ui/react";
import NavigationBar from "@/components/NavigationBar";
import IconButton from "@/components/IconButton";
import Circle from "@/components/Circle";
import { CameraIconsArr, CameraColorsArr, CameraColors } from "@/enums/camera";
import { isEmptyObject } from "@/utils";
import { t } from "@/utils";

type Config = {
  title: StringKey;
  renderBack: boolean;
  renderContinue: boolean;
  onSkip?: Cb;
  allowSaveWithoutChange: boolean;
};

type Props = Omit<CSTypes.CameraSettingsStepProps, "onClose" | "xmppLogin" | "deviceName" | "cameraSettings"> & {
  cameraSettings: Omit<CSTypes.CameraSettingsStepProps["cameraSettings"], "settings">;
} & { config?: Config };

const _config: Config = {
  title: "myCamera",
  renderBack: true,
  renderContinue: false,
  allowSaveWithoutChange: false
};

export default function Customize({ previous, cameraSettings, updateSettings, Progress, config = _config }: Props) {
  const { name, color, icon } = cameraSettings;

  const nameRef = useRef<HTMLInputElement>(null);
  const [colorId, setColorId] = useState(color);
  const [iconId, setIconId] = useState(icon);

  const [shadowColor, outlineColor, backgroundColor] = useToken("colors", ["primary", "outline", "background"]);
  const { title, onSkip, renderBack, renderContinue, allowSaveWithoutChange } = config;

  const save = async () => {
    if (!nameRef.current?.value) return;
    const update: CSTypes.UpdateParams = {};
    if (iconId !== icon) update.icon = iconId;
    if (colorId !== color) update.color = colorId;
    if (nameRef.current.value !== name) update.name = nameRef.current.value;
    if (isEmptyObject(update) && !allowSaveWithoutChange) return;
    await updateSettings(update);
  };

  return (
    <Box>
      {Progress && <Progress />}
      <NavigationBar title={t(title)} onBack={renderBack ? previous : undefined}>
        {!onSkip && (
          <Button variant="filledFixedSmall" pos="absolute" right="0" top="4" onClick={save}>
            {t("buttons.save")}
          </Button>
        )}
        {onSkip && (
          <Button variant="textFixedSmall" pos="absolute" right="-12" top="4" onClick={onSkip}>
            {t("buttons.skip")}
          </Button>
        )}
      </NavigationBar>
      <Text variant="label" color="on-surface" mt="8px" mb="1rem">
        {t("camera.name")}
      </Text>
      <Input px="1.5rem" py="1rem" defaultValue={name} ref={nameRef} type="text" mb="24px" />
      <Text variant="label" color="on-surface">
        {t("camera.icon")}
      </Text>
      <Flex flexWrap="wrap" gap="1rem" mt="1rem" mb="24px">
        {CameraIconsArr.map(({ id, icon }) => {
          const isSelected = id === iconId;
          return (
            <IconButton
              key={id}
              p="12"
              fill={CameraColors[colorId].value}
              bg="surface1"
              icon={icon}
              onClick={() => setIconId(id)}
              props={{
                boxShadow: isSelected ? `0px 0px 0px 2px ${shadowColor}` : `0px 0px 0px 1px ${outlineColor}`,
                transition: "background 0.3s, box-shadow 0.2s"
              }}
            />
          );
        })}
      </Flex>
      <Text variant="label" color="on-surface">
        {t("color")}
      </Text>
      <Flex flexWrap="wrap" columnGap="1rem" rowGap="1rem" mt="1rem">
        {CameraColorsArr.map(({ id, value }) => {
          const isSelected = id === colorId;
          return (
            <Circle
              bg={value}
              size="48"
              key={value}
              props={{
                onClick: () => setColorId(id),
                as: "button",
                boxShadow: `${isSelected && `0px 0px 0px 2px ${backgroundColor}, 0px 0px 0px 4px ${shadowColor} `}`,
                transition: "box-shadow 0.2s"
              }}
            />
          );
        })}
      </Flex>
      {renderContinue && (
        <Flex justify="center">
          <Button onClick={save} mt="4rem" minW="18.5rem">
            {t("buttons.continue")}
          </Button>
        </Flex>
      )}
    </Box>
  );
}
