export function proxyWithDefault<T extends object>(
  obj: T,
  defaultValue: keyof T
) {
  const handler: ProxyHandler<T> = {
    get: function (target, name) {
      return target[name as unknown as keyof T] || target[defaultValue];
    },
  };

  const proxy = new Proxy(obj, handler);
  return proxy;
}
