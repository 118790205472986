import protoRoot from "../proto-root";
import createMessage from "./createMessage";

const name = "setVideoFlashlightMessage";

function create({
  enabled,
  intensity,
}: {
  enabled: boolean;
  intensity: number;
}) {
  const SetVideoFlashlightMessage = protoRoot.lookupType(
    `comm.SetVideoFlashlightMessage`
  );

  return createMessage({
    [name]: SetVideoFlashlightMessage.create({
      enabled,
      intensity,
    }),
  });
}

const setVideoFlashlightMessage = { create, name };

export default setVideoFlashlightMessage;
