import BatteryStatus from "../private/BatteryStatus";
import createMessage from "./createMessage";

const name = "batteryStatus";

function create({
  state,
  level,
}: {
  state: "CHARGING" | "FULL" | "DISCHARGING" | "UNKNOWN";
  level: number;
}) {
  return createMessage({
    [name]: BatteryStatus.create({ state, level }),
  });
}

const batteryStatus = { create, name };

export default batteryStatus;
