import protoRoot from "../proto-root";
import createMessage from "./createMessage";

const name = "cloudAccountSyncMessage";

function create({ item }: { item: CloudAccountSyncItem }) {
  const CloudAccountSyncMessage = protoRoot.lookupType(
    "comm.CloudAccountSyncMessage"
  );
  const CloudAccountSyncItem = CloudAccountSyncMessage.lookupEnum(
    "CloudAccountSyncItem"
  );

  const cloudAccountSyncMessage = CloudAccountSyncMessage.create({
    item: CloudAccountSyncItem.values[item],
  });

  return createMessage({ [name]: cloudAccountSyncMessage });
}

const cloudAccountSyncMessage = { create, name };

export default cloudAccountSyncMessage;
