const variantOverline = {
  fontWeight: 600,
  fontSize: ["0.875rem", "0.875rem", "1rem"],
  lineHeight: ["16px", "16px", "18.75px"],
  textTransform: "uppercase",
  letterSpacing: "0.5px"
};
const variantSubheader = {
  fontWeight: 400,
  fontSize: ["1.25rem", "1.375rem", "1.75rem"],
  lineHeight: ["28px", "32px", "40px"]
};
const variantLabel = {
  fontWeight: 500,
  fontSize: ["0.6875rem", "0.75rem", "0.875rem"],
  lineHeight: ["16px", "16px", "20px"],
  letterSpacing: ["0.5px", "0.5px", "0.1px"]
};
const variantLabel2 = {
  fontWeight: 500,
  fontSize: "0.6875rem",
  lineHeight: "12px",
  letterSpacing: "0.5px"
};
const variantBody = {
  fontWeight: 400,
  fontSize: ["0.75rem", "0.75rem", "0.875rem"],
  lineHeight: ["16px", "16px", "20px"],
  letterSpacing: ["0.4px", "0.4px", "0.25px"]
};
const variantTitle = {
  fontWeight: 400,
  fontSize: ["0.875rem", "0.875rem", "1rem"],
  lineHeight: ["20px", "20px", "24px"],
  letterSpacing: ["0.1px", "0.1px", "0.5px"]
};
const variants = {
  overline: variantOverline,
  subheader: variantSubheader,
  label: variantLabel,
  label2: variantLabel2,
  body: variantBody,
  title: variantTitle
};
const text = {
  variants
};

export default text;
