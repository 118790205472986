import { useAuth } from "@/store/auth";
import { getHasActiveSubscription } from "@/store/auth/selectors";

export const replayDurations = {
  premium: {
    "8h": 8 * 60 * 60 * 1000,
    "4h": 4 * 60 * 60 * 1000,
    "1h": 1 * 60 * 60 * 1000,
    "10m": 10 * 60 * 1000
  },
  free: {
    "10m": 10 * 60 * 1000
  }
} as const;

export const getReplayDuration = (replayDuration?: ReplayDuration) => {
  const isPremium = getHasActiveSubscription(useAuth.getState());
  if (isPremium) {
    return replayDurations.premium[replayDuration || "8h"];
  }
  return replayDurations.free["10m"];
};
