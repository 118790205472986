import protoRoot from "../proto-root";

const name = "startVideoReceivingResponse";

function create({ result, previewAspectRatio }: StartVideoReceivingResponse) {
  const StartVideoReceiving = protoRoot.lookupType(
    "comm.StartVideoReceivingRPCResponse"
  );
  const Result = StartVideoReceiving.lookupEnum("Result");

  const response = StartVideoReceiving.create({
    result: Result.values[result],
    previewAspectRatio,
  });
  return { payload: response, name };
}

const startVideoReceivingResponse = { create, name };

export default startVideoReceivingResponse;
