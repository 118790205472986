import protoRoot from "../proto-root";

const name = "startVideoRecordingRPCResponse";

function create({
  result,
  recordingLimit,
}: {
  result: "SUCCESS" | "FAILURE" | "ALREADY_RUNNING";
  recordingLimit: number;
}) {
  const StartVideoRecording = protoRoot.lookupType(
    "comm.StartVideoRecordingRPCResponse"
  );

  const Result = StartVideoRecording.lookupEnum("Result");

  const response = StartVideoRecording.create({
    result: Result.values[result],
    recordingLimit,
  });
  return { payload: response, name };
}

const startVideoRecordingRPCResponse = { create, name };

export default startVideoRecordingRPCResponse;
