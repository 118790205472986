import { Flex, FlexProps, ThemeTypings } from "@chakra-ui/react";

export default function Circle({
  children,
  bg,
  size = "40",
  props,
  w = size,
  h = size,
}: {
  children?: any;
  bg?: ThemeTypings["colors"];
  size?: string;
  w?: string;
  h?: string;
  props?: FlexProps;
}) {
  return (
    <Flex
      align="center"
      justify="center"
      bg={bg}
      borderRadius="100%"
      w={w}
      h={h}
      minW={w}
      minH={h}
      {...props}
    >
      {children}
    </Flex>
  );
}
