import protoRoot from "../proto-root";

const name = "stopVideoRecordingRPCRequest";

function create() {
  const StopVideoRecordingRPCRequest = protoRoot.lookupType(
    "comm.StopVideoRecordingRPCRequest"
  );

  return { payload: StopVideoRecordingRPCRequest.create(), name };
}

const stopVideoRecordingRPCRequest = { create, name };

export default stopVideoRecordingRPCRequest;
