import protoRoot from "../proto-root";

const name = "syncMonitoringRPCRequest";

function create() {
  const SyncMonitoring = protoRoot.lookupType("comm.SyncMonitoringRPCRequest");
  const request = SyncMonitoring.create({});

  return { payload: request, name };
}

const syncMonitoringRPCRequest = { create, name };
export default syncMonitoringRPCRequest;
