import { create } from "zustand";
import { persist, subscribeWithSelector } from "zustand/middleware";
import environment from "@/modules/environment";
import { NoiseSenKeys } from "@/enums/noise";
import { MotionSensKeys } from "@/enums/motion";
import { VideoQualityKeys } from "@/enums/videoQuality";
import { ReplayDurationKeys } from "@/enums/replayDuration";

const initialState: StationState = {
  noiseCorrection: 0,
  role: "unknown",
  icon: "living_room",
  color: "color1",
  objectId: null,

  settings: {
    micSensitivity: NoiseSenKeys.MEDIUM,
    noiseDetectionEnabled: false,
    motionDetectionEnabled: false,
    motionSensitivity: MotionSensKeys.MEDIUM,
    videoQuality: VideoQualityKeys.HD,
    replayDuration: ReplayDurationKeys["8h"]
  }
};

const useStation = create<StationStore>()(
  subscribeWithSelector(
    persist(
      (set) => ({
        ...initialState,
        setRole(role) {
          log.info("setting station role to: ", role);
          set({ role });
        },
        setNoiseCorrection(noiseCorrection) {
          log.info("setting noiseCorrection to ", noiseCorrection);
          set({ noiseCorrection });
        },
        setIcon(icon) {
          log.info("setting station icon to: ", icon);
          set({ icon });
        },
        setColor(color) {
          log.info("setting station color to: ", color);
          set({ color });
        },
        setObjectId(objectId) {
          set({ objectId });
        },
        updateSettings(update) {
          log.info("Update for station settings", update);
          set(({ settings }) => ({ settings: { ...settings, ...update } }));
        },
        resetState() {
          log.info("resetting station store");
          set(initialState);
        }
      }),
      {
        name: environment.appMode + "-station"
      }
    )
  )
);

const stationStore = useStation.getState;

export { useStation, stationStore };
