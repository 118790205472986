import { useAuth } from "@/store/auth";
import { useDevices } from "@/store/devices";
import { usePairingCode } from "camera/store/pairingCode";
import { usePendingEvents } from "camera/store/pendingEvents";
import { useStation } from "camera/store/station";
import LocalEvents from "../LocalEvents";
import { MediaUploadManager } from "../upload/MediaUploadManager";
import EventManager from "../events/EventManager";

export default function clearSession() {
  log.app("Clearing session");

  useAuth.persist.clearStorage();
  useDevices.persist.clearStorage();
  usePairingCode.persist.clearStorage();
  usePendingEvents.persist.clearStorage();
  useStation.persist.clearStorage();

  usePairingCode.getState().resetState();
  usePendingEvents.getState().resetState();
  useStation.getState().resetState();
  useAuth.getState().resetState();
  useDevices.getState().resetState();

  LocalEvents.clearAll();
  MediaUploadManager.reset();
  EventManager.reset();
}
