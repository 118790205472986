import CameraAndFlashlightState from "../private/CameraAndFlashlightState";
import createMessage from "./createMessage";

const name = "cameraAndFlashlightState";

function create({ cameraId }: { cameraId: string }) {
  return createMessage({
    [name]: CameraAndFlashlightState.create({ cameraId }),
  });
}

const cameraAndFlashlightState = { create, name };

export default cameraAndFlashlightState;
