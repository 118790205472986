import protoRoot from "../proto-root";
import createMessage from "./createMessage";

const name = "appStateMessage";

function create({ state }: { state: AppActivityState }) {
  const AppStateMessage = protoRoot.lookupType("comm.AppStateMessage");
  const AppState = AppStateMessage.lookupEnum("AppState");

  const appStateMessage = AppStateMessage.create({
    state: AppState.values[state],
  });

  return createMessage({ [name]: appStateMessage });
}

const appStateMessage = { create, name };

export default appStateMessage;
