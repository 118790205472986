export function decodeProtocolBuffer({
  typePath,
  buffer,
  root,
}: {
  typePath: string;
  buffer: Uint8Array;
  root: protobuf.Root;
}) {
  const Type = root.lookupType(typePath);

  const decoded = Type.decode(buffer);

  return Type.toObject(decoded, {
    enums: String,
    longs: Number,
    defaults: true,
  });
}
