const colors = {
  transparent: "transparent",
  current: "currentColor",
  black: "#000000",
  white: "#FFFFFF",
  whiteAlpha: {
    50: "rgba(255, 255, 255, 0.04)",
    100: "rgba(255, 255, 255, 0.06)",
    200: "rgba(255, 255, 255, 0.08)",
    300: "rgba(255, 255, 255, 0.16)",
    400: "rgba(255, 255, 255, 0.24)",
    500: "rgba(255, 255, 255, 0.36)",
    600: "rgba(255, 255, 255, 0.48)",
    700: "rgba(255, 255, 255, 0.64)",
    800: "rgba(255, 255, 255, 0.80)",
    900: "rgba(255, 255, 255, 0.92)",
  },
  blackAlpha: {
    50: "rgba(0, 0, 0, 0.04)",
    100: "rgba(0, 0, 0, 0.06)",
    200: "rgba(0, 0, 0, 0.08)",
    300: "rgba(0, 0, 0, 0.16)",
    400: "rgba(0, 0, 0, 0.24)",
    500: "rgba(0, 0, 0, 0.36)",
    600: "rgba(0, 0, 0, 0.48)",
    700: "rgba(0, 0, 0, 0.64)",
    800: "rgba(0, 0, 0, 0.80)",
    900: "rgba(0, 0, 0, 0.92)",
  },
  primary: {
    99: "#fefbff",
    95: "#eef0ff",
    90: "#dae2ff",
    80: "#b1c5ff",
    70: "#87a9ff",
    60: "#588cff",
    50: "#3471e8",
    40: "#0057ce",
    30: "#00419e",
    20: "#002c70",
    10: "#001946",
  },
  secondary: {
    99: "#fefbff",
    95: "#eef0ff",
    90: "#dce2f9",
    80: "#c0c6dc",
    70: "#a5abc1",
    60: "#8a90a5",
    50: "#70778b",
    40: "#585e71",
    30: "#404659",
    20: "#2a3042",
    10: "#151b2c",
  },
  tertiary: {
    99: "#fffbff",
    95: "#ffebfa",
    90: "#fdd7fa",
    80: "#e0bbdd",
    70: "#c4a0c1",
    60: "#a886a6",
    50: "#8d6d8b",
    40: "#725572",
    30: "#593d59",
    20: "#412742",
    10: "#2a122c",
  },
  neutral: {
    99: "#fefbff",
    95: "#f2f0f4",
    90: "#e4e2e6",
    80: "#c7c6ca",
    70: "#acabaf",
    60: "#919094",
    50: "#77777a",
    40: "#5e5e62",
    30: "#46464a",
    20: "#303034",
    10: "#1b1b1f",
  },
  "neutral-variant": {
    99: "#fefbff",
    95: "#f0f0fa",
    90: "#e1e2ec",
    80: "#c5c6d0",
    70: "#a9abb4",
    60: "#8f9099",
    50: "#757780",
    40: "#5c5e67",
    30: "#44464f",
    20: "#2e3038",
    10: "#191b23",
  },
  error: {
    99: "#fffbff",
    95: "#ffedea",
    90: "#ffdad6",
    80: "#ffb4ab",
    70: "#ff897d",
    60: "#ff5449",
    50: "#de3730",
    40: "#ba1a1a",
    30: "#93000a",
    20: "#690005",
    10: "#410002",
  },
  green: {
    99: "#f7ffee",
    95: "#cbffb6",
    90: "#92fb74",
    80: "#77de5b",
    70: "#5cc142",
    60: "#40a529",
    50: "#228a09",
    40: "#146e00",
    30: "#0d5300",
    20: "#063900",
    10: "#022100",
  },
  purple: {
    99: "#fffbff",
    95: "#f5eeff",
    90: "#e8ddff",
    80: "#cdbdff",
    70: "#b49cff",
    60: "#9b7aff",
    50: "#815ee6",
    40: "#6743cb",
    30: "#4f25b2",
    20: "#370095",
    10: "#20005e",
  },
  orange: {
    99: "#fffbff",
    95: "#ffede3",
    90: "#ffdcc5",
    80: "#ffb782",
    70: "#f9913b",
    60: "#d87822",
    50: "#b85f00",
    40: "#934b00",
    30: "#703800",
    20: "#4f2500",
    10: "#301400",
  },
  cyan: {
    99: "#f1fffe",
    95: "#adfffe",
    90: "#5bf8f8",
    80: "#2fdcdb",
    70: "#00bebe",
    60: "#00a1a1",
    50: "#008585",
    40: "#006a6a",
    30: "#004f4f",
    20: "#003737",
    10: "#002020",
  },
  blue: {
    99: "#fefbff",
    95: "#eef0ff",
    90: "#dae2ff",
    80: "#b1c5ff",
    70: "#87a9ff",
    60: "#588cff",
    50: "#3471e8",
    40: "#0057ce",
    30: "#00419e",
    20: "#002c70",
    10: "#001946",
  },
  pink: {
    99: "#fffbff",
    95: "#ffecf0",
    90: "#ffd9e2",
    80: "#ffb1c8",
    70: "#ff84af",
    60: "#fa5096",
    50: "#d7347c",
    40: "#b61263",
    30: "#8e004a",
    20: "#650033",
    10: "#3e001d",
  },
};

export default colors;
