import protoRoot from "../proto-root";

function create({ name, cameraId }: CameraInfo) {
  const CameraInfo = protoRoot.lookupType("comm.CameraInfo");
  const CameraFacing = CameraInfo.lookupEnum("CameraFacing");

  return CameraInfo.create({
    name,
    cameraId,
    cameraFacing: CameraFacing.values.EXTERNAL,
  });
}

const cameraInfo = { create };

export default cameraInfo;
