export const VideoQualityStringKeys = {
  HD: "videoSettings.quality.HD",
  HIGH: "videoSettings.quality.HIGH",
  MEDIUM: "videoSettings.quality.MEDIUM",
  LOW: "videoSettings.quality.LOW"
} as const;

export const VideoResolutionKeys = {
  HD: "720p",
  HIGH: "480p",
  MEDIUM: "360p",
  LOW: "288p"
} as const;

export const VideoQualityKeys = {
  HD: "HD",
  HIGH: "HIGH",
  MEDIUM: "MEDIUM",
  LOW: "LOW"
} as const;

export const VideoQualityKeysArray = Object.values(VideoQualityKeys);
