const baseStyleTrack = {
  borderRadius: "full",
  width: "2.5rem",
  height: "1.5rem",
  alignItems: "center",
  transitionProperty: "common",
  transitionDuration: "fast",
  bg: "surface-variant",
  border: "1px",
  borderColor: "outline",
  _disabled: {
    opacity: 0.4,
    cursor: "not-allowed",
  },
  _checked: {
    bg: "primary",
  },
};

const baseStyleThumb = {
  bg: "outline",
  transitionProperty: "transform",
  transitionDuration: "normal",
  borderRadius: "inherit",
  width: "1.25rem",
  height: "1.25rem",
  transform: `translateX(0rem) scale(0.6)`,
  _checked: {
    transform: `translateX(1.125rem) scale(1)`,
    bg: "white",
  },
};

const baseStyle = {
  track: baseStyleTrack,
  thumb: baseStyleThumb,
};

const switchTheme = {
  baseStyle,
};

export default switchTheme;
