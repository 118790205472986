import { RPCResponses } from "@/modules/communication";
import Xmpp from "@/modules/Xmpp";
import { isPairedDevice } from "@/utils";
import { cameraStore } from "camera/store/camera";
import Peers from "@/modules/Peers";
import EventManager from "camera/modules/events/EventManager";
import { stopFaceIfrunning } from "./utils";

type Init = {
  stationManager: CameraStationManager;
  rtcManager: CameraRtcManager;
  changeCamera: (cameraId: string, jidToIgnore?: string) => Promise<void>;
  rpcEventUpdater: RpcEventUpdater;
};

const { setActiveFaceJid } = cameraStore();

export default class Handlers {
  private xmpp = Xmpp.getInstance();
  private stationManager!: CameraStationManager;
  private rtcManager!: CameraRtcManager;
  private _changeCamera!: Init["changeCamera"];
  private rpcEventUpdater!: RpcEventUpdater;

  private get cameraList() {
    return cameraStore().cameraList;
  }
  private get cameraId() {
    return cameraStore().cameraId;
  }

  init = ({ stationManager, rtcManager, changeCamera, rpcEventUpdater }: Init) => {
    this.stationManager = stationManager;
    this.rtcManager = rtcManager;
    this._changeCamera = changeCamera;
    this.rpcEventUpdater = rpcEventUpdater;
  };

  startMonitoring = (request: {}, from: string, sendResponse: RpcSendResponse) => {
    log.monitoring("received start monitoring request", request, from);
    if (!isPairedDevice(from)) return;

    const availableCameras = this.cameraList
      ? this.cameraList.map((camera) => ({
          cameraId: camera.deviceId,
          name: camera.label
        }))
      : [];

    const response = RPCResponses.StartMonitoring.create({
      batteryStatus: this.stationManager.getBatteryStatus(),
      cameraState: { cameraId: this.cameraId! },
      configuration: { availableCameras }
    });
    sendResponse(response);
    this.rtcManager.initializeConnection(from);
    this.xmpp.sendPresence("sender_connected");
    EventManager.triggerEvent("CONNECT", from);
  };

  stopMonitoring = (request: {}, from: string, sendResponse: RpcSendResponse) => {
    if (!isPairedDevice(from)) return;
    this.rtcManager.rtc.destroyPeerConnection(from);
    const response = RPCResponses.StopMonitoring.create();
    sendResponse(response);
    stopFaceIfrunning(from);

    if (Object.keys(Peers.getAll()).length === 0) {
      this.xmpp.sendPresence("sender");
    }
    EventManager.triggerEvent("DISCONNECT", from);
  };

  startVideoBroadcast = (request: {}, from: string, sendResponse: RpcSendResponse) => {
    if (!isPairedDevice(from)) return;
    const response = RPCResponses.StartVideoBroadcasting.create({
      result: "SUCCESS",
      cameraId: this.cameraId!
    });
    sendResponse(response);
  };

  ping = (request: {}, from: string, sendResponse: RpcSendResponse) => {
    if (!isPairedDevice(from)) return;
    sendResponse(RPCResponses.Ping.create());
  };

  changeCamera = async (request: ChangeCameraRequest, from: string, sendResponse: RpcSendResponse) => {
    if (!isPairedDevice(from)) return;
    await this._changeCamera(request.cameraId, from);
    const response = RPCResponses.CameraCommand.create({
      cameraId: this.cameraId!,
      result: "SUCCESS"
    });
    sendResponse(response);
  };

  startVideoReceiving = (request: {}, from: string, sendResponse: RpcSendResponse) => {
    if (!isPairedDevice(from)) return;
    const previewAspectRatio = window.innerWidth / window.innerHeight;
    const hasFaceActive = Boolean(cameraStore().activeFaceJid);
    const response = RPCResponses.StartVideoReceiving.create({
      result: hasFaceActive ? "ALREADY_RUNNING" : "SUCCESS",
      previewAspectRatio
    });
    if (!hasFaceActive) setActiveFaceJid(from);
    sendResponse(response);
  };

  stopVideoReceiving = (request: {}, from: string, sendResponse: RpcSendResponse) => {
    if (!isPairedDevice(from)) return;
    setActiveFaceJid(null);
    sendResponse(null);
  };

  startCameraHistoryReplayEvents = async (request: {}, from: string, sendResponse: RpcSendResponse) => {
    if (!isPairedDevice(from)) return;
    log.rpc("Received 'startCameraHistoryReplayEvents' request");
    this.rpcEventUpdater.enableForPeer(from);
    const payload = await this.rpcEventUpdater.getPayloadForPeer(from);

    const response = RPCResponses.CameraHistoryReplayEvents.create(payload);
    sendResponse(response);
  };

  reloadCameraHistoryReplayEvents = async (request: {}, from: string, sendResponse: RpcSendResponse) => {
    if (!isPairedDevice(from)) return;
    log.rpc("Received 'reloadCameraHistoryReplayEvents' request");
    const payload = await this.rpcEventUpdater.getPayloadForPeer(from);

    const response = RPCResponses.CameraHistoryReplayEvents.create(payload);
    sendResponse(response);
  };

  stopCameraHistoryReplayEvents = (request: {}, from: string, sendResponse: RpcSendResponse) => {
    if (!isPairedDevice(from)) return;
    log.rpc("Received 'stopCameraHistoryReplayEvents' request");

    const response = RPCResponses.StopCameraHistoryReplayEvents.create();
    this.rpcEventUpdater.disableForPeer(from);
    sendResponse(response);
  };

  syncMonitoring = (request: {}, from: string, sendResponse: RpcSendResponse) => {
    if (!isPairedDevice(from)) return;
    log.rpc("Received 'syncMonitoring' request");

    const response = RPCResponses.SyncMonitoring.create({
      batteryStatus: this.stationManager.getBatteryStatus(),
      cameraState: { cameraId: this.cameraId! }
    });
    sendResponse(response);
  };
}
