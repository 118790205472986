import { useEffect, useCallback } from "react";
import { Box } from "@chakra-ui/react";
import SplashScreen from "@/modules/app/scenes/SplashScreen";
import AlreadyRunningScreen from "@/modules/app/scenes/AlreadyRunningScreen";
import setupI18n from "@/modules/app/i18n";
import { useApp } from "@/store/app";
import appUpdater, { UpdateNotifier } from "@/lib/appUpdater";
import Messenger from "@/modules/Messenger";
import Announcer from "@/modules/Announcer";
import createInstanceChecker from "@/modules/app/createInstanceChecker";
import createNetworkManager from "@/modules/app/createNetworkManager";
import ErrorScreen from "@/modules/app/scenes/ErrorScreen";
import PremiumScreenProvider from "@/modules/app/PremiumScreen/PremiumScreenProvider";
import createStatusMonitor from "@/modules/app/createStatusMonitor";
import Responder from "camera/modules/pairing/Responder";
import AppRoutes from "camera/modules/Routes";
import AppContextProvider from "@/modules/app/AppContextProvider";
import SigningOut from "@/modules/app/components/SigningOut";
import { createPremiumChangeEmitter } from "@/modules/app/premiumChangeEmitter";
import { checkBrowserSupport } from "@/utils";
import createDeviceSetup from "./modules/app/createDeviceSetup";
import { useStationReset } from "./modules/station/useStationReset";
import Navbar from "./modules/app/components/Navbar";

const networkManager = createNetworkManager();
const deviceSetup = createDeviceSetup();
const checker = createInstanceChecker();
checker.notifyOtherExistingInstances();
createPremiumChangeEmitter();
appUpdater.create();

const setStatus = useApp.getState().setStatus;

async function startApp() {
  try {
    await checkBrowserSupport();
    await setupI18n();
    if (!checker.getCanBeStarted()) {
      setStatus("ALREADY_RUNNING");
      return;
    }
    const xmppCredentials = await deviceSetup.getXmppCredentials();
    await networkManager.start(xmppCredentials);
    Messenger.getInstance();
    Announcer.getInstance();
    await deviceSetup.syncDevice();
    createStatusMonitor();
    setStatus("STARTED");
  } catch (err) {
    log.err("'startApp' failed", err);
    setStatus("FAILURE");
  }
}
startApp();

export default function App() {
  const status = useApp((state) => state.status);
  const stationReset = useStationReset({ deviceSetup, networkManager });

  const handleAppStatusChange = useCallback((store: AppStore, prevStore: AppStore) => {
    if (
      (store.status === "NO_NETWORK" && prevStore.status !== "NO_NETWORK") ||
      (store.status === "XMPP_DISCONNECTED" && prevStore.status !== "XMPP_DISCONNECTED")
    )
      networkManager.reconnect();
  }, []);

  useEffect(() => {
    const unsubscribe = useApp.subscribe(handleAppStatusChange);
    networkManager.startListening();
    return () => {
      unsubscribe();
      networkManager.stopListening();
    };
  }, [handleAppStatusChange]);

  if (status === "STARTING") return <SplashScreen />;
  if (status === "FAILURE") return <ErrorScreen />;
  if (status === "ALREADY_RUNNING") return <AlreadyRunningScreen />;

  return (
    <AppContextProvider resetStation={stationReset.resetStation}>
      <PremiumScreenProvider>
        <Box height="100vh" px={["1rem", "1.5rem"]}>
          {status === "SIGNING_OUT" && <SigningOut />}
          <UpdateNotifier />
          <Responder />
          <Navbar />
          <AppRoutes />
        </Box>
      </PremiumScreenProvider>
    </AppContextProvider>
  );
}
