import protoRoot from "./proto-root";
import { v4 as uuid4 } from "uuid";

export const MAX_BYTE_LENGTH = 65536;

export function createMultiPart(payload: MultiPartProps) {
  const MultiPart = protoRoot.lookupType("comm.MultiPart");
  const multiPart = MultiPart.create(payload);
  return multiPart;
}

export function createMultiParts(buffer: Uint8Array) {
  const uuid = uuid4();
  const numberOfParts = Math.ceil(buffer.length / MAX_BYTE_LENGTH);

  log.messenger("NumberOfParts:", numberOfParts);

  const multiParts: protobuf.Message[] = [];
  for (let i = 0; i < numberOfParts; i++) {
    const start = i * MAX_BYTE_LENGTH;
    log.messenger("Current buffer start:", start);

    const end = start + MAX_BYTE_LENGTH;
    log.messenger("Current buffer end:", end);

    const part = buffer.slice(start, end);

    const multiPart = createMultiPart({
      uuid,
      total: numberOfParts,
      part: i,
      data: part,
    });
    log.messenger("Created multiPart", multiPart);
    multiParts.push(multiPart);
  }
  log.messenger("Returning multiParts", multiParts);
  return multiParts;
}
