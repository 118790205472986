export async function getMediaStream(
  constraints: MediaStreamConstraints = {
    audio: true,
    video: true,
  }
) {
  const stream = await navigator.mediaDevices.getUserMedia(constraints);

  return stream;
}
