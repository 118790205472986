import protoRoot from "../proto-root";

const name = "getSignedUrlRpcresponse";

function create(signedUrl: string) {
  const GetSignedUrlRpcResponse = protoRoot.lookupType(
    "comm.GetSignedUrlRpcResponse"
  );

  const response = GetSignedUrlRpcResponse.create({
    signedUrl,
  });
  return { payload: response, name };
}

const getSignedUrlRpcresponse = { create, name };

export default getSignedUrlRpcresponse;
