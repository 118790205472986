import { Box, Container } from "@chakra-ui/react";
import PageAnimation from "@/components/PageAnimation";
import { useMount } from "@/hooks/useLifeCycles";
import { wait } from "@/utils";
import CameraSettingsFlow from "@/modules/cameraSettings/CameraSettingsFlow";
import DeviceDeleteFlow from "@/modules/app/DeviceDeleteFlow";
import { loadOpenCv } from "camera/lib/OpenCV";
import { createRecorder } from "camera/lib/Recorder";
import { useCamera } from "camera/store/camera";
import NoiseDetector from "camera/modules/noise/NoiseDetector";
import EventUploader from "camera/modules/events/EventUploader";
import MotionDetector from "camera/modules/motion/MotionDetector";
import PairingCode from "camera/modules/station/components/PairingCode";
import NoiseIndicator from "camera/modules/noise/components/NoiseIndicator";
import useCameraSettingsUpdater from "camera/modules/settings/useCameraSettingsUpdater";
import RemoteStreamPlayer from "camera/modules/station/components/RemoteStreamPlayer";
import Video from "camera/modules/app/components/Video";
import LocalEvents from "camera/modules/LocalEvents";
import { MediaUploadManager } from "camera/modules/upload/MediaUploadManager";
import streamQualityUpdater from "camera/modules/station/streamQualityUpdater";
import useMediaStream from "../modules/station/useMediaStream";
import { monitoring, stationManager } from "../modules/station";

EventUploader.start();
LocalEvents.init();
LocalEvents.startRemovingOldEvents();
NoiseDetector.init();
NoiseDetector.startListeningForSensitivityUpdate();
loadOpenCv();
MotionDetector.startListeningForUpdates();
streamQualityUpdater.startListeningForChanges();
const recorderCreator = createRecorder();

MediaUploadManager.init(recorderCreator);

export default function Camera() {
  const cameraSettings = useCameraSettingsUpdater();

  const { error, loading } = useCamera((state) => ({
    error: state.error,
    loading: state.loading
  }));
  const { videoRef } = useMediaStream({
    onStreamLoad: async () => {
      await wait(1000);
      stationManager.init();
    }
  });
  MotionDetector.video = videoRef.current;
  useMount(monitoring.init);

  return (
    <PageAnimation>
      {cameraSettings.xmppLogin && (
        <CameraSettingsFlow NoiseIndicator={NoiseIndicator} settingsStore={cameraSettings as CSTypes.SettingsStore} />
      )}
      <Container centerContent p="0" borderRadius="24px">
        <Box w="100%" position="relative" id="camera-video-container">
          <Video ref={videoRef} loading={loading} error={error} />
          <RemoteStreamPlayer />
          {!loading && <PairingCode />}
        </Box>
        <DeviceDeleteFlow />
      </Container>
    </PageAnimation>
  );
}
