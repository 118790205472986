import environment from "@/modules/environment";

type Page = "pricing" | "directions";

const { envMode } = environment;
const cameritoUrl = "camerito.com/";

export default function openCameritoWeb(page: Page, target = "_blank") {
  if (page === "pricing") page += "?from=webapp";
  const prefix = envMode === "test" ? "test." : "";
  const url = `https://${prefix}${cameritoUrl}${page}`;

  if (isDev) {
    log.info("Not openning web links in development", url);
    return;
  }
  window.open(url, target);
}
