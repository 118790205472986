import protoRoot from "../proto-root";

const name = "stopMonitoringRPCResponse";

function create() {
  const StopMonitoringRPCResponse = protoRoot.lookupType(
    "comm.StopMonitoringRPCResponse"
  );

  return {
    payload: StopMonitoringRPCResponse.create(),
    name,
    sendBy: "xmpp",
  } as const;
}

const stopMonitoringRPCResponse = {
  create,
  name,
};

export default stopMonitoringRPCResponse;
