import { textOverflowDots } from "@/theme/utils/style";
import { Menu as ChakraMenu, MenuButton, MenuList, MenuOptionGroup, MenuItemOption, Box, Flex } from "@chakra-ui/react";
import { useRef } from "react";

type Props = {
  children?: any;
  isOpen?: boolean;
  close?: Cb;
  cameraList: { deviceId: string; label: string }[] | null;
  cameraId: string | undefined | null;
  changeCamera: Cb<string, Promise<boolean | void>>;
};

export default function CameraList({ isOpen, close, children, cameraId, cameraList, changeCamera }: Props) {
  const isChangingCamera = useRef(false);

  async function handleCameraChange(e: string | string[]) {
    if (!e) {
      log.err("Couldn't change camera, received:", e);
      return;
    }
    if (isChangingCamera.current) {
      log.warn("already changing");
      return;
    }
    const cameraId = typeof e === "string" ? e : e[0];
    isChangingCamera.current = true;

    await changeCamera(cameraId);
    isChangingCamera.current = false;
  }

  if (!cameraList || cameraList.length < 2) return null;

  // use OptionsMenu component
  // log.warn("Use 'OptionsMenu' component instead");
  return (
    <ChakraMenu closeOnSelect={false} placement="bottom-end" isOpen={isOpen} onClose={close}>
      {children && <MenuButton>{children}</MenuButton>}
      <MenuList>
        <MenuOptionGroup value={cameraId!} type="radio" onChange={handleCameraChange}>
          {cameraList?.map((camera) => {
            const isSelected = camera.deviceId === cameraId;

            return (
              <MenuItemOption key={camera.deviceId} value={camera.deviceId} icon={null}>
                <Flex maxW="240">
                  {isSelected && <Box minW="4px" h="1.5rem" mr="0.5rem" borderRadius="7px" bg="primary" />}
                  <Flex {...textOverflowDots} ml={isSelected ? 0 : "0.75rem"}>
                    {camera.label || "Unrecognized camera"}
                  </Flex>
                </Flex>
              </MenuItemOption>
            );
          })}
        </MenuOptionGroup>
      </MenuList>
    </ChakraMenu>
  );
}
