import { proxyWithDefault } from "@/utils";

import Camera from "assets/icons/camera.svg?react";
import LivingRoom from "assets/icons/living_room.svg?react";
import Stairs from "assets/icons/stairs.svg?react";
import House from "assets/icons/house.svg?react";
import Garage from "assets/icons/garage.svg?react";
import Trees from "assets/icons/trees.svg?react";
import Kitchen from "assets/icons/kitchen.svg?react";
import Bathroom from "assets/icons/bathroom.svg?react";
import Office from "assets/icons/office.svg?react";
import FrontDoor from "assets/icons/front_door.svg?react";
import Bedroom from "assets/icons/bedroom.svg?react";
import Baby from "assets/icons/baby.svg?react";
import Store from "assets/icons/store.svg?react";
import Street from "assets/icons/street.svg?react";
import Car from "assets/icons/car.svg?react";

type CameraIconsType = {
  [key in CameraIconId]: {
    id: CameraIconId;
    icon: React.FunctionComponent;
  };
};

type CameraColorsType = {
  [key in CameraColorId]: {
    id: CameraColorId;
    value: string;
  };
};

export const CameraIconsEnum: CameraIconsType = {
  video_camera: { id: "video_camera", icon: Camera },
  living_room: { id: "living_room", icon: LivingRoom },
  stairs: { id: "stairs", icon: Stairs },
  house: { id: "house", icon: House },
  garage: { id: "garage", icon: Garage },
  trees: { id: "trees", icon: Trees },
  bedroom: { id: "bedroom", icon: Bedroom },
  office: { id: "office", icon: Office },
  street: { id: "street", icon: Street },
  store: { id: "store", icon: Store },
  kitchen: { id: "kitchen", icon: Kitchen },
  front_door: { id: "front_door", icon: FrontDoor },
  bathroom: { id: "bathroom", icon: Bathroom },
  baby: { id: "baby", icon: Baby },
  car: { id: "car", icon: Car }
};

const cameraColorsEnum: CameraColorsType = {
  color1: { id: "color1", value: "blue" },
  color2: { id: "color2", value: "purple" },
  color3: { id: "color3", value: "pink" },
  color4: { id: "color4", value: "orange" },
  color5: { id: "color5", value: "green" },
  color6: { id: "color6", value: "cyan" }
} as const;

export const CameraIcons = proxyWithDefault(CameraIconsEnum, "video_camera");
export const CameraColors = proxyWithDefault(cameraColorsEnum, "color1");

export const CameraColorsArr = Object.values(cameraColorsEnum);
export const CameraIconsArr = Object.values(CameraIconsEnum);
