import { create } from "zustand";
import { persist } from "zustand/middleware";
import environment from "@/modules/environment";

const initialState: InstallationState = {
  installationId: null
};

const useInstallation = create<InstallationStore>()(
  persist(
    (set) => ({
      ...initialState,
      setInstallationId(installationId) {
        log.installation("setting installationId", installationId);
        set({ installationId });
      }
    }),
    {
      name: environment.appMode + "-installation"
    }
  )
);

export { useInstallation };
