import { useSyncExternalStore } from "react";
import EventEmitter from "@/lib/EventEmitter";
import { useDevices } from "@/store/devices";
import { getCameraDevices } from "@/store/devices/selectors";

type Devices = { [jid: string]: Presence };
type _PresenceKeeper = {
  get(jid: string): Devices[string];
  update(update: PresenceUpdate): Presence;
  getAvailableDevices(devices?: StoreDevice[] | StoreDevices): StoreDevice[];
  getAvailableJids(devices?: StoreDevice[] | StoreDevices): string[];
  setDefaults(): void;
  on: EventEmitter["on"];
  off: EventEmitter["off"];
};

const getAsArray = (devices?: StoreDevice[] | StoreDevices) => {
  if (!devices) return Object.values(useDevices.getState().devices);
  if (Array.isArray(devices)) return devices;
  return Object.values(devices);
};

const presenceEmitter = new EventEmitter();
let devicePresences: { [jid: string]: Presence } = {};
const PresenceKeeper: _PresenceKeeper = {
  get(jid: string) {
    const device = devicePresences[jid];
    if (!device) return this.update({ jid, status: "unavailable" });
    return device;
  },
  update({ jid, status }) {
    const prevPresence = devicePresences[jid];
    if (status === prevPresence) return status;
    devicePresences = { ...devicePresences, [jid]: status };
    log.info("Updating presence", status, jid);
    presenceEmitter.emit("presence-update", { jid, status });
    return status;
  },
  getAvailableDevices(devices) {
    const _devices = getAsArray(devices);
    const availableDevices = _devices.filter((d) => this.get(d.jid) !== "unavailable");
    return availableDevices;
  },
  getAvailableJids(devices) {
    const _devices = getAsArray(devices);
    const availableDevices = _devices.filter((d) => this.get(d.jid) !== "unavailable");
    return availableDevices.map((d) => d.jid);
  },
  setDefaults() {
    const cameras = getCameraDevices(useDevices.getState());
    cameras.forEach((c) => this.get(c.jid));
  },
  on: presenceEmitter.on,
  off: presenceEmitter.off
};

const subscribe = (cb: Cb) => {
  return presenceEmitter.on("presence-update", cb);
};
const getSnapshot = () => {
  return devicePresences;
};

export default PresenceKeeper;
export const usePresenceKeeper = () => {
  return useSyncExternalStore(subscribe, getSnapshot);
};
