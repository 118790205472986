import protoRoot from "../proto-root";
import CameraAndFlashlightState from "../private/CameraAndFlashlightState";

const name = "cameraCommandRpcResponse";

function create({
  cameraId,
  result,
}: {
  cameraId: string;
  result: "SUCCESS" | "FAILURE";
}) {
  const CameraCommandRpcResponse = protoRoot.lookupType(
    "comm.CameraCommandRpcResponse"
  );
  const Result = protoRoot.lookupEnum("Result");

  const response = CameraCommandRpcResponse.create({
    cameraAndFlashlightState: CameraAndFlashlightState.create({
      cameraId,
    }),
    result: Result.values[result],
  });
  return { payload: response, name };
}

const cameraCommandRpcResponse = { create, name };

export default cameraCommandRpcResponse;
